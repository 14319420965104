import { INftData, UserId } from '../../types/nft-map-types'
import styles from './map.module.scss'

export enum MapLandTypes {
  WATER = '#ffffff00',
  AVAILABLE_LAND = '#99E1D4',
  MINTED_LAND = '#EF9B6C',
  NOT_INTERACTIVE = 'white',
  SELECTED = '#FF0000',
}

export interface MapTopItemsTypes {
  text: string
  class: string
  color: string
}

export interface Path {
  path: Path2D
  coordinates: {
    x: number
    y: number
    width: number
    height: number
  }
  content: string | HTMLImageElement | null
}

export interface Props {
  onLandSelect: (coordinates: { x: number; y: number }) => void
  nftData: INftData[] | undefined
}

export const mapZoomSettings = {
  maxZoom: 1.51,
  minZoom: 0.1,
  scrollSensitivity: 0.0005,
}

export const mapTopItems: MapTopItemsTypes[] = [
  {
    text: 'Available Land',
    class: styles.first,
    color: '#99E1D4',
  },
  {
    text: 'Minted land',
    class: styles.fourth,
    color: '#1D8DF4',
  },
  {
    text: 'Land Unavailable for Sale',
    class: styles.second,
    color: '#1e296e',
  },
  {
    text: 'Land Available for Sale on Marketplaces',
    class: styles.third,
    color: '#FF5F1F',
  },
]

interface PriceTypes {
  solanaPrice: number
  usdPrice: number
}

export interface PopUpProps {
  name: string
  price: PriceTypes
  imgUrl: string
  location: string
  status: string
  description: string
  userId?: UserId,
  coordinates?: string[]
}
