import { useEffect, useRef, useState } from 'react'
import { Box, Typography } from '@mui/material'
import { ObserverUtil } from '../../utils/observerUtil'
import { DiscoverContent } from './types'

import styles from './discover.module.scss'

export default function Discover() {
  const [isVisible, setIsVisible] = useState(false)
  const [hasBeenVisible, setHasBeenVisible] = useState(false)

  const discoverRef = useRef(null)

  useEffect(() => {
    ObserverUtil(discoverRef, setIsVisible, hasBeenVisible, setHasBeenVisible)
  }, [hasBeenVisible])

  return (
    <Box ref={discoverRef} className={styles.discoverContainer}>
      <Box className={`${styles.contentSection} ${isVisible ? styles.animate : styles.hidden}`}>
        <Typography className={styles.title}>{DiscoverContent.HOME_TITLE}</Typography>
        <Typography className={styles.subtitle}>{DiscoverContent.SUBTITLE}</Typography>
        <Typography className={`${styles.description} ${isVisible ? styles.animate : ''}`}>
          {DiscoverContent.DESCRIPTION}
        </Typography>
      </Box>
    </Box>
  )
}
