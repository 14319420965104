import { Box, Button, IconButton, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import defaultLandImg from '../../../assets/images/Exgoland_logo.png'
import mapPin from '../../../assets/images/map.svg'
import solanaIcon from '../../../assets/images/whiteSolana.svg'
import usdcLogo from '../../../assets/images/usdcLogo.svg'
import {
  mapPupUpDefaultLandDescription,
  mapPupUpUnmintedLandDescription,
  mapPupUpUnmintedLandDescriptionHeader,
} from '../../../utils/constants/landDiscription'
import { formatText } from '../../../helpers/textFormatter'
import CopyButton from '../../copy-btn'
import { DataTypes, INftData, MintType, LandStatus } from '../../../types/nft-map-types'
import { getSolPrice } from '../../../services/helper.service'
import { isDevelopment } from '../../../utils/isDevelopment'
import styles from './popUp.module.scss'
import api from '../../../services/axios.service'
import { useUserContext } from '../../../context/UserContext'


export default function PopUp({
  nftInfo,
  mintType,
  nftData,
  handleClose,
}: {
  nftInfo: DataTypes
  mintType: string
  nftData: INftData[] | undefined
  handleClose: () => void
}) {
  const [combinedLandsCount, setCombinedLandsCount] = useState(0)
  const [buttonType, setButtonType] = useState<string>(MintType.NONE)
  const [solPrice, setSOLPrice] = useState(0)
  const [usdPrice, setUSDPrice] = useState(0)
  const navigate = useNavigate()
  const wallet = useWallet()
  const { user } = useUserContext()

  const navigateToNft = () => {
    window.scrollTo(0, 0)
    navigate(`/nft?square=${nftInfo.address ? nftInfo.address : nftInfo.location}&autoOpen=0&buttonValue=${buttonType}&price=${solPrice}`, {
      state: { buttonType, nftData, price: solPrice, autoOpen: false },
    })
  }

  useEffect(() => {
    setCombinedLandsCount(0)
    const combinedNfts = nftData?.find((nft) => nft.coordinates.includes(nftInfo.location))
    if (nftInfo.location !== '') {
      nftInfo.coordinates = Array(nftInfo.location)
    }
    if (combinedNfts) {
      setCombinedLandsCount(combinedNfts.coordinates.length)
      nftInfo.coordinates = combinedNfts.coordinates
    }
    fetchNftData()
  }, [nftInfo])

  useEffect(() => {
    if (solPrice) {
      getSolPrice().then((price) => {
        setUSDPrice(price * solPrice)
      })
    }
  }, [solPrice])

  const fetchNftData = async () => {
    const isLocal = isDevelopment()
    if (isLocal) {
      if (nftInfo.address) {
        if (wallet.publicKey && (wallet.publicKey?.toBase58() === nftInfo.userId?.walletAddress)) {
          return setButtonType(MintType.SELL)
        } else if (user?.publicAddress && (user?.publicAddress === nftInfo.userId?.walletAddress)) {
          return setButtonType(MintType.SELL)
        } else {
          return setButtonType(MintType.NONE)
        }
      }
      return setButtonType(MintType.MINT)
    }

    if (nftInfo && nftInfo.userId && nftInfo.address) {
      try {
        const nftMetadata = await api.get('/nfts/tokenMetadata/' + nftInfo.address)
        if (nftMetadata.status === 200) {
          const { owner, price } = nftMetadata.data

          if (wallet.publicKey?.toBase58() === owner && !price) {
            console.log('SELL')
            setButtonType(MintType.SELL)
          } else if (wallet.publicKey?.toBase58() !== owner && price) {
            console.log('BUY')
            setSOLPrice(price)
            setButtonType(MintType.BUY)
          } else {
            setButtonType(MintType.NONE)
          }
        } else {
          setSOLPrice(0)
          setButtonType(MintType.NONE)
        }
      } catch (error) {
        setSOLPrice(0)
        setButtonType(MintType.NONE)
      }
    } else {
      setSOLPrice(0)
      setButtonType(MintType.MINT)
    }
  }

  const handleButtonClick = () => {
    if (buttonType === MintType.BUY) {
      return window.open(
        `https://magiceden.io/marketplace/exgoland_collection?search=${nftInfo.name}`,
        '_blank',
      )
    } else if (buttonType === MintType.MINT) {
      navigate(`/nft?square=${nftInfo.location}&autoOpen=1&buttonValue=${buttonType}&price=${solPrice}`, {
        state: { buttonType, nftData, price: solPrice, autoOpen: true },
      })
    } else if (buttonType === MintType.SELL) {
      window.open(`https://magiceden.io/marketplace/exgoland_collection?search=${nftInfo.name}`, '_blank')
    }
  }

  return (
    <Box className={styles.modalContainer}>
      <IconButton onClick={handleClose} className={styles.closeIconBox}>
        <CloseIcon className={styles.closeIcon} />
      </IconButton>
      <Box className={styles.modalHeader}>
        <Box className={styles.firstSection}>
          <img className={styles.landImg} src={nftInfo.imgUrl || defaultLandImg} />
          <Box className={styles.headerInfoBox}>
            <Typography className={styles.landName}>{nftInfo.name}</Typography>
            {combinedLandsCount >= 2 && (
              <Typography className={styles.landName}>({combinedLandsCount} Lands)</Typography>
            )}
            <Box className={styles.iconBox}>
              <img src={mapPin} className={styles.mapPin} alt='mapPin' />
              <Typography className={styles.text}>{nftInfo.location}</Typography>
            </Box>
            {(buttonType === MintType.MINT) && (
              <Box className={styles.iconBox}>
                <img src={usdcLogo} className={styles.solanaIcon} alt='usdc icon' />
                <Typography className={styles.text}>
                  {`${process.env.REACT_APP_INITIAL_SALE_USDC_PRICE} ($ ${process.env.REACT_APP_INITIAL_SALE_USDC_PRICE})`}
                </Typography>
              </Box>
            )}
            {(buttonType === MintType.BUY) && (
              <Box className={styles.iconBox}>
                <img src={solanaIcon} className={styles.solanaIcon} alt='solana icon' />
                <Typography className={styles.text}>
                  {`${solPrice} ($ ${usdPrice})`}
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <Box className={styles.bottomSection}>
        <Box className={styles.ownerBox}>
          {
            nftInfo.userId?.walletAddress && (
              <>
                <Typography className={styles.ownerHeader}>Owner</Typography>
                  <Typography className={styles.ownerName}>
                    <span className={styles.walletAddress}>
                      {formatText(nftInfo.userId?.walletAddress) ?? '@landowner'}
                    </span>
                    {nftInfo.userId?.walletAddress && (
                      <CopyButton isWhite={true} text={nftInfo.userId?.walletAddress ?? ''} />
                    )}
                  </Typography>
              </>
            )
          }
          
          <Box className={styles.descriptionBox}>
            <Typography className={styles.descriptionHead}>Description</Typography>
            {buttonType === MintType.MINT ? (
              <Box className={styles.mintDescription}>
                {mapPupUpUnmintedLandDescriptionHeader}
                {mapPupUpUnmintedLandDescription.map((el) => (
                  <Typography key={el} className={styles.unmintedDescriptionListItem}>
                    <Box className={styles.circle} />
                    {el}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography className={styles.description}>
                {nftInfo?.description || mapPupUpDefaultLandDescription}
              </Typography>
            )}
          </Box>
        </Box>
        <Typography className={styles.status}>
          {solPrice ? LandStatus.ON_SALE : LandStatus.NOT_ON_SALE}
        </Typography>
      </Box>
      <Box className={styles.buttonsSection}>
        <Button className={styles.showMoreButton} onClick={navigateToNft}>
          Show more
        </Button>

        {buttonType !== MintType.NONE && (
          <Button className={styles.buyButton} onClick={handleButtonClick}>
            {buttonType}
          </Button>
        )}
      </Box>
    </Box>
  )
}
