import { Box, Container } from '@mui/material'
import Navigation from '../../Navigation'
import Footer from '../index'
import AboutInfo from './aboutInfo/AboutInfo'
import styles from './faq.module.scss'
import GeneralFaqs from './generalFaqs/GeneralFaqs'

const Faqs = () => (
  <Container className={styles.wrapper}>
    <Navigation type='black' />
    <AboutInfo />
    <GeneralFaqs />
    <Box>
      <Footer />
    </Box>
  </Container>
)

export default Faqs
