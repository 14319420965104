import { Path } from '../../components/Map/types'
import { ICameraZoomSettings, ZoomLevel } from '../../types/cameraZoom-types'
import { zoomNumbers } from './landZoomInfo'

export const detectZoomLevel = (cameraZoom: number): ZoomLevel => {
  let zoomLevel: ZoomLevel = 'zoomLevel1'

  if (cameraZoom < zoomNumbers.first) {
    zoomLevel = 'zoomLevel2'
  } else if (cameraZoom >= zoomNumbers.first && cameraZoom < zoomNumbers.second) {
    zoomLevel = 'zoomLevel3'
  } else if (cameraZoom >= zoomNumbers.second && cameraZoom <= zoomNumbers.third) {
    zoomLevel = 'zoomLevel4'
  }
  return zoomLevel
}

export const cameraZoomSettings: ICameraZoomSettings = {
  zoomLevel1: {
    squareWidth: 550,
    font: 60,
    landName: {
      ratioX: 87,
      ratioY: 550,
    },
    coordinates: {
      ratioX: 145,
      ratioY: 380,
    },
    solanaPrice: {
      ratioX: 136,
      ratioY: 241,
    },
    solanaPriceByDollar: {
      ratioX: 18,
      ratioY: 244,
    },
    ownerLogo: {
      ratioX: 245,
      ratioY: 644,
      size: 140,
    },
    pointerLogo: {
      ratioX: 240,
      ratioY: 436,
      size: 75,
    },
    solanaLogo: {
      ratioX: 240,
      ratioY: 436,
      size: 75,
    },
  },
  zoomLevel2: {
    squareWidth: 2000,
    font: 170,
    landName: {
      ratioX: 290,
      ratioY: 1700,
    },
    coordinates: {
      ratioX: 440,
      ratioY: 1120,
    },
    solanaPrice: {
      ratioX: 440,
      ratioY: 650,
    },
    solanaPriceByDollar: {
      ratioX: 50,
      ratioY: 650,
    },
    ownerLogo: {
      ratioX: 820,
      ratioY: 1950,
      size: 450,
    },
    pointerLogo: {
      ratioX: 820,
      ratioY: 1320,
      size: 280,
    },
    solanaLogo: {
      ratioX: 820,
      ratioY: 850,
      size: 280,
    },
  },
  zoomLevel3: {
    squareWidth: 1250,
    font: 100,
    landName: {
      ratioX: 150,
      ratioY: 1120,
    },
    coordinates: {
      ratioX: 267,
      ratioY: 774,
    },
    solanaPrice: {
      ratioX: 244,
      ratioY: 437,
    },
    solanaPriceByDollar: {
      ratioX: 58,
      ratioY: 440,
    },
    ownerLogo: {
      ratioX: 520,
      ratioY: 1290,
      size: 280,
    },
    pointerLogo: {
      ratioX: 480,
      ratioY: 890,
      size: 150,
    },
    solanaLogo: {
      ratioX: 480,
      ratioY: 550,
      size: 150,
    },
  },
  zoomLevel4: {
    squareWidth: 770,
    font: 60,
    landName: {
      ratioX: 146,
      ratioY: 742,
    },
    coordinates: {
      ratioX: 210,
      ratioY: 476,
    },
    solanaPrice: {
      ratioX: 202,
      ratioY: 286,
    },
    solanaPriceByDollar: {
      ratioX: 23,
      ratioY: 286,
    },
    ownerLogo: {
      ratioX: 320,
      ratioY: 840,
      size: 150,
    },
    pointerLogo: {
      ratioX: 308,
      ratioY: 540,
      size: 85,
    },
    solanaLogo: {
      ratioX: 308,
      ratioY: 348,
      size: 85,
    },
  },
}

export const getLandCoordianates = (canvasPath: Path) => {  
  const landX = Math.floor(canvasPath.coordinates.x / CoordinateSizePerSquare);
  const landY = Math.floor(canvasPath.coordinates.y / CoordinateSizePerSquare);

  return {
    landX,
    landY,
  };
};

export const CoordinateSizePerSquare = 84
