import { useState, useEffect } from 'react'
import { Box, Button, Typography } from '@mui/material'

import Modal from '../shared/Modal'
import xIcon from '../../assets/images/x-icon.svg'

import styles from './confirmModal.module.scss'

export default function ConfirmModal({
  open,
  toggleModal,
  onCancel,
  onOK,
}: {
  open: boolean
  toggleModal: (open: boolean) => void,
  onCancel: () => void,
  onOK: () => void,
}) {

  const handleClose = () => {
    toggleModal(false)
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box className={styles.mintLandContainer}>
          <Button onClick={handleClose} className={styles.closeButton}>
            <img src={xIcon} alt='' />
          </Button>

          <Typography className={styles.modalTitle}>
            Are you sure you want to delete the land image?
          </Typography>

          <Box className={styles.btnsSection}>
            <Button
              className={
                styles.mintActiveBtn
              }
              onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className={styles.mintActiveBtn}
              onClick={onOK}>
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
