import BlockchainSelection from './pages/BlockchainSelection'
import BuyLand from './pages/BuyLand'
import Comparison from './pages/Comparison'
import ExgolandAI from './pages/ExgolandAI'
import Features from './pages/Features'
import IndustryTrends from './pages/IndustryTrends'
import IntroductionPage from './pages/IntroductionPage'
import NetworkPlatform from './pages/NetworkPlatform'
import PoweredByAI from './pages/PoweredByAI'
import PurchaseToken from './pages/PurchaseToken'
import Roadmap from './pages/Roadmap'
import TokenUsers from './pages/TokenUsers'
import TwoDMap from './pages/TwoDMap'
import UserBenefits from './pages/UserBenefits'
import OutlinePage from './pages/OutlineVision'
import UseOfFunds from './pages/UseofFunds'
import TechToolsUsedPage from './pages/TechToolsUsed'
import TokenomicsPage from './pages/Tokenomics'
import TokenLockupProgramPage from './pages/TokenLockupProgram'
import TokenBenefitsPage from './pages/TokenBenefits'

export const sidebarMenuItems = [
  {
    id: 0,
    path: '',
    name: 'Introduction',
    component: <IntroductionPage />,
  },
  // {
  //   id: 1,
  //   path: '/exgoland-ai',
  //   name: 'EXGOLAND AI',
  //   component: <ExgolandAI />,
  // },
  {
    id: 1,
    path: '/outline-of-the-vision',
    name: 'Outline of the Vision',
    component: <OutlinePage />,
  },
  {
    id: 2,
    path: '/network-platform',
    name: 'Network platform(Solana)',
    component: <NetworkPlatform />,
  },
  {
    id: 3,
    path: '/blockchain-selection',
    name: 'Blockchain Selection',
    component: <BlockchainSelection />,
  },
  // {
  //   id: 4,
  //   path: '/purchase-token',
  //   name: 'Purchase Token',
  //   component: <PurchaseToken />,
  // },
  // {
  //   id: 5,
  //   path: '/powered-by-ai',
  //   name: 'Powered By AI',
  //   component: <PoweredByAI />,
  // },
  {
    id: 4,
    path: '/2d-map',
    name: '2D Map',
    component: <TwoDMap />,
  },
  {
    id: 5,
    path: '/buy-land',
    name: 'Buy Land',
    component: <BuyLand />,
  },
  {
    id: 6,
    path: '/features',
    name: 'Features',
    component: <Features />,
  },
  {
    id: 7,
    path: '/user-benefits',
    name: 'User Benefits',
    component: <UserBenefits />,
  },
  {
    id: 8,
    path: '/technical-tools-used',
    name: 'Technical Tools Used',
    component: <TechToolsUsedPage />,
  },
  {
    id: 9,
    path: '/industry-Trends',
    name: 'Industry Trends',
    component: <IndustryTrends />,
  },
  {
    id: 10,
    path: '/comparison',
    name: 'Comparison',
    component: <Comparison />,
  },
  // {
  //   id: 11,
  //   path: '/use-of-funds',
  //   name: 'Use of Funds',
  //   component: <UseOfFunds />,
  // },
  {
    id: 11,
    path: '/roadmap',
    name: 'Roadmap',
    component: <Roadmap />,
  },
  // {
  //   id: 15,
  //   path: '/token-users',
  //   name: 'Token Uses',
  //   component: <TokenUsers />,
  // },
  {
    id: 12,
    path: '/tokenomics',
    name: 'Tokenomics ',
    component: <TokenomicsPage />,
  },
  {
    id: 13,
    path: '/token-lockup-program',
    name: 'Token Lock-up Program',
    component: <TokenLockupProgramPage />,
  },
  {
    id: 14,
    path: '/token-benefits',
    name: 'Token Benefits',
    component: <TokenBenefitsPage />,
  },
  // {
  //   id: 14,
  //   path: '/roadmap',
  //   name: 'Token Benefits',
  //   component: <Roadmap />,
  // },
]
