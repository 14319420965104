import { Box } from '@mui/system'

import styles from './loader.module.scss'

export default function Loader({
  isFullScreen,
  background,
  loaderColor,
  isLoaderSmall
}: {
  isFullScreen: boolean
  background?: string
  loaderColor?: string
  isLoaderSmall?: boolean
}) {
  const loaderSize = isLoaderSmall ? '8vh' : '20vh'
  
  return (
    <Box
      className={`${styles.loaderContainer} ${isFullScreen ? styles.fullScreen : ''}`}
      sx={{ backgroundColor: background ?? ' rgba(0, 0, 0, 0.527)' }}
    >
      <Box className={styles.skChase} sx={{
        width: loaderSize,
        height: loaderSize,
      }}>
        {[...Array(6)].map((_, i) => (
          <Box key={i} className={styles.skChaseDot}>
            <Box
              className={styles.skChaseDotChild}
              sx={{ backgroundColor: loaderColor ?? '#ffffff' }}
            />
          </Box>
        ))}
      </Box>
    </Box>
  )
}
