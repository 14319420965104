export enum ButtonType {
  DARK = 'dark',
  LIGHT = 'light',
}

export interface ButtonProps {
  btnType: ButtonType
  text: string
  onClick?: () => void
  disabled?: boolean
}
