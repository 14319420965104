export const convertDataUrlToFile = (dataUrl: string | null, filename: string) => {
  if (!dataUrl) return
  const base64Data = dataUrl.replace(/^data:image\/png;base64,/, '')
  const byteCharacters = atob(base64Data)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += 1024) {
    const slice = byteCharacters.slice(offset, offset + 1024)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: 'image/png' })
  return new File([blob], filename, { type: 'image/png' })
}
