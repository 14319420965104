import { useEffect, useMemo, useRef, useState } from 'react'
import { Box, Button, Container, FormControl, TextField, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import emailjs from 'emailjs-com'
import PhoneInput from 'react-phone-input-2'
import Navigation from '../Navigation'
import Footer from '../Footer'
import { isMailValid, isNameValid } from '../../utils/validators'
import ConfirmationModal from '../shared/Modal/ConfirmationModal'
import post from '../../assets/images/post.svg'
import map from '../../assets/images/map.svg'
import web from '../../assets/images/globs.png'
import qr from '../../assets/images/qr.png'
import 'react-phone-input-2/lib/style.css'
import styles from './contact.module.scss'

export default function Contact() {
  const [to, setTo] = useState<string>('')
  const [fullName, setFullName] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [phoneNumberError, setPhoneNumberError] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [isSending, setIsSending] = useState<boolean>(false)
  const [mailError, setMailError] = useState<string>('')
  const [fullNameError, setFullNameError] = useState<string>('')
  const [messageError, setMessageError] = useState<string>('')
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)

  interface Country {
    dialCode: string
    countryCode: string
    format: string
    name: string
  }
  const errorMessage = '*This field is required'
  const phoneErrorMessage = 'Not correct phone number'

  const isNameChanged = useRef(false)
  const isPhoneNumberChanged = useRef(false)
  const isMessageChanged = useRef(false)
  const isToChanged = useRef(false)

  const disabledCondition = useMemo(
    function () {
      return (
        fullNameError.length !== 0 ||
        mailError.length !== 0 ||
        messageError.length !== 0 ||
        phoneNumberError.length !== 0 ||
        isSending ||
        !isNameChanged.current ||
        !isMessageChanged.current ||
        !isToChanged.current
      )
    },
    [
      fullNameError,
      phoneNumberError,
      mailError,
      messageError,
      isSending,
      isNameChanged.current,
      isMessageChanged.current,
      isToChanged.current,
    ],
  )

  const sendEmail = async () => {
    setIsSending(true)
    try {
      await emailjs.send(
        String(process.env.REACT_APP_EMAIL_SERVICE),
        String(process.env.REACT_APP_CONTACT_US_TEMPLATE),
        {
          to_email: 'business@exgoland.io',
          phone_number: phoneNumber,
          email: to,
          from_name: fullName,
          message,
        },
        process.env.REACT_APP_EMAIL_API_KEY,
      )
    } catch (error) {
      console.error(error)
    }
    setIsSending(false)
  }

  const fullNameChecking = () => {
    if (fullName.length === 0 && isNameChanged.current) {
      setFullNameError(errorMessage)
    }
    if (fullName.length !== 0) {
      const result = isNameValid(fullName.trim())
      if (typeof result === 'string') setFullNameError(result)
      else {
        setFullNameError('')
      }
    }
  }

  const emailChecking = () => {
    if (to.length === 0 && isToChanged.current) {
      setMailError(errorMessage)
    }
    if (to.length !== 0) {
      if (isMailValid(to)) {
        setMailError('')
      } else {
        setMailError('Please enter a valid email address')
      }
    }
  }

  useEffect(() => {
    emailChecking()
  }, [to])

  const messageChecking = () => {
    if (message.length === 0 && isMessageChanged.current) {
      setMessageError(errorMessage)
    } else {
      setMessageError('')
    }
  }

  const phoneValidator = (phone: string, countryCode: string) => {
    if (phone.substring(0, countryCode.length) !== countryCode) {
      setPhoneNumberError(phoneErrorMessage)
    } else {
      setPhoneNumberError('')
    }
  }

  const handleChangePhoneNumber = (phone: string, country: Country) => {
    if (phone.length > country.dialCode.length) {
      setPhoneNumber(phone)
      isPhoneNumberChanged.current = true
    }
    phoneValidator(phone, country.dialCode)
  }

  const handleSubmit = (event: React.FormEvent<HTMLDivElement>) => {
    event.preventDefault()
    if (!disabledCondition) {
      sendEmail()
      setTo('')
      setFullName('')
      setMessage('')
      setPhoneNumber('')
      isNameChanged.current = false
      isPhoneNumberChanged.current = false
      isMessageChanged.current = false
      isToChanged.current = false
    }
  }

  const closeSuccessModal = () => {
    setIsSubmitted(false)
  }

  const handleInputChange = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const inputChar = String.fromCharCode(event.keyCode) // get the character that was typed
    const isLetterOrSpace = /^[A-Za-z\s]*$/i.test(inputChar) // check if the character is a letter or space
    const isBackspace = event.keyCode === 8 // check if the key pressed is the backspace key
    const isSpecialChar =
      event.keyCode === 111 ||
      event.keyCode === 110 ||
      event.keyCode === 106 ||
      event.keyCode === 109 ||
      event.keyCode === 107

    if ((!isLetterOrSpace && !isBackspace) || isSpecialChar) {
      event.preventDefault() // block special characters
      setFullNameError('Your name must not contain symbols')
    }

    if (!isLetterOrSpace && !isSpecialChar && isBackspace && fullName.length === 0) {
      setFullNameError(errorMessage)
    }
  }

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTo(e.target.value)
    isToChanged.current = true
  }

  const textTypeCheck = (text: string) => {
    if (!/\d/.test(text)) {
      setFullName(text)
      isNameChanged.current = true
    } else {
      setFullNameError('Your name must not contain numbers')
    }
  }

  useEffect(() => {
    fullNameChecking()
  }, [fullName])

  return (
    <Container className={styles.wrapper}>
      <Navigation type='black' />
      <Box className={styles.main}>
        <Container className={styles.leftSection}>
          <Container className={styles.descWrapper}>
            <Typography variant='subtitle1' className={styles.description}>
              Feel Free to contact us any time. We will get back to you as soon as we can!
            </Typography>
          </Container>
          <Container className={styles.info}>
            <Typography variant='subtitle2' className={`${styles.infoData} ${styles.hoverEffect}`}>
              <img src={post} alt='post' />
              <Link to={'mailto:business@exgoland.io'} target='_blank' className={styles.link}>
                business@exgoland.io
              </Link>
            </Typography>
            <Typography variant='subtitle2' className={`${styles.infoData} ${styles.hoverEffect}`}>
              <img width={27} src={web} alt='post' />
              <Link to={'https://exgoland.io/'} target='_blank' className={styles.link}>
                exgoland.io
              </Link>
            </Typography>
            <Typography variant='subtitle2' className={styles.infoData}>
              <img src={map} alt='location' />
              Dubai, United Arab Emirates
            </Typography>
            <Typography variant='subtitle2' className={styles.infoData}>
              <img width={50} src={qr} alt='location' />
              Scan for more!
            </Typography>
          </Container>
        </Container>
        <Container className={styles.second}>
          <FormControl className={styles.touch}>
            <Typography variant='h4' className={styles.title}>
              Get in Touch
            </Typography>
            <Box className={styles.box} onSubmit={handleSubmit}>
              <form className={styles.box}>
                <Box className={styles.topSection}>
                  <Box
                    className={styles.topSectionContainer}
                    sx={{
                      position: 'relative',
                      width: '40%',
                    }}>
                    <TextField
                      type={'text'}
                      placeholder='Full name'
                      className={`${styles.input} ${fullNameError.length > 0 ? styles.error : ''}`}
                      autoComplete='off'
                      value={fullName}
                      onChange={(e) => {
                        textTypeCheck(e.target.value)
                      }}
                      onKeyDown={handleInputChange}
                    />
                    {fullNameError.length > 0 && (
                      <Typography className={styles.errorMessage}>{fullNameError}</Typography>
                    )}
                  </Box>

                  <Box
                    className={`${styles.phoneInputContainer} ${
                      phoneNumberError.length > 0 && styles.phoneInputContainerError
                    }`}>
                    <PhoneInput
                      buttonClass={styles.numberDropdownButton}
                      containerClass={styles.numberInputField}
                      inputClass={styles.phoneNumberInput}
                      placeholder='Phone number (optional)'
                      enableTerritories
                      value={phoneNumber}
                      disableCountryGuess={true}
                      country={'gb'}
                      onChange={(phone: string, country: Country) => {
                        handleChangePhoneNumber(phone, country)
                      }}
                    />
                    {phoneNumberError.length > 0 && (
                      <Typography className={styles.phoneErrorMessage}>
                        {phoneNumberError}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <Box />
                <Box className={styles.topSectionContainer2}>
                  <TextField
                    placeholder='Mail'
                    autoComplete='off'
                    className={`${styles.input} ${mailError.length > 0 ? styles.error : ''}`}
                    value={to}
                    onChange={handleEmailChange}
                  />
                  {mailError.length > 0 && (
                    <Typography className={styles.errorMessage}>{mailError}</Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    width: '100%',
                    mb: '50px',
                  }}
                  className={styles.topSectionContainer2}>
                  <TextField
                    placeholder='Your message...'
                    autoComplete='off'
                    className={`${styles.input} ${messageError.length > 0 ? styles.error : ''}`}
                    multiline
                    onBlur={messageChecking}
                    rows={5.4}
                    onChange={(e) => {
                      setMessage(e.target.value)
                      isMessageChanged.current = true
                    }}
                    value={message}
                  />
                  {messageError.length > 0 && (
                    <Typography className={styles.errorMessage}>{messageError}</Typography>
                  )}
                </Box>
                <Button
                  type='submit'
                  variant='contained'
                  sx={{ gridArea: 'btn' }}
                  className={styles.btn}
                  disabled={disabledCondition}
                  onClick={() => {
                    setIsSubmitted(true)
                  }}>
                  {isSending ? 'Sending...' : 'Submit'}
                </Button>
              </form>
            </Box>
          </FormControl>
        </Container>
      </Box>
      <ConfirmationModal
        isOpen={isSubmitted}
        handleOpen={closeSuccessModal}
        title='Thank you for messaging us!'
        subTitle='Your message has been received. We will get back to you within two business days.'
        isContactUs={true}
      />
      <Footer />
    </Container>
  )
}
