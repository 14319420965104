import { Box, Typography, useMediaQuery } from '@material-ui/core'

import styles from './outlinevision.module.scss'

export default function OutlinePage() {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={styles.pageContainer}>
      <Box className={styles.leftSection}>
        <Typography className={styles.pageTitle}>Outline of the Vision</Typography>
        <Typography className={styles.pageDescription}>
          The vision for <strong>EXGOLAND</strong> is to be one of the leaders in the metaverse industry.
          Our mission is to provide entrepreneurs and users a universe of opportunities to build, own and operate businesses.
        </Typography>
      </Box>
    </Box>
  )
}
