import type { WalletContextState } from '@solana/wallet-adapter-react'
import api from '../services/axios.service'
import { INftData } from '../types/nft-map-types'

const MinimumAllowedLand = 2

export const fetchNftData = async (): Promise<INftData[] | undefined> => {
  try {
    const { data } = await api.get('/nfts/all')
    if (!data) throw new Error('Data not found')

    return data
  } catch (error) {
    console.error(error)
  }
}

export const getNeighborLands = async (landCoordinates: number[][], wallet?: WalletContextState, magicWalletAddress?: string) => {
  const nftData = await fetchNftData()
  if (!nftData) return

  const publicKey = wallet?.publicKey ? wallet.publicKey.toBase58() : magicWalletAddress
  
  let userNfts: string | any[] = []
  if (publicKey) {
    userNfts = nftData.filter((el: INftData) => {
      return el.userId?.walletAddress === publicKey
    })  
  }

  if (!userNfts || !userNfts.length) return

  const result: INftData[] = []
  for (let k = 0; k < landCoordinates.length; k++) {
    for (let i = 0; i < userNfts.length; i++) {
      for (let j = 0; j < userNfts[i].coordinates.length; j++) {
        const [x, y] = userNfts[i].coordinates[j].split(', ').map(Number)
        if (
          (landCoordinates[k][0] + 1 === x && landCoordinates[k][1] === y) ||
          (landCoordinates[k][0] - 1 === x && landCoordinates[k][1] === y) ||
          (landCoordinates[k][1] + 1 === y && landCoordinates[k][0] === x) ||
          (landCoordinates[k][1] - 1 === y && landCoordinates[k][0] === x) ||
          (landCoordinates[k][0] - 1 === x && landCoordinates[k][1] - 1 === y) ||
          (landCoordinates[k][0] - 1 === x && landCoordinates[k][1] + 1 === y) ||
          (landCoordinates[k][0] + 1 === x && landCoordinates[k][1] - 1 === y) ||
          (landCoordinates[k][0] + 1 === x && landCoordinates[k][1] + 1 === y) ||
          (landCoordinates[k][0] === x && landCoordinates[k][1] === y)
        ) {
          result.push(userNfts[i])
          break
        }
      }
    }
  }

  if (result.length < MinimumAllowedLand) return

  const removedDublicates = new Set(result)
  const neighbourLands: INftData[] = []

  removedDublicates.forEach((el) => {
    neighbourLands.push(el)
  })
  if (neighbourLands.length < MinimumAllowedLand) return

  return neighbourLands
}
