import { Divider, List, ListItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import styles from './tokenBenefits.module.scss'

const data = [
  {
    title: 'Metaverse Access & Interactions',
    content:
      'EXGO tokens serve as keys to unlock exclusive domains and premium offerings in the EXGOLAND Metaverse, ensuring richer and more immersive user experiences.',
    list: [],
  },
  {
    title: 'Virtual Real Estate & Assets',
    content: 'EXGO tokens are pivotal for transactions involving virtual terrains and assets. They can be used for purchasing, trading, or leasing virtual properties, reinforcing the token\'s utility and demand.',
    list: [],
  },
  {
    title: 'NFT Marketplace',
    content: 'Our NFT marketplace, EXGONFT, primarily uses EXGO tokens for transactional processes, whether for purchasing, selling, or auctioneering NFTs.',
    list: [],
  },
  {
    title: 'Staking Rewards',
    content: 'Users can stake EXGO tokens to derive rewards. This model incentivizes long-term holding, reducing the active circulation and potentially enhancing token value.',
    list: [],
  },
  {
    title: 'Community Engagement',
    content: 'Be a part of engaging community activities like hackathons or design contests and earn or spend EXGO tokens for participation and achievements.',
    list: [],
  },
  {
    title: 'Education & Training',
    content: 'EXGO tokens facilitate access to a plethora of workshops, webinars, and training modules centered around the Metaverse and NFT ecosystem.',
    list: [],
  },
  {
    title: 'Voting & Governance',
    content:
      'Token holders are empowered to play a decisive role in platform evolution. They can propose alterations, participate in vital decisions, and vote on various platform-related topics.',
    list: [],
  },
  {
    title: 'Strategic Partnerships & Collaborations',
    content:
      'EXGO tokens can unlock advanced collaboration tools, promoting seamless integrations with diverse platforms and the formulation of strategic alliances.',
    list: [],
  },
  {
    title: 'Referral & Onboarding Bonuses',
    content: 'Promote EXGOLAND, introduce new users, and earn EXGO tokens as a gesture of appreciation.',
    list: [],
  },
  {
    title: 'Token Buybacks & Burns',
    content: 'A dedicated fraction of platform-generated profits will periodically buy back EXGO tokens. Token holders will have voting rights to decide on the percentage designated for buybacks. These repurchased tokens will subsequently be eliminated, aiming to increase the token\'s intrinsic value.',
    list: [],
  },
  {
    title: 'Customization & Personal Touch',
    content:
      'Dedicate your EXGO tokens towards refining and personalizing avatars, virtual environments, and myriad customizable facets of the metaverse.',
    list: [],
  },
]

export default function TokenBenefitsPage() {
  return (
    <Box className={styles.featuresContainer}>
      <Typography sx={{ fontFamily: 'DM Sans' }} className={styles.featuresTitle}>
        Token Benefits
      </Typography>
      <Typography className={styles.featuresDescription}>
        <strong>$EXGO</strong> token has many benefits, here are some of the benefits and we might add more in the future.
      </Typography>
      {data.map((el, index) => (
        <Box key={index}>
          <Typography className={styles.subTitle}>{el.title}</Typography>
          <Divider className={styles.divider} />
          <Typography className={styles.text}>{el.content}</Typography>
          {el.list.length > 0 && (
            <List
              sx={{
                mt: '10px',
              }}
              className={styles.list}>
              {el.list.map((li, index) => (
                <ListItem key={index} className={styles.featuresListItem}>
                  <span className={styles.listItemMarker} />
                  <Typography className={styles.listItemText}>{li}</Typography>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      ))}
      
    </Box>
  )
}
