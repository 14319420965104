import Linkedin from '../../../src/assets/images/linkedinIcon.svg'
import Twitter from '../../../src/assets/images/shareModal/Twitter.svg'
import file from '../../../src/assets/images/shareModal/file.svg'
import Email from '../../assets/images/shareModal/Email.svg'
import {
  shareEmailUrl,
  shareLinkedinUrl,
  shareTwitterUrl,
} from './socialMediaUrl'

export const ShareModalItems = [
  {
    imgSrc: file,
    path: '',
  },
  {
    imgSrc: Email,
    path: shareEmailUrl,
  },
  {
    imgSrc: Twitter,
    path: shareTwitterUrl,
  },
  {
    imgSrc: Linkedin,
    path: shareLinkedinUrl,
  },
]

export const shareAppText = 'EXGOLAND provides you with numerous of opportunities to build, own and manage your world. Build what comes to your mind from concerts, events, marketplaces and games.'
