import { Magic } from 'magic-sdk';
import { SolanaExtension } from '@magic-ext/solana';
import { Cluster, clusterApiUrl } from '@solana/web3.js';

const rpcUrl = 'https://api.devnet.solana.com';
const dedicatedMagicPk = 'pk_live_317D52E7B34810B6'
const solanaNetworkRpc = process.env.REACT_APP_RPC_URL || clusterApiUrl((process.env.REACT_APP_SOLANA_NETWORK || 'devnet') as Cluster)

export const magicInstance = new Magic(dedicatedMagicPk, {
  extensions: {
    solana: new SolanaExtension({
      rpcUrl: solanaNetworkRpc
    })
  }
});
