import { useEffect, useContext, useCallback, useState } from 'react'
import { Box, CardContent, Typography, useMediaQuery } from '@mui/material'
import { GlowWalletName } from '@solana/wallet-adapter-glow'
import { PhantomWalletName } from '@solana/wallet-adapter-phantom'
import { OKXWalletName } from '@stay.hungry07212/wallet-adapter-okxwallet'
import { WalletConnectWalletName } from '@solana/wallet-adapter-walletconnect'
import { useWallet } from '@solana/wallet-adapter-react'
import { useNavigate } from 'react-router'

import { useUserContext } from '../../context/UserContext'
import { authenticate, authenticateMagicWallet } from '../../services/auth.service'
import { WalletConnectContext } from '../../providers/web3'
import { glowUrl, glowUrlMobile, phantomUrl, phantomUrlMobile, okxUrl, okxUrlMobile, walletConnectUrl } from '../../utils/constants/URL'
import glow from '../../assets/images/Glow logo.svg'
import okx from '../../assets/images/okx_wallet_icon.svg'
import crossmint from '../../assets/images/partners/crossmint.svg'
import disconnectIcon from '../../assets/images/disconnectIcon.svg'
import ghost from '../../assets/images/ghost.svg'
import walletConnectLogo from '../../assets/images/walletConnectLogo.svg'
import magiclogo from '../../assets/images/magiclogo.svg'

import styles from './top.module.scss'
import { magicInstance } from '../../services/magic.service'

export default function WalletButtons({ signInWallet } : { signInWallet?: () => void }) {
  const navigate = useNavigate()
  const wallet = useWallet()
  const isMobile = useMediaQuery('(max-width: 600px)')
  const { closeModal, showCrossMint, openMagicModal } = useContext(WalletConnectContext)
  const accessToken = sessionStorage.getItem('accessToken')
  const [isWalletConnected, setIsWalletConnected] = useState<boolean>(false)
  const { isLoggedin, setUser, checkLoggedInStatus, user } = useUserContext()

  const userAuth = async () => {
    if (isWalletConnected && wallet.connected && !accessToken) {
      const response = await authenticate(wallet)
      if (response) closeModal()
    }
  }

  const isMobileWithCryptoSupport =
    /android|iphone|ipad/i.test(navigator.userAgent) &&
    typeof (window as Window & { solana?: any }).solana !== 'undefined'

  const auth = async () => {
    if (wallet.connected || isLoggedin) {
      await userAuth()
    }
  }

  useEffect(() => {
    if (isWalletConnected || isLoggedin) auth()
  }, [wallet.connected ,isWalletConnected, isLoggedin])

  const connectWallet = async () => {
    if (wallet.wallet !== null && !wallet.connected) {
      await wallet.connect()
    }
  }

  const isWalletExtensionDetected = async (walletUrl: string) => {
    if (wallet.wallet?.readyState === 'NotDetected' && !isMobileWithCryptoSupport) {
      window.open(walletUrl)
    } else {
      await connectWallet()
    }
  }

  const connectPhantom = async () => {
    if (isMobile) {
      if (navigator.userAgent.includes('Phantom')) {
        await wallet.select(PhantomWalletName)
        await isWalletExtensionDetected(phantomUrlMobile)
        setIsWalletConnected(true)
      } else {
        window.open(`https://phantom.app/ul/browse/${encodeURIComponent(window.location.href)}`)
      }
    } else {
      wallet.select(PhantomWalletName)
      await isWalletExtensionDetected(phantomUrl)
      setIsWalletConnected(true)
    }
  }

  const connectGlow = async () => {
    if (isMobile && navigator.userAgent.includes('IPhone')) {
      await wallet.select(GlowWalletName)
      await connectWallet()
      setIsWalletConnected(true)

    } else if (isMobile && navigator.userAgent.includes('Glow')) {
      window.open(glowUrlMobile)
    } else {
      await wallet.select(GlowWalletName)
      await isWalletExtensionDetected(isMobile ? glowUrlMobile : glowUrl)
      setIsWalletConnected(true)
    }
  }

  const connectOkx = async () => {
    if (isMobile && navigator.userAgent.includes('IPhone')) {
      await wallet.select(OKXWalletName)
      await connectWallet()
      setIsWalletConnected(true)

    } else if (isMobile && navigator.userAgent.includes('Okx')) {
      window.open(okxUrlMobile)
    } else {
      await wallet.select(OKXWalletName)
      await isWalletExtensionDetected(isMobile ? okxUrlMobile : okxUrl)
      setIsWalletConnected(true)
    }
  }

  const connectWalletConnect = async () => {
    if (isMobile && navigator.userAgent.includes('IPhone')) {
      await wallet.select(WalletConnectWalletName)
      await connectWallet()
      setIsWalletConnected(true)

    } else if (isMobile && navigator.userAgent.includes('WalletConnect')) {
      window.open(walletConnectUrl)
    } else {
      await wallet.select(WalletConnectWalletName)
      await isWalletExtensionDetected(walletConnectUrl)
      setIsWalletConnected(true)
    }
  }

  const connectCrossmint = async () => {
    console.log('crossmint connection!')
  }

  const connectMagic = async () => {
    console.log('magic connection!')
    try {
      // await magicInstance.auth.loginWithEmailOTP({ email: 'stay.hungry07212@gmail.com' });
      // setUserLoggedin(true)
      closeModal()
      if (typeof signInWallet === 'function') {
        signInWallet()
      } else {
        // navigate('/magic_wallet')
        openMagicModal()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const disconnectWallet = async () => {
    wallet.wallet?.adapter.disconnect()
    sessionStorage.removeItem('accessToken')
    closeModal()
  }

  const disconnectMagic = async () => {
    try {
      console.log('disconnectMagic')
      await magicInstance.user.logout()
      sessionStorage.removeItem('accessToken')
      // setUser(null)
      // checkLoggedInStatus()
      checkLoggedInStatus()
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <>
      <Box className={(wallet.connected && wallet.wallet?.adapter.name === 'Phantom') ? styles.connectedSection : styles.buttonContainer}>
        <CardContent
          onClick={() => {
            connectPhantom()
          }}
          className={`${styles.wrapper} ${
            wallet.connected && wallet.wallet?.adapter.name === 'Phantom'
              ? styles.activeWrapper
              : styles.inactiveWrapper
          }`}>
          <img
            src={ghost}
            alt='icon'
            className={`${!isMobile ? styles.bigLogo : styles.mobileLogo}`}
          />
          <Typography className={styles.element}>Phantom</Typography>
        </CardContent>
        {wallet.connected && wallet.wallet?.adapter.name === 'Phantom' && (
          <Box onClick={disconnectWallet} className={styles.disconnectButton}>
            <img src={disconnectIcon} />
          </Box>
        )}
      </Box>
      <Box className={(wallet.wallet?.adapter.name === GlowWalletName && wallet.connected) ? styles.connectedSection: styles.buttonContainer}>
        <CardContent
          onClick={() => {
            connectGlow()
          }}
          className={`${styles.wrapper} ${
            wallet.wallet?.adapter.name === GlowWalletName && wallet.connected
              ? styles.activeWrapper
              : styles.inactiveWrapper
          }`}>
          <img
            src={glow}
            alt='icon'
            className={`${!isMobile ? styles.bigLogo : styles.mobileLogo}`}
          />
          <Typography className={styles.element}>Glow</Typography>
        </CardContent>
        {wallet.connected && wallet.wallet?.adapter.name === GlowWalletName && (
          <Box onClick={disconnectWallet} className={styles.disconnectButton}>
            <img src={disconnectIcon} />
          </Box>
        )}
      </Box>

      <Box className={(wallet.wallet?.adapter.name === OKXWalletName && wallet.connected) ? styles.connectedSection: styles.buttonContainer}>
        <CardContent
          onClick={() => {
            connectOkx()
          }}
          className={`${styles.wrapper} ${
            wallet.wallet?.adapter.name === OKXWalletName && wallet.connected
              ? styles.activeWrapper
              : styles.inactiveWrapper
          }`}>
          <img
            src={okx}
            alt='icon'
            className={`${!isMobile ? styles.bigLogo : styles.mobileLogo}`}
          />
          <Typography className={styles.element}>{OKXWalletName}</Typography>
        </CardContent>
        {wallet.connected && wallet.wallet?.adapter.name === OKXWalletName && (
          <Box onClick={disconnectWallet} className={styles.disconnectButton}>
            <img src={disconnectIcon} />
          </Box>
        )}
      </Box>

      <Box className={(wallet.wallet?.adapter.name === WalletConnectWalletName && wallet.connected) ? styles.connectedSection: styles.buttonContainer}>
        <CardContent
          onClick={() => {
            connectWalletConnect()
          }}
          className={`${styles.wrapper} ${
            wallet.wallet?.adapter.name === WalletConnectWalletName && wallet.connected
              ? styles.activeWrapper
              : styles.inactiveWrapper
          }`}>
          <img
            src={walletConnectLogo}
            alt='icon'
            className={`${!isMobile ? styles.bigLogo : styles.mobileLogo}`}
          />
          <Typography className={styles.element}>{WalletConnectWalletName}</Typography>
        </CardContent>
        {wallet.connected && wallet.wallet?.adapter.name === WalletConnectWalletName && (
          <Box onClick={disconnectWallet} className={styles.disconnectButton}>
            <img src={disconnectIcon} />
          </Box>
        )}
      </Box>
      {/* <Box className={!isLoggedin ? styles.buttonContainer : styles.connectedSection}>
        <CardContent
          onClick={() => {
            connectMagic()
          }}
          className={`${styles.wrapper} ${isLoggedin ? styles.activeWrapper : styles.inactiveWrapper}`}>
          <img
            src={magiclogo}
            alt='icon'
            className={!isMobile ? styles.bigLogo : styles.mobileLogo}
          />
          <Typography className={styles.element}>Use Credit Card</Typography>
        </CardContent>
        {isLoggedin && (
          <Box onClick={disconnectMagic} className={styles.disconnectButton}>
            <img src={disconnectIcon} />
          </Box>
        )}
      </Box> */}
      {showCrossMint && (
        <Box
          className={`${!wallet.connected ? styles.buttonContainer : styles.connectedSection} ${
            styles.disabled
          }`}>
          <CardContent
            onClick={() => {
              connectCrossmint()
            }}
            className={`${styles.wrapper} ${styles.inactiveWrapper}`}>
            <img
              src={crossmint}
              alt='icon'
              className={!isMobile ? styles.bigLogo : styles.mobileLogo}
            />
            <Typography className={styles.element}>Crossmint</Typography>
          </CardContent>
        </Box>
      )}
    </>
  )
}
