import { Box, Typography } from '@material-ui/core'
import industryTrendsImg from '../../../../assets/images/industryTrends.svg'
import styles from './industrytrends.module.scss'
import { industryTrendsTexts } from './types'

export default function IndustryTrends() {
  return (
    <Box className={styles.pageContainer}>
      <Box className={styles.leftSection}>
        <Typography className={styles.pageTitle}>Industry Trends</Typography>
        {industryTrendsTexts.map((item) => (
          <>
            <Typography className={styles.pageSubTitle}>{item.title}</Typography>
            <Typography className={styles.pageText}>{item.text}</Typography>
          </>
        ))}
      </Box>
      <Box className={styles.rightSection}>
        <img src={industryTrendsImg} />
      </Box>
    </Box>
  )
}
