import { S3Client } from '@aws-sdk/client-s3'
// This relies on a Region being set up in your local AWS config.
const s3Client = new S3Client({
  region: 'us-east-1',
  credentials: {
    accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID || '',
    secretAccessKey: process.env.REACT_APP_ACCESS_KEY || '',
  },
});

const getS3ObjectUrl = (key: string) => {
  return `https://${process.env.REACT_APP_BUCKET}.s3.amazonaws.com/${key}`
}

const getS3ImageKeyFromUri = (uri: string) => {
  const s3uri =  new URL(uri)
  const key = s3uri.pathname.slice(1)
  return key
}

export { s3Client, getS3ObjectUrl, getS3ImageKeyFromUri };
// snippet-end:[s3.JavaScript.buckets.createclientv3]
