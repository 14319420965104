import { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useConnection, useWallet } from '@solana/wallet-adapter-react'
import { AttachFile } from '@mui/icons-material'
import { TextField } from '@material-ui/core'
import { Checkbox, Box, Button, Typography } from '@mui/material'

import { createPnft, createPnftWithExgolandWallet } from '../../services/metaplex.service'
import ErrorModal from '../shared/Modal/ErrorModal'
import { notEnoughFundsHeader, notEnoughFundsMessage, maintenanceHeader, maintenanceMessage } from '../../utils/constants/textConstants'
import api from '../../services/axios.service'
import WalletButtons from '../Top/walletButtons'
import Modal from '../shared/Modal'
import Loader from '../Loader'
import xIcon from '../../assets/images/x-icon.svg'
import mapPin from '../../assets/images/mapPin.svg'
import usdcLogo from '../../assets/images/usdcLogo.svg'
import solanaLogo from '../../assets/images/solanaLogo.svg'
import RoyaltyInput from '../UI/RoyaltyInput'
import removeImgIcon from '../../assets/images/removeImgIcon.svg'
import { LandDefaultDescription } from '../../utils/constants/landDiscription'
import { INftData } from '../../types/nft-map-types'
import { useUserContext } from '../../context/UserContext'
import CreditCardForm from '../Stripe'

import styles from './marketplacemodal.module.scss'
import { magicInstance } from '../../services/magic.service'
import powerByStripeBlack from '../../assets/images/poweredByStripeBlack.svg'
import { landGasFee } from '../../utils/constants/metaplex'

export default function MarketplaceModal({
  open,
  toggleModal,
  landInfo,
  setMintSuccess,
  totalNftNames
}: {
  open: boolean
  toggleModal: (open: boolean) => void
  landInfo?: any
  setMintSuccess: (value: boolean, nftData: INftData) => void,
  totalNftNames?: string[] | undefined
}) {
  const [landName, setLandName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [landImage, setLandImage] = useState<File | null>(null)
  const [royalty, setRoyalty] = useState<string>('')
  const [landDescription, setLandDescription] = useState<string>('')
  const [agreeWithTermsOfService, setAgreeWithTermsOfService] = useState<boolean>(false)
  const [connectWalletModal, setConnectWalletModal] = useState<boolean>(false)
  const [creditCardModal, setCreditCardModal] = useState<boolean>(false)
  const [mintDisabled, setMintDisabled] = useState<boolean>(false)
  const [mintLoading, setMintLoading] = useState<boolean>(false)
  const [isErrorModalOpen, setIsErrorModalOpen] = useState<boolean>(false)
  const [isMaintenanceModalOpen, setIsMaintenanceModalOpen] = useState<boolean>(false)
  const [showSignInPart, setShowSignInPart] = useState<boolean>(false)
  const { checkLoggedInStatus, isLoggedin, user } = useUserContext()
  const [loading, setLoading] = useState<boolean>(false)

  const wallet = useWallet()
  const navigate = useNavigate()
  const { connection } = useConnection()

  const getWalletBalance = () => {
    console.log('wallet public key', wallet?.publicKey)
    if (wallet.publicKey) {
      return connection.getBalance(wallet.publicKey)
    }
  }

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file != null) {
      setLandImage(file)
    }
  }

  const handleClose = () => {
    toggleModal(false)
    setLandImage(null)
    setLandName('')
    setRoyalty('')
    setLandDescription('')
    setAgreeWithTermsOfService(false)
  }

  const sendCommission = (signature: string, nftAddress: string) => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'pap_x2s6df8d'
    script.src = 'https://affiliate.exgoland.io/scripts/76gv7j'
    script.onload = () => {
      const scriptCode = `
        PostAffTracker.setAccountId('default1');
        var sale = PostAffTracker.createSale();
        sale.setTotalCost('299');
        sale.setOrderID('${signature}');
        sale.setProductID('${nftAddress}');
        PostAffTracker.register();
      `
      const scriptElement = document.createElement('script')
      scriptElement.type = 'text/javascript'
      scriptElement.innerHTML = scriptCode
      document.head.appendChild(scriptElement)
    }
    document.head.appendChild(script)
  }

  const mint = async () => {
    if (wallet.connected) {
      setMintDisabled(true)
      setMintLoading(true)
      const balance = await getWalletBalance()
      console.log('balance', balance)
      if ((balance !== undefined && balance / Math.pow(10, 9) > landGasFee)) {
        setConnectWalletModal(false)
        try {
          const landCoordinates = landInfo?.coordinates
            .map((coordinate: string) => coordinate.trim())
            .join(' X ')

          const attributes = [
            {
              trait_type: 'Coordinates',
              value: landCoordinates,
            },
          ]

          const response = await createPnft(landName, landImage, wallet, royalty, attributes)
          if (!response) throw new Error('something went wrong when creating NFT')

          const { nft, uploadedImgUrl, signature } = response

          if (nft) {
            const nftData = await api.post('/nfts/create', {
              metaDataUrl: nft.uri,
              address: nft.address,
              coordinates: landInfo?.coordinates,
              name: nft.name,
              description: landDescription === '' ? LandDefaultDescription : landDescription,
              imageUrl: uploadedImgUrl ?? '',
              isDefaultImg: !landImage,
            })

            console.log('nftData', nftData)

            if (nftData) {
              sendCommission(signature.toString(), nft.address.toString())
              setMintSuccess(true, nftData.data)
            } else {
              console.log('collection verification failed')
            }
          }
        } catch (error) {
          console.error(error)
        } finally {
          setMintLoading(false)
          setMintDisabled(false)
          handleClose()
        }
      } else {
        setMintLoading(false)
        setMintDisabled(false)
        setIsErrorModalOpen(true)
      }
    } else if(isLoggedin) { 
      // console.log(isLoggedin)
      setCreditCardModal(true)
    } else {
      setConnectWalletModal(true)
    }
  }

  const mintByCredtCard = async () => {
    console.log('mintByCreditCard')

    if (isLoggedin) {
      setMintLoading(true)
      try {
        const landCoordinates = landInfo?.coordinates
          .map((coordinate: string) => coordinate.trim())
          .join(' X ')

        const attributes = [
          {
            trait_type: 'Coordinates',
            value: landCoordinates,
          },
        ]
        const response = await createPnftWithExgolandWallet(landName, landImage, wallet, royalty, attributes, user?.publicAddress)
        if (!response) throw new Error('something went wrong when creating NFT')

        const { nft, uploadedImgUrl, signature } = response
        if (nft) {
          const nftData = await api.post('/nfts/create', {
            metaDataUrl: nft.uri,
            address: nft.address,
            coordinates: landInfo?.coordinates,
            name: nft.name,
            description: landDescription === '' ? LandDefaultDescription : landDescription,
            imageUrl: uploadedImgUrl ?? nft.image,
            isDefaultImg: !landImage,
          })

          if (nftData) {
            sendCommission(signature.toString(), nft.address.toString())
            setMintSuccess(true, nftData.data)
          } else {
            console.log('collection verification failed')
          }
        }
      } catch (error) {
        console.error(error)
      } finally {
        setMintLoading(false)
        setCreditCardModal(false)
        handleClose()
      }
    } else {
      setConnectWalletModal(true)
    }
  }

  useEffect(() => {
    if (landName.length === 0 || !agreeWithTermsOfService || royalty.length === 0) {
      setMintDisabled(true)
    } else {
      setMintDisabled(false)
    }
  }, [landName, agreeWithTermsOfService, landImage, royalty])

  const handleCloseWalletsModal = () => {
    setConnectWalletModal(false)
  }

  const handleCloseCreditCardModal = () => {
    setCreditCardModal(false)
  }

  const removeSelectedImage = () => {
    setLandImage(null)
  }

  const handleClickErrorModal = () => {
    setIsErrorModalOpen(!isErrorModalOpen)
  }

  const handleClickMaintenanceModal = () => {
    navigate('/')
  }

  const signInWallet = () => {
    handleCloseWalletsModal()
    setShowSignInPart(true)
    console.log('sign in wallet')
  }

  const loginWallet = async () => {
    setLoading(true)
    try {
      await magicInstance.auth.loginWithEmailOTP({ email });
      await checkLoggedInStatus()
      setCreditCardModal(true)
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div>
      {mintLoading && (
        <Box className={styles.loaderBackground}>
          <Loader isFullScreen={false} background='inherit' loaderColor='#0a3455' />
          <Typography sx={{ textAlign: 'center' }}>
            <div>
              Processing
            </div>
            <div>
              ( DO NOT close this window until done )
            </div>
          </Typography>
          
        </Box>
      )}
      <Modal open={open} onClose={handleClose}>
        {
          showSignInPart ? (
            <Box className={styles.mintLandContainer} sx={{ width: '400px' }}>
        
              <Button onClick={handleClose} className={styles.closeButton}>
                <img src={xIcon} alt='' />
              </Button>
              <Typography className={styles.modalTitle}>
                Please sign up or login
              </Typography>

              <Box className={styles.inputsContainer}>
                <Box className={styles.landNameInputContainer}>
                  <TextField
                    className={styles.landNameInput}
                    type='email'
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value)
                    }}
                  />
                  {email.length === 0 && (
                    <Typography className={styles.placeholderDefault}>
                      Email<Typography className={styles.red}>*</Typography>
                    </Typography>
                  )}
                </Box>

                {/* <Box className={styles.line} sx={{ margin: '20px' }} /> */}
              </Box>
              
              <Box className={styles.btnsSection}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  className={`${!email ? styles.mintDisabledBtn : styles.mintActiveBtn}`}
                  onClick={loginWallet}
                  disabled={!email || loading}>
                  Submit
                </Button>
              </Box>
            </Box>
          ) : (
            <Box className={styles.mintLandContainer}>
              <ErrorModal
                isOpen={isErrorModalOpen}
                handleOpen={handleClickErrorModal}
                title={notEnoughFundsHeader}
                subTitle={notEnoughFundsMessage}
              />
              {/* <ErrorModal
                isOpen={isMaintenanceModalOpen || true}
                handleOpen={handleClickMaintenanceModal}
                title={maintenanceHeader}
                subTitle={maintenanceMessage}
              /> */}
              <Button onClick={handleClose} className={styles.closeButton}>
                <img src={xIcon} alt='' />
              </Button>
              <Typography className={styles.modalTitle}>
                Are you sure you want to mint this land?
              </Typography>
              <Box className={styles.inputsContainer}>
                <Box className={styles.landNameInputContainer}>
                  <TextField
                    className={totalNftNames?.includes(landName) ? styles.landNameWarnInput : styles.landNameInput}
                    style={{ color: 'red' }}
                    value={landName}
                    onChange={(event) => {
                      setLandName(event.target.value)
                    }}
                  />
                  {landName.length == 0 && (
                    <Typography className={styles.placeholderDefault}>
                      Land’s name<Typography className={styles.red}>*</Typography>
                    </Typography>
                  )}
                </Box>

                {
                  (totalNftNames?.includes(landName)) && (
                    <Box className={styles.landNameInputContainer}>
                      <Typography sx={{ color: 'red', marginLeft: '1rem' }}>Name already taken</Typography>
                    </Box>
                  )
                }
                
                <Box className={styles.royaltyImgInputsContainer}>
                  <Box className={styles.royaltyInputContainer}>
                    <RoyaltyInput royalty={royalty} setRoyalty={setRoyalty} />
                  </Box>

                  <Box className={styles.landImgInputContainer}>
                    {landImage == null && (
                      <Typography className={styles.landImgText}>Upload an image</Typography>
                    )}
                    <TextField
                      key={String(landImage)}
                      className={styles.landImgInput}
                      onChange={handleImageChange}
                      inputProps={{ accept: 'image/*', type: 'file' }}
                    />
                    <AttachFile className={styles.attachFileIcon} />
                    {landImage != null && (
                      <Box className={styles.landImgBox}>
                        <img
                          src={URL.createObjectURL(landImage)}
                          alt='Uploaded Image'
                          className={styles.uploadedImg}
                        />
                        <img
                          src={removeImgIcon}
                          alt='removeImg'
                          className={styles.removeImgIcon}
                          onClick={removeSelectedImage}
                        />
                      </Box>
                    )}
                  </Box>
                </Box>
                <TextField
                  className={styles.descriptionInput}
                  value={landDescription}
                  multiline
                  rows={4}
                  placeholder='Write a description'
                  onChange={(event) => {
                    setLandDescription(event.target.value)
                  }}
                />
              </Box>
              <Box className={styles.line} />
              <Box className={styles.infoBox}>
                <Typography className={styles.infoBoxTitle}>Location</Typography>
                <Box className={styles.info}>
                  <img src={mapPin} />
                  <span className={styles.secondText}>{landInfo?.coordinates}</span>
                </Box>
              </Box>

              <Box className={styles.line} />
              <Box className={styles.infoBox}>
                <Typography className={styles.infoBoxTitle}>Price</Typography>
                <Box className={styles.info}>
                  <img src={usdcLogo} />{' '}
                  <span className={styles.secondText}>{process.env.REACT_APP_INITIAL_SALE_USDC_PRICE}</span>{' '}
                  <span className={styles.secondText}>
                    USDC
                  </span>
                </Box>
              </Box>
              <Box className={styles.line} />

              <Box className={styles.line} />
              <Box className={styles.infoBox}>
                <Typography className={styles.infoBoxTitle}>Gas Fee</Typography>
                <Box className={styles.info}>
                  <img src={solanaLogo} />{' '}
                  <span className={styles.secondText}>{landGasFee}</span>{' '}
                  <span className={styles.secondText}>SOL</span>
                </Box>
              </Box>
              <Box className={styles.line} />

              <Box className={styles.checkboxContainer}>
                <Checkbox
                  className={styles.checkbox}
                  value={agreeWithTermsOfService}
                  onChange={() => {
                    setAgreeWithTermsOfService(!agreeWithTermsOfService)
                  }}
                  sx={{
                    width: '16px',
                    height: '14px',
                    boxSizing: 'border-box',
                    p: '0px',
                  }}
                  disableRipple
                  color='default'
                  icon={<span className={styles.bpIcon} />}
                  checkedIcon={<span className={styles.bpCheckedIcon} />}
                  inputProps={{ 'aria-label': 'Checkbox demo' }}
                />
                <Typography className={styles.text}>
                  By checking this box, I agree to its{' '}
                  <Link to={'/terms-and-conditions'} target='_blank' style={{ color: 'blue' }}>
                    Terms of Service
                  </Link>
                </Typography>
              </Box>

              <Box className={styles.btnsSection}>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                  className={`${(mintDisabled || totalNftNames?.includes(landName)) ? styles.mintDisabledBtn : styles.mintActiveBtn}`}
                  onClick={mint}
                  disabled={mintDisabled || totalNftNames?.includes(landName)}>
                  Mint
                </Button>
              </Box>
            </Box>
          )
        }
        
      </Modal>
      <Modal open={connectWalletModal} onClose={handleCloseWalletsModal}>
        <Box className={styles.chooseWalletContainer}>
          <Button onClick={handleCloseWalletsModal} className={styles.closeButton}>
            <img src={xIcon} alt='' />
          </Button>
          <Typography className={styles.modalTitle}>Choose the wallet to continue!</Typography>
          <Box className={styles.line} />
          <Box className={styles.buttonsSection}>
            <WalletButtons signInWallet={signInWallet} />
          </Box>
        </Box>
      </Modal>
      <Modal open={creditCardModal} onClose={handleCloseCreditCardModal}>
        <Box className={styles.chooseWalletContainer}>
          <Button onClick={handleCloseCreditCardModal} className={styles.closeButton}>
            <img src={xIcon} alt='' />
          </Button>
          <Typography className={styles.modalTitle}>Credit Card Payment</Typography>
          <Box className={styles.line} />
          <Box className={styles.buttonsSection}>
            <CreditCardForm mintByCredtCard={mintByCredtCard}/>
          </Box>
          <Box
            component="img"
            sx={{
              position: 'absolute',
              height: '30px',
              bottom: '20px',
              left: '20px',
            }}
            alt="Powered by Stripe"
            src={powerByStripeBlack}
          />
          </Box>
      </Modal>
    </div>
  )
}
