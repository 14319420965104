import React, { useMemo, useState } from 'react'
import { useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import CloseIcon from '@mui/icons-material/Close'
import Menu from '@mui/material/Menu'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { mapTopItems } from '../types'
import infoIcon from '../../../assets/images/infoIcon.svg'

import styles from './modal.module.scss'

export default function MapInfoModal() {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = useMemo(() => Boolean(anchorEl), [anchorEl])
  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <div>
      <Button
        className={styles.landsInfoIcon}
        sx={{
          minWidth: '48px',
          height: '38px',
          background: 'rgba(255, 255, 255, 0.9)',
          padding: 0,
          borderRadius: '4px'
        }}
        id='basic-button'
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpen}>
        <img src={infoIcon} width={20} height={20} />
      </Button>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Box
          sx={{
            width: isMobile ? '80%' : 'auto',
            padding: isMobile ? '63px 54px 64px 31px' : '41px 56px 23px 34px',
            display: 'flex',
            flexDirection: 'column',
            rowGap: '15px',
            borderRadius: '8px',
            position: 'relative',
          }}>
          <IconButton
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: isMobile ? '23px' : '11px',
              top: isMobile ? '1px' : '8px',
              padding: '0px',
            }}>
            <CloseIcon />
          </IconButton>
          {mapTopItems.map((elem, index) => {
            return (
              <Box sx={{ display: 'flex', columnGap: '14px', alignItems: 'center' }} key={index}>
                <Box
                  sx={{
                    width: '16px',
                    height: '16px',
                    borderRadius: '8px',
                    background: elem.color,
                  }}
                />
                <Typography
                  sx={{
                    fontFamily: 'DM Sans',
                    fontSize: '16px',
                    fontWeight: '600',
                    color: '#161616',
                  }}>
                  {elem.text}
                </Typography>
              </Box>
            )
          })}
        </Box>
      </Menu>
    </div>
  )
}
