import { useEffect, useState } from 'react';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';

import CheckoutForm from './CheckoutForm';
import api from '../../services/axios.service';
import { getSolPrice } from '../../services/helper.service';
import { landGasFee } from '../../utils/constants/metaplex';
// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripe_pk = 'pk_test_51O2VyPEnXbLXIgb6RmjmvOXDFsglPKiJigtIE09BcR67DSgwjGzOD7CkexBLpYUzjcBG6xjBAkXI1XbRTWtp533d0087654JZj'
const stripePromise = loadStripe(stripe_pk);


export default function CreditCardForm({ mintByCredtCard } : { mintByCredtCard: () => void }) {
  const [clientSecret, setClientSecret] = useState('');
  const [solPrice, setSolPrice] = useState(0)
  const [gasFee, setGasFee] = useState(0)

  useEffect(() => {
    getSolPrice().then((price) => {
      console.log('price', price)
      setSolPrice(price)
    })
  }, [])

  useEffect(() => {
    if (solPrice) {
      setGasFee(+solPrice * landGasFee)
    }

  }, [solPrice  ])
  
  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    // fetch("/create-payment-intent", {
    //   method: "POST",
    //   headers: { "Content-Type": "application/json" },
    //   body: JSON.stringify({ items: [{ id: "xl-tshirt" }] }),
    // })
    //   .then((res) => res.json())
    //   .then((data) => setClientSecret(data.clientSecret));
    const getClientSecret = async () => {
      const data = await api.post('/stripe/create-payment-intent', {
        solPrice,
      })
      setClientSecret(data.data)
      // console.log('data', data.data)
    }

    if (solPrice) {
      getClientSecret()
    }
  }, [solPrice]);
  
  const options = {
    // passing the client secret obtained from the server
    clientSecret: clientSecret,
  };

  return (
    <>
      {clientSecret && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm clientSecret={clientSecret} mintByCredtCard={mintByCredtCard} gasFee={gasFee} />
        </Elements>
      )}
    </>
    
  );
};