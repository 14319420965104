export const shareEmailUrl =
  'mailto:?subject=EXGOLAND&body=EXGOLAND%20provides%20you%20with%20numerous%20of%20opportunities%20to%20build,%20own%20and%20manage%20your%20world.%20Build%20what%20comes%20to%20your%20mind%20from%20concerts,%20events,%20marketplaces%20and%20games.%20https://www.exgoland.io/'
export const shareTwitterUrl =
  'https://twitter.com/intent/tweet?text=EXGOLAND%20provides%20you%20with%20numerous%20opportunities%20to%20build,%20own%20and%20manage%20your%20world.%0A%0ABuild%20what%20comes%20to%20your%20mind%20from%20concerts,%20events,%20marketplaces%20and%20games.%0A%0Ahttps%3A//www.exgoland.io/'
export const shareFacebooklUrl =
  'https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fsharer%2Fsharer.php%3Fu%3Dhttps%253A%252F%252Fwww.exgoland.io%252F&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_US'
export const shareTelegramUrl =
  'https://telegram.me/share/url?url=https://www.exgoland.io/&text=EXGOLAND%20provides%20you%20with%20numerous%20of%20opportunities%20to%20build,%20own%20and%20manage%20your%20world.%20Build%20what%20comes%20to%20your%20mind%20from%20concerts,%20events,%20marketplaces%20and%20games.'
export const shareLinkedinUrl =
  'https://www.linkedin.com/sharing/share-offsite/?url=https://www.exgoland.io'
