import { Box, Typography, List, ListItem, ListItemText } from '@mui/material'
import styles from './twoDMap.module.scss'

const data = {
  map2dMeters: ['16 X 16 Meter', '32 X 32 Meter', '64 X 64 Meter'],
  colors2d: [
    { text: 'Available Land – Green', color: '#99E1D4' },
    { text: 'Minted Land – Blue or profile pic', color: '#1D8DF4' },
    { text: 'Land Unavailable for Sale – Dark blue', color: '#1e296e' },
    { text: 'Land Available on Sale on Marketplaces – Orange', color: '#FF5F1F' },
  ],
}

export default function TwoDMap() {
  return (
    <>
      <Typography className={styles.mapContainerTitle}>2D Map</Typography>
      <Typography className={styles.mapContainerSecondTitle}>
        <strong> EXGOLAND </strong> has a 2D map that contains
      </Typography>
      <Typography className={styles.mapContainerSecondTitle}>
        <strong>100,000 Lands with a size of 64x64 Meters</strong>
      </Typography>
      <Typography className={styles.mapContainerSecondContant}>
        Users will access the market place where they will be able to purchase these land parcels
        using the USD stable token (USDC) until <strong>EXGOLAND</strong> integrates the purchase with EXGO token (EXGO).
        Users will be able to buy, sell & rent their lands with an option to put the minted land for
        bidding where all the users on <strong>EXGOLAND</strong> can take an opportunity to take
        part in the bidding.
        <br />
        <br />
        After minting or buying the land, landowners can place their desired{' '}
        <strong>profile</strong> or <strong>logo </strong>
        <strong>picture</strong> in a way to make it a unique land to be seen by others.
      </Typography>
      <Box className={styles.mapContainer2D}>
        <Box className={styles.secondBox2d}>
          <Typography sx={{ fontWeight: 700, fontSize: '20px', fontFamily: 'DM Sans' }}>
            Users will see different colors for different land status:
          </Typography>
          <Box className={styles.colors2d}>
            <List
              sx={{
                listStyleType: 'disc',
              }}>
              {data.colors2d.map((el, index) => (
                <ListItem className={styles.listItem2d} key={index}>
                  <span className={styles.listItemMarker2d} />
                  <ListItemText
                    primary={el.text}
                    sx={{
                      maxWidth: '345px',
                      width: '70%',
                      display: 'list-item',
                      '& span': { fontWeight: '400 !important' },
                    }}
                  />
                  <Box
                    className={styles.colorItem}
                    sx={{
                      backgroundColor: el.color,
                    }}
                  />
                </ListItem>
              ))}
            </List>
          </Box>
          <Typography>
            Users will have these color explanation as a key in the 2D map. After land selection,
            users will be able to see land details like land location (longitude and latitude),
            name, description, status, price (if any), a zoomed picture of the specific parcel and
            many other useful details.
          </Typography>
        </Box>
      </Box>
    </>
  )
}
