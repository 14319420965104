import React, { useEffect, useState } from 'react';
import {
  PaymentElement,
  LinkAuthenticationElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { PaymentIntentResult, StripeLinkAuthenticationElementChangeEvent } from '@stripe/stripe-js';

import { useUserContext } from '../../context/UserContext';
import './checkoutform.scss'

export default function CheckoutForm({ clientSecret, mintByCredtCard, gasFee } : { clientSecret: string, mintByCredtCard: () => void, gasFee: number}) {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useUserContext();

  // const [email, setEmail] = useState<string>(user?.email);
  const [message, setMessage] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  // useEffect(() => {
  //   if (!stripe) {
  //     return;
  //   }

  //   const clientSecret = new URLSearchParams(window.location.search).get(
  //     'payment_intent_client_secret'
  //   );

  //   if (!clientSecret) {
  //     return;
  //   }

  //   stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
  //     switch (paymentIntent?.status) {
  //       case 'succeeded':
  //         setMessage('Payment succeeded!');
  //         break;
  //       case 'processing':
  //         setMessage('Your payment is processing.');
  //         break;
  //       case 'requires_payment_method':
  //         setMessage('Your payment was not successful, please try again.');
  //         break;
  //       default:
  //         setMessage('Something went wrong.');
  //         break;
  //     }
  //   });
  // }, [stripe]);

  const handleSubmit = async (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);
    
    try {
      const {paymentIntent, error}: PaymentIntentResult = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          // return_url: 'http://localhost:3000',
          receipt_email: user?.email,
        },
        redirect: 'if_required'
      });
  
      switch (paymentIntent?.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          mintByCredtCard()
          break;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      
      <PaymentElement id="payment-element" />
      <button disabled={isLoading || !stripe || !elements} id="submit">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : 'Pay now'}
        </span>
      </button>
      {/* Show any error or success messages */}
      <div id="payment-message">Total Price({ 299 + +(gasFee.toFixed(2)) } USD): Land Price(299 USD) + Gas Fee({gasFee.toFixed(2)} USD)</div>
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}


