import cryptodaily from '../../assets/images/blognews/cryptodaily.svg'
import deccanherald from '../../assets/images/blognews/deccanherald.svg'
import ibtimes from '../../assets/images/blognews/ibtimes.svg'
import khaleej from '../../assets/images/blognews/khaleej.svg'
import laweekly from '../../assets/images/blognews/laweekly.svg'
import leadership from '../../assets/images/blognews/leadership.svg'
import medium from '../../assets/images/blognews/medium-wo-bg.svg'
import midday from '../../assets/images/blognews/midday.svg'
import outlook from '../../assets/images/blognews/outlook.svg'
import vanguard from '../../assets/images/blognews/vanguard.svg'

export const data = [
  {
    title: 'Medium',
    src: medium,
    url: 'https://exgoland.medium.com/',
  },
  {
    title: 'CryptoDaily',
    src: cryptodaily,
    url: 'https://cryptodaily.co.uk/2022/10/the-best-upcoming-metaverse-in-2023-exgoland',
  },
  {
    title: 'Khaleej Times',
    src: khaleej,
    url: 'https://www.khaleejtimes.com/kt-network/exgoland-building-on-dubais-metaverse-vision',
  },
  {
    title: 'Outlook',
    src: outlook,
    url: 'https://www.outlookindia.com/outlook-spotlight/exgoland-the-metaverse-built-to-make-an-impact-on-your-business-news-231646',
  },
  {
    title: 'Leadership',
    src: leadership,
    url: 'https://leadership.ng/exgo-land-project-plans-to-introduce-a-metaverse-based-marketplace-for-trading-assets/',
  },
  {
    title: 'Vanguard',
    src: vanguard,
    url: 'https://www.vanguardngr.com/2022/09/exgo-land-plans-to-develop-a-seamless-user-interface-for-its-enthusiast/',
  },
  {
    title: 'IbTimes',
    src: ibtimes,
    url: 'https://www.ibtimes.sg/exgo-land-taps-into-web-3-0-create-new-metaverse-66760',
  },
  {
    title: 'Deccanherald',
    src: deccanherald,
    url: 'https://www.deccanherald.com/brandspot/pr-spot/exgo-land-uses-3d-images-and-short-videos-to-give-an-edge-to-its-platform-1144728.html',
  },
  {
    title: 'Laweekly',
    src: laweekly,
    url: 'https://www.laweekly.com/exgo-lands-plan-to-enter-the-metaverse-ecosystem-with-unique-utilities/',
  },
  {
    title: 'Midday',
    src: midday,
    url: 'https://www.mid-day.com/brand-media/article/the-exgo-land-project-uses-the-latest-features-23245820',
  },
]
