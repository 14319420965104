export interface ModalTypes {
  location: string
  price: string
}

export enum ButtonText {
  BUY = 'Buy',
  CANCLE = 'Cancel to Sell',
  SELL = 'Sell',
  NOT_ON_SALE = 'Not On Sale',
  MINT = 'Mint',
  SHOW_MORE = 'Show More',
  DISABLED = '',
  SEND = 'Send',
  NONE = 'None',
}
