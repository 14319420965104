import { useEffect, useRef, useState } from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import { ObserverUtil } from '../../utils/observerUtil'
import landBot from '../../assets/images/landBot.png'
import landTop from '../../assets/images/landTop.png'
import { featuresItems } from './types'

import styles from './BuyYourLand.module.scss'

export default function BuyYourLand() {
  const [isVisible, setIsVisible] = useState(false)
  const [isFeaturesVisible, setIsFeaturesVisible] = useState(false)
  const [hasBeenVisible, setHasBeenVisible] = useState(false)
  const [featuresHasBeenVisible, setFeaturesHasBeenVisible] = useState(false)
  const buyYourLandRef = useRef(null)
  const featuresRef = useRef(null)
  const isMobile = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    ObserverUtil(buyYourLandRef, setIsVisible, hasBeenVisible, setHasBeenVisible)
  }, [hasBeenVisible])

  useEffect(() => {
    ObserverUtil(
      featuresRef,
      setIsFeaturesVisible,
      featuresHasBeenVisible,
      setFeaturesHasBeenVisible,
    )
  }, [featuresHasBeenVisible])

  return (
    <div ref={buyYourLandRef} className={styles.landContainer}>
      <div className={styles.landSection1}>
        <div className={isVisible ? styles.landColumn1 : styles.landColumn1Hidden}>
          <div className={styles.contentSection}>
            <span className={styles.buyYourLand}>Buy Your Land</span>
            <p className={styles.subTitle}>Get Exclusive Opportunities </p>
            <div className={styles.opportunities}>
              <div className={styles.oppRow1}>
                <div className={styles.oppColumn}>
                  <p>Be the first owner</p>
                </div>
                <div className={styles.oppColumn}>
                  <p>Buy and combine</p>
                </div>
              </div>
              <div className={styles.oppRow2}>
                <div className={styles.oppColumn}>
                  <p>Put your royalty</p>
                </div>
              </div>
            </div>
            <div className={styles.buyDiscoverBtns}>
              <Link to='/buy-land' className={styles.buyNow}>
                Buy now
              </Link>
              <Link to='/buy-land' className={styles.discoverMap}>
                Discover map
              </Link>
            </div>
          </div>
        </div>

        <div className={styles.landColumn2}>
          <img
            src={landTop}
            className={`${styles.landTop} ${isVisible ? styles.animate : styles.hidden}`}
          />
          <img
            src={landBot}
            className={`${styles.landBot} ${isVisible ? styles.animate : styles.hidden}`}
          />
        </div>
      </div>
      <Box ref={featuresRef} className={styles.featuresContainer}>
        <Typography className={styles.title}>Features</Typography>
        <Box
          component='div'
          sx={{ display: 'flex' }}
          className={`${styles.featuresSection}  ${
            !isMobile ? (isFeaturesVisible ? styles.animate : styles.hidden) : ''
          }`}
        >
          {featuresItems.map((item, index) => (
            <Box key={index} className={styles.featureItemSection}>
              <Box className={styles.featureItem}>
                <Box className={styles.iconSection}>
                  <img src={item.icon} />
                </Box>
                <Typography className={styles.featureTitle}>{item.title}</Typography>
              </Box>
              <Box className={styles.featureHoverItem} sx={{ display: 'none' }}>
                <Typography className={styles.featureHoverTitle}>{item.title}</Typography>
                <Typography className={styles.featureDescription}>{item.description}</Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </div>
  )
}
