import { Umi, Transaction } from '@metaplex-foundation/umi';
import { toWeb3JsTransaction } from '@metaplex-foundation/umi-web3js-adapters';
import { Cluster, Connection, VersionedTransaction, clusterApiUrl } from '@solana/web3.js';
import base58 from 'bs58';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getRequiredCU = async (umi: Umi, transaction: Transaction) => {
  const defaultCU = 800_000;
  const web3tx = toWeb3JsTransaction(transaction);
  const connection = new Connection(umi.rpc.getEndpoint(), 'finalized');
  const simulatedTx = await connection.simulateTransaction(web3tx, {
    replaceRecentBlockhash: true,
    sigVerify: false,
  });
  if (simulatedTx.value.err) {
    return defaultCU;
  }
  return simulatedTx.value.unitsConsumed || defaultCU;
};

const solanaNetwork =
  process.env.REACT_APP_RPC_URL ||
  clusterApiUrl((process.env.REACT_APP_SOLANA_NETWORK || 'devnet') as Cluster)

export const getPriorityFeeEstimate = async (priorityLevel: number, transaction: VersionedTransaction) => {
  const response = await fetch(solanaNetwork, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      jsonrpc: '2.0',
      id: '1',
      method: 'getPriorityFeeEstimate',
      params: [
        {
          transaction: base58.encode(transaction.serialize()), // Pass the serialized transaction in Base58
          options: { priorityLevel: priorityLevel },
        },
      ],
    }),
  });
  const data = await response.json();
  console.log(
    'Fee in function for',
    priorityLevel,
    ' :',
    data?.result?.priorityFeeEstimate
  );
  return data.result;
}
