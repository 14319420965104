import { useNavigate } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import { Box } from '@mui/material'
import Modal from '@mui/material/Modal'
import SuccessIcon from '../../../assets/images/successIcon.svg'
import CloseIcon from '../../../assets/images/x-icon.svg'

import styles from './successMintModal.module.scss'

export default function SuccessMintModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean
  onClose: () => void
}) {
  const navigate = useNavigate()

  const navigateToMyLands = () => {
    navigate('/marketplace')
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className={styles.modalContainer}>
        <img onClick={onClose} className={styles.closeIcon} src={CloseIcon} />
        <img className={styles.confirmIcon} src={SuccessIcon} />
        <Typography>Congrats!</Typography>
        <Typography>The Land is minted</Typography>
        <Typography>
          Click on <span onClick={navigateToMyLands}>this link</span> to go{' '}
          <strong>My Lands page</strong>
        </Typography>
      </Box>
    </Modal>
  )
}
