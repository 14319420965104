import { INftData, ISquareCoord } from '../types/nft-map-types'

interface ITopLeftCoordinates {
  topLeft: number[]
  size: number
}

export const getTopLeftCoordinates = (squareCoordinates: ISquareCoord[]) => {
  const topLeftCoordinates: ITopLeftCoordinates[] = []
  const notPrimaryCombinedLands:  Array<number[][]> = []

  for (let i = 0; i < squareCoordinates.length; i++) {
    const topLeft = findTopLeftCoordinates(squareCoordinates[i].coordinates)
    topLeftCoordinates.push({ topLeft, size: squareCoordinates[i].size })

    const res = squareCoordinates[i].coordinates.filter(
      (item) => item[0] !== topLeft[0] || item[1] !== topLeft[1],
    )
    notPrimaryCombinedLands.push(res)
  }

  
  return {
    topLeftCoordinates,
    notPrimaryCombinedLands,
  }
}

export const findBiggestSquare = (coordinates: number[][]) => {
  let maxSize = 0
  let maxSquare: any = []

  for (let i = 0; i < coordinates.length; i++) {
    for (let j = 0; j < coordinates.length; j++) {
      if (i === j) continue

      const [x1, y1] = coordinates[i]
      const [x2, y2] = coordinates[j]

      const size = Math.max(Math.abs(x2 - x1), Math.abs(y2 - y1)) + 1
      const square = coordinates.filter(
        ([x, y]) => x >= x1 && x <= x1 + size - 1 && y >= y1 && y <= y1 + size - 1,
      )

      if (square.length === size * size && size > maxSize) {
        maxSize = size
        maxSquare = square
      }
    }
  }

  return maxSquare
}

export const getUserCombinedLands = (nftData: INftData[]) => {
  const userCombinedNfts = nftData.filter((el) => {
    return el.coordinates.length > 3
  })

  const data: any = []
  for (let i = 0; i < userCombinedNfts.length; i++) {
    const convertedArray = userCombinedNfts[i].coordinates.map((str) => str.split(',').map(Number))
    const biggestSquareCoordinates = findBiggestSquare(convertedArray)

    if (biggestSquareCoordinates.length < 1) continue

    if (biggestSquareCoordinates.length) {
      data.push({
        coordinates: biggestSquareCoordinates,
        size: Math.sqrt(biggestSquareCoordinates.length),
      })
    }
  }
  return data
}

export const findTopLeftCoordinates = (coordinates: number[][]) => {
  let minX = Infinity
  let minY = Infinity

  for (let i = 0; i < coordinates.length; i++) {
    const [x, y] = coordinates[i]
    minX = Math.min(minX, x)
    minY = Math.min(minY, y)
  }

  return [minX, minY]
}
