import { useState } from 'react'
import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'

import styles from './contentDrawer.module.scss'

export default function ContentDrawer({ data, isMobile, isTerms }: { data: any; isMobile: boolean; isTerms?: boolean }) {
  const [selectedTitle, setSelectedTitle] = useState(0)

  return (
    <Box>
      {isMobile ? <Box className={styles.accordionContainer}>
        {data.content.map((el: any, index: number) => (
          <Accordion key={el.title} className={styles.accordion}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              className={styles.accordionHeaderBox}
              focusVisibleClassName={styles.selectedAccordionHeader}
            >
              <Typography
                key={index}
                className={
                  selectedTitle === index ? styles.selectedAccordionHeader : styles.accordionHeader
                }
              >
                {el.title}
              </Typography>
            </AccordionSummary>
            <Box>
              <AccordionDetails className={styles.accordionItem}>
                <Divider className={styles.divider} />
                {Boolean(el.firstSentence) && (
                  <Box className={styles.accordionText}>
                    <strong>
                      {el.firstSentence}
                      <br />
                      <br />
                    </strong>
                  </Box>
                )}
                <Box className={styles.accordionText}>{el.body}</Box>
              </AccordionDetails>
            </Box>
          </Accordion>
        ))}
      </Box>
        :
        <Box className={styles.drawerContainer}>
          <Box className={styles.titleListContainer}>
            <List className={styles.titleList}>
              {data.content.map((el: any, index: number) => (
                <ListItem
                  onClick={() => {
                    setSelectedTitle(index)
                  }}
                  key={index}
                  className={
                    selectedTitle === index ? styles.selectedTitleListItem : styles.titleListItem
                  }
                >
                  {el.title}
                </ListItem>
              ))}
            </List>
          </Box>
          <Box className={styles.contentContainer}>
            <Typography className={styles.contentTitle}>{data.content[selectedTitle].title}</Typography>
            <Box className={styles.contentBox}>
              <>
                {isTerms === false && (
                  <Typography className={styles.firstSentence}>
                    {data.content[selectedTitle].firstSentence}
                  </Typography>
                )}
                {data.content[selectedTitle].body}
              </>
            </Box>
          </Box>
        </Box>}
    </Box>

  )
}
