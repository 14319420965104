import { Box } from '@mui/material'
import Arrow from '../../assets/images/blognews/Arrow.svg'
import logo from '../../assets/images/blognews/logo.svg'
import Footer from '../../components/Footer'
import Navigation from '../../components/Navigation'

import styles from './blogNews.module.scss'
import { data } from './data'

export default function BlogNews() {
  return (
    <div className={styles.container}>
      <Navigation type='black' />
      <Box className={styles.content}>
        <div className={styles.headerContainer}>
          <img src={logo} className={styles.logo} />
          <h2 className={styles.title}>EXGOLAND</h2>
          <h3 className={styles.subTitle}>Blog and news</h3>
        </div>
        <div className={styles.dataContainer}>
          {data.map((item) => (
            <div
              key={item.title}
              className={styles.dataDiv}
              onClick={() => {
                window.open(item.url, '_blank')
              }}
            >
              <div className={styles.titleContainer}>
                <img src={item.src} />
                <span>{item.title}</span>
              </div>
              <img src={Arrow} className={styles.arrow} />
            </div>
          ))}
        </div>
      </Box>

      <Footer />
    </div>
  )
}
