import airshipIcon from '../../assets/images/partners/airshipIcon.svg'
import audiusIcon from '../../assets/images/partners/audiusIcon.svg'
import crossmint from '../../assets/images/partners/crossmint.svg'
import liveartIcon from '../../assets/images/partners/liveartIcon.svg'
import meanfiIcon from '../../assets/images/partners/meanfiIcon.svg'
import arwave from '../../assets/images/partners/arwave.svg'
import solanaIcon from '../../assets/images/partners/solanaIcon.svg'
import solicyIcon from '../../assets/images/partners/solicyIcon.svg'
import alchemyIcon from '../../assets/images/partners/alchemyIcon.svg'
import magicIcon from '../../assets/images/partners/magicIcon.svg'
import paypalIcon from '../../assets/images/partners/paypalIcon.svg'
import metaplexIcon from '../../assets/images/partners/metaplexIcon.svg'
import magicLinkIcon from '../../assets/images/partners/magicLinkIcon.svg'
import heliusIcon from '../../assets/images/partners/helius.svg'
import irysIcon from '../../assets/images/partners/Irys.svg'

export const partners = [
  {
    icon: solanaIcon,
    url: 'https://solana.com/',
  },
  {
    icon: metaplexIcon,
    url: 'https://www.metaplex.com/',
  },
  {
    icon: magicIcon,
    url: 'https://magiceden.io/',
  },
  {
    icon: paypalIcon,
    url: 'https://www.paypal.com/',
  },
  
  {
    icon: audiusIcon,
    url: 'https://audius.co/',
  },
  {
    icon: meanfiIcon,
    url: 'https://meanfi.com/',
  },
  {
    icon: airshipIcon,
    url: 'https://airship-x.com/',
  },
  {
    icon: liveartIcon,
    url: 'https://liveart.io/',
  },
  {
    icon: arwave,
    url: 'https://www.arweave.org/',
  },
  {
    icon: irysIcon,
    url: 'https://irys.xyz/',
  },
  {
    icon: heliusIcon,
    url: 'https://www.helius.dev/',
  },
  {
    icon: magicLinkIcon,
    url: 'https://magic.link/',
  },
  {
    icon: solicyIcon,
    url: 'https://solicy.net/',
  },
]
