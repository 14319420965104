import {
    createContext,
    useState,
    useContext,
    useEffect,
    useCallback,
  } from 'react'
import { magicInstance } from '../services/magic.service'
import { MagicUserMetadata } from 'magic-sdk'
import { authenticateMagicWallet } from '../services/auth.service'
  
  // Define context
  type UserContextType = {
    user: MagicUserMetadata | any
    setUser: React.Dispatch<React.SetStateAction<MagicUserMetadata | any>>
    checkLoggedInStatus: () => void
    isLoggedin: boolean,
    // setUserLoggedin: React.Dispatch<React.SetStateAction<boolean>>
  }
  
  // Create context with a default value
  const UserContext = createContext<UserContextType>({
    user: {}, // Initially, no user is logged in
    setUser: () => {}, // Placeholder function, will be overwritten by provider
    checkLoggedInStatus: () => {}, // Placeholder function, will be overwritten by provider,
    isLoggedin: false,
    // setUserLoggedin: () => {}
  })
  
  // A custom hook to use context
  export const useUserContext = () => useContext(UserContext)
  
  // Provider component that wraps the app
  export const UserProvider = ({ children }: { children: React.ReactNode }) => {
    // Local state for storing user data
    const [user, setUser] = useState<any>(null)
    const [isLoggedin, setUserLoggedin] = useState<boolean>(false)
  
    // Function to fetch user information from magic
    const fetchUserInfo = useCallback(async () => {
      try {
        const userInfo = await magicInstance?.user.getInfo()
        if (userInfo) {
          setUser(userInfo)
          if (userInfo.publicAddress) {
            await authenticateMagicWallet(userInfo.publicAddress)
          }
        }

      } catch (error) {
        console.log('Error fetching userInfo: ', error)
      }
    }, [])
  
    // Check if user is logged in
    const checkLoggedInStatus = async () => {
      const loggedIn = await magicInstance.user.isLoggedIn()
      console.log('loggedin', loggedIn)
      setUserLoggedin(loggedIn)
      if (loggedIn) {
        // setUserLoggedin(loggedIn)
        // If logged in, fetch user info
        await fetchUserInfo()
      } else {
        setUser(null)
      }
    }
  
  
    return (
      <UserContext.Provider value={{ user, setUser, checkLoggedInStatus, isLoggedin }}>
        {children}
      </UserContext.Provider>
    )
  }
  
