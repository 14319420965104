import { useState, useEffect } from 'react'
import { Checkbox, Box, Button, Typography } from '@mui/material'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import { DropzoneArea, DropzoneDialog } from 'material-ui-dropzone'
import { AttachFile, Audiotrack, Description, PictureAsPdf, Theaters, Image } from '@material-ui/icons'

import Modal from '../shared/Modal'
import xIcon from '../../assets/images/x-icon.svg'
import imageUploadIcon from '../../assets/images/nftDetailView/upload-svg.svg'

import styles from './nftimageupdatemodal.module.scss'
import { useDropzone } from 'react-dropzone'

export default function NftImageUpdateModal({
  open,
  toggleModal,
  initialImage,
  onSaveImage,
  onDeleteImageFromServer
}: {
  open: boolean
  toggleModal: (open: boolean) => void
  initialImage: string | undefined
  onSaveImage: (image: File | undefined) => void,
  onDeleteImageFromServer: () => void,
}) {
  const [files, setFiles] = useState<(File & { preview: string })[]>([])

  const handleClose = () => {
    setFiles([])
    toggleModal(false)
  }

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    maxFiles: 1,
    onDrop: (acceptedFiles: File[]) => {
      setFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      )
    },
  })

  const isImageUploaded = () => files && files.length > 0

  const onDeleteImage = () => {
    setFiles([])
    if (initialImage) {
      onDeleteImageFromServer()
    }
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box className={styles.mintLandContainer}>
          <Button onClick={handleClose} className={styles.closeButton}>
            <img src={xIcon} alt='' />
          </Button>

          <Typography className={styles.modalTitle}>
            You can change/delete your land image here
          </Typography>

          <Box className={styles.dropzone}>
            {isImageUploaded() || initialImage ? (
              <Box>
                <img
                  src={files[0]?.preview || initialImage}
                  style={{ maxWidth: '15rem', maxHeight: '15rem' }}
                />
              </Box>
            ) : (
              <Box
                {...getRootProps({ className: 'dropzone' })}
                sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <input {...getInputProps()} />
                <img
                  src={imageUploadIcon}
                  alt='Upload Icon'
                  style={{ width: '15rem', height: '15rem' }}
                />
                <p>
                  <Typography>Drag or paste your image here</Typography>
                </p>
                <p>
                  <Typography component='span'>Browse file</Typography>
                </p>
              </Box>
            )}
          </Box>

          <Box className={styles.btnsSection}>
            <Button
              className={
                isImageUploaded() || initialImage ? styles.mintActiveBtn : styles.mintDisabledBtn
              }
              disabled={!isImageUploaded() && !initialImage}
              onClick={onDeleteImage}>
              Delete
            </Button>
            <Button
              className={styles.mintActiveBtn}
              // disabled={!isImageUploaded() && !!}
              onClick={() => onSaveImage(files[0])}>
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
