import { useEffect, useLayoutEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { ListItemText, MenuItem, MenuList } from '@material-ui/core'
import { Box, List, ListItem, Typography, useMediaQuery } from '@mui/material'
import { headerNavBarItems } from './types'
import Top from '../Top'
import navbarLogo from '../../assets/images/logo-small.png'
import hamburgerIcon from '../../assets/images/hamburgerIcon.svg'
import navbarMobileLogo from '../../assets/images/navbarMobileLogo.svg'
import { useUserContext } from '../../context/UserContext'

import styles from './navbar.module.scss'

export default function Navigation({ type }: { type?: string }) {
  const [activeItem, setActiveItem] = useState<string>('/')
  const [isScrolled, setIsScrolled] = useState<boolean>(false)
  const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState<boolean>(false)
  const { isLoggedin } = useUserContext()

  useLayoutEffect(() => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.id = 'pap_x2s6df8d'
    script.src = 'https://affiliate.exgoland.io/scripts/76gv7j'
    script.onload = () => {
      const scriptCode = `
        PostAffTracker.setAccountId('default1');
        try {
        PostAffTracker.track();
        } catch (err) { }
      `
      const scriptElement = document.createElement('script')
      scriptElement.type = 'text/javascript'
      scriptElement.innerHTML = scriptCode
      document.head.appendChild(scriptElement)
    }
    document.head.appendChild(script)
  }, [])

  useEffect(() => {
    setActiveItem(window.location.pathname)
  }, [window.location.pathname])

  useEffect(() => {
    function handleScroll() {
      setIsScrolled(window.scrollY > 0)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [window.scrollY])

  const isMobile = useMediaQuery('(max-width: 600px)')

  const selectSidebarItem = (item: { id: string; title: string; path: string }) => {
    setActiveItem(item.path)
    window.scrollTo(0, 0)
  }

  if (isMobile) {
    return (
      <>
        <Box className={styles.mobileNavbar}>
          <Link
            to='/'
            className={styles.mobileNavbarLogoContainer}
            onClick={() => {
              window.scrollTo({ top: 0, behavior: 'smooth' })
              setIsMobileNavbarOpen(false)
            }}>
            <img src={navbarMobileLogo} />
          </Link>
          <img
            src={hamburgerIcon}
            alt='hamburger'
            className={`${styles.hamburgerIcon} ${isMobileNavbarOpen ? styles.open : styles.close}`}
            onClick={() => {
              setIsMobileNavbarOpen(!isMobileNavbarOpen)
            }}
          />
        </Box>

        <Box
          className={`${styles.mobileNavbarOpen} ${
            isMobileNavbarOpen ? styles.open : styles.close
          }`}>
          <Top />
          <MenuList className={styles.mobileTitleList}>
            {headerNavBarItems.map((item, index) => {
              if (item.path === '/magic_wallet' && !isLoggedin) return
              return (
                <Link key={index} className={styles.sidebarItem} to={item.path}>
                  <MenuItem
                    style={{
                      width: '100%',
                      color: '#0B3456',
                      boxSizing: 'border-box',
                      fontFamily: '"DM Sans", sans-serif ',
                      fontSize: '16px',
                      fontWeight: '700',
                      marginBottom: '8px',
                    }}
                    onClick={() => {
                      selectSidebarItem(item)
                      setIsMobileNavbarOpen(false)
                    }}
                    button>
                    <ListItemText
                      primary={
                        <Typography
                          sx={{
                            fontWeight: '700 !important',
                            fontFamily: 'DM Sans !important',
                            borderBottom:
                              activeItem === item.path ? '2px solid #0B3456' : '2px solid #white',
                            width: 'max-content',
                          }}>
                          {item.title}
                        </Typography>
                      }
                    />
                  </MenuItem>
                </Link>
              )
            })}
          </MenuList>
        </Box>
      </>
    )
  }

  return (
    <Box
      className={`${styles.navbar} ${type === 'black' ? styles.blackNavbar : ''} ${
        isScrolled ? styles.blueNavbar : ''
      }`}>
      <Box className={styles.navbarContainer}>
        <List>
          <Link to='/' onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            <img src={navbarLogo} style={{ cursor: 'pointer' }} />
          </Link>
        </List>

        <List className={styles.navItems}>
          {headerNavBarItems.map((item, index) => {
            if (item.path === '/magic_wallet' && !isLoggedin) return
            return (
              <ListItem
                className={styles.listItem}
                key={index}
                sx={{
                  padding: '0px !important',
                  width: 'max-content !important',
                }}>
                <Link
                  key={index}
                  className={`${activeItem === item.path ? styles.activeNavItem : styles.navItem}`}
                  to={item.path}>
                  {item.title}
                </Link>
              </ListItem>
            )
          })}
        </List>
        <Top />
      </Box>
    </Box>
  )
}
