import { Divider, List, ListItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import featuresBG from '../../../../assets/images/featuresBG.png';
import styles from './features.module.scss'

const data = [
  {
    title: 'AI Powered Metaverse:',
    content:
      'AI is a the key to this metaverse. The ability of what it can do is limitless. But these some of the top ones:',
    list: [
      'Time control',
      'Climate control',
      'Environment control',
      'Map navigation',
      'User assistance',
      'And many more',
    ],
  },
  {
    title: 'Solana Blockchain:',
    content: (
      <span>
        <strong>EXGOLAND</strong> is developed on the Solana blockchain because it possesses
        numerous features that others do not. <strong>EXGOLAND</strong> users will have access to a
        variety of blockchain services, including:
      </span>
    ),
    list: [
      <span key={1}>Low gas fee</span>,
      <span key={2}>Very fast transaction speed</span>,
      <span key={3}>High security</span>,
      <span key={4}>Less impact on the environment</span>,
    ],
  },
  {
    title: 'Land size details:',
    content: (
      <span>
        All the lands have the same height of <strong>128m.</strong> In addition, each land size is{' '}
        <strong>(64x64m)</strong> this size gives the users plenty of space to build or create their
        big ideas.
      </span>
    ),
    list: [],
  },
  {
    title: 'High graphics:',
    content: (
      <span>
        <strong>EXGOLAND</strong> provides high-quality visuals to users so that they may truly
        experience the metaverse.
      </span>
    ),
    list: [],
  },
  {
    title: 'Duplicate avatar:',
    content: (
      <span>
        <strong>EXGOLAND</strong> offers a unique feature known as Duplicate Avatar. We offer the
        user the option of converting their avatar to about 99% of their facial characteristics and
        emotions. Users will be able to appear in the metaverse with the same skin and face shape.
      </span>
    ),
    list: [],
  },
  {
    title: 'Mint your land:',
    content: (
      <span>
        Users can mint their land of choice on the <strong>EXGOLAND</strong> platform. This will
        give the user number of benefits which are:
      </span>
    ),
    list: [
      <span key={1}>Be the first owner</span>,
      <span key={2}>Put your Royalty</span>,
      <span key={3}>Buy and Combine</span>,
    ],
  },
  {
    title: 'Verification:',
    content:
      'Celebrities and verified users want to keep their profiles or images private and want other users to recognize them at concerts or events. We also provide the user the opportunity to verify their account, which is a new and unique feature. This feature enables recognized artists and celebrities to protect their identity by showing the verification icon on top of 10 their avatars, ensuring that everyone knows it’s them.',
    list: [],
  },
  {
    title: 'Land combination:',
    content:
      'Users has the option to combine adjacent lands, to create a single big asset. Either to expand their space or sell it as one asset.',
    list: [],
  },
  {
    title: 'EXGO land:',
    content: (
      <span>
        <strong>EXGOLAND</strong> owns the largest land on the map and is the core of{' '}
        <strong>EXGOLAND</strong>. This land is located in the center of the map and hosts a variety
        of entertainment activities such as concerts, events, games, gatherings, and other
        entertaining events.
      </span>
    ),
    list: [],
  },
  {
    title: 'Fun Gathering:',
    content: (
      <span>
        <strong>EXGOLAND</strong> will have places for users to just hang out and have fun from
        concerts, shops and big brands, marketplaces, games and many more.
      </span>
    ),
    list: [],
  },
  {
    title: 'Meet verified users:',
    content:
      'Users will have the opportunity to meet verified celebrities, artists or influencers that may sign for them a piece of NFT to add more value to it. These users might also create events where they can giveaway signed NFTs to their fans.',
    list: [],
  },
  {
    title: 'Ready to install games:',
    content:
      'Users will be given the choice to buy ready- developed games from our marketplace to directly install in their land where they can enjoy with their friends and families or even the public can join if they want to.',
    list: [],
  },
  {
    title: 'Generate fair income:',
    content:
      'lands can be profitable in so many ways such as renting the land, doing Ads on it, build marketplaces or even build play-to-earn games.',
    list: [],
  },
  {
    title: 'Put your picture on the map:',
    content:
      'Landowners have the choice to put their picture on the land parcel on the map, which makes it recognizable to others.',
    list: [],
  },
  {
    title: 'Available on all devices:',
    content: (
      <span>
        On <strong>EXGOLAND</strong>, accessibility is not an issue. We provide users with endless
        access by making
        <strong>EXGOLAND</strong> available on all devices such as:
      </span>
    ),
    list: [
      <span key={1}>OS devices – available on iPhone, mac or even iPad</span>,
      <span key={2}>
        Android devices – available on mobile, tablets or any android supported device
      </span>,
      <span key={3}>
        Desktop – available on all windows devices like budget laptops, gaming laptops or PCs.
      </span>,
    ],
  },
]

export default function Features() {
  return (
    <Box className={styles.featuresContainer}>
      <Typography sx={{ fontFamily: 'DM Sans' }} className={styles.featuresTitle}>
        Features
      </Typography>
      <Typography className={styles.featuresDescription}>
        <strong>EXGOLAND</strong> has various features being implemented time to time where users
        will be able to take the advantage of. These are the Features that will be available on{' '}
        <strong>EXGOLAND</strong>
      </Typography>
      {data.map((el, index) => (
        <Box key={index}>
          <Typography className={styles.subTitle}>{el.title}</Typography>
          <Divider className={styles.divider} />
          <Typography className={styles.text}>{el.content}</Typography>
          {el.list.length > 0 && (
            <List
              sx={{
                mt: '10px',
              }}
              className={styles.list}>
              {el.list.map((li, index) => (
                <ListItem key={index} className={styles.featuresListItem}>
                  <span className={styles.listItemMarker} />
                  <Typography className={styles.listItemText}>{li}</Typography>
                </ListItem>
              ))}
            </List>
          )}
        </Box>
      ))}
      <Typography className={styles.bottomText}>
        This allows users to be constantly online, and you do not need to own a specific gadget to
        participate in the metaverse. Keep in touch and be inventive.
      </Typography>
      <img className={styles.featuresBG} src={featuresBG} alt="" />
    </Box>
  )
}
