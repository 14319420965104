import { Path } from '../components/Map/types'
import { INftData } from '../types/nft-map-types'
import { getLandCoordianates } from './constants/cameraZoomSettings'
import { LandDefaultDescription } from './constants/landDiscription'

export const getRects = async (svgData: string) => {
    const parser = new DOMParser()
    if (svgData) {
        const svg = parser.parseFromString(svgData, 'image/svg+xml')
        const rects = svg.querySelectorAll('rect')
        return rects
    } else {
        return []
    }
}

export const getMapPaths = (rects: any, nftData: INftData[]) => {
    const pathsArr = []
    for (let i = 0; i < rects.length; i++) {
        const rect = rects[i]
        const rectColor = rect.attributes.getNamedItem('fill')?.value
        const path = new Path2D()

        path.rect(
            rect.x.baseVal.value,
            rect.y.baseVal.value,
            rect.width.baseVal.value,
            rect.height.baseVal.value,
        )

        const newPath: Path = {
            path,
            coordinates: {
                x: rect.x.baseVal.value,
                y: rect.y.baseVal.value,
                width: rect.width.baseVal.value,
                height: rect.height.baseVal.value,
            },
            content: rectColor ?? '',
        }
        const { landX, landY } = getLandCoordianates(newPath)

        const nft = nftData.find((obj) => obj.coordinates.includes(`${landX}, ${landY}`))

        if (nft) {
            continue
        }
        pathsArr.push(newPath)
    }

    return pathsArr
}

export const getNewNotMintedLands = (paths: any, lastElementIndex: number) => {
    const newNotMintedLands: any[] = []
    const nextLastElementIndex = paths.length - lastElementIndex + 1 > 10 ? lastElementIndex + 10 : paths.length
    const isLastNotMintedElement = nextLastElementIndex === paths.length ? true : false

    for (let i = lastElementIndex; i < nextLastElementIndex; i++) {
        const x = Math.floor(paths[i].coordinates.x / (paths[i].coordinates.width + 20))
        const y = Math.floor(paths[i].coordinates.y / (paths[i].coordinates.height + 20))

        const newNotMintedLand = {
            address: '',
            coordinates: [`${x}, ${y}`],
            id: 0,
            isOnSale: true,
            metaDataUrl: '',
            name: 'Land\'s Name',
            description: LandDefaultDescription,
            imageUrl: '',
            isDefaultImg: true,
            userId: {
                email: '',
                id: 0,
                username: '',
                walletAddress: ''
            }
        }

        newNotMintedLands.push(newNotMintedLand)
    }

    const result = {
        lands: newNotMintedLands,
        lastElementIndex: nextLastElementIndex,
        isLastNotMintedElement: isLastNotMintedElement
    }

    return result
}
