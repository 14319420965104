import { Link } from 'react-router-dom'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import { termsOfServiceData } from './termsOfServiceData'
import Footer from '../Footer'
import Navigation from '../Navigation'
import ContentDrawer from '../drawer'
import termsOfServiceImg from '../../assets/images/termsOfServiceImg.svg'

import styles from './termsOfService.module.scss'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function TermsOfService() {
  const isMobile = useMediaQuery('(max-width: 700px)')

  return (
    <Box sx={{ paddingTop: '109px' }}>
      <Navigation type='black' />
      <Box className={styles.termsOfServiceContainer}>
        <Box className={styles.mainHeader}>
          <Typography className={styles.header}>Terms of Service</Typography>
        </Box>
        <Box className={styles.termsOfServiceContent}>
          <Box className={styles.termsOfServiceDescription}>
            <Typography className={styles.description}>
              These Terms of Service <b>(&ldquo;Terms&rdquo;)</b> apply to your access to and use of
              the websites, mobile applications and other online products and services
              (collectively, the <b>&ldquo;Services&rdquo;</b>) provided by E X G O LAND TECHNOLOGY
              L.L.C (<b>&ldquo;EXGOLAND&rdquo;</b> or{' '}
              <b>
                &ldquo;we&rdquo;). By clicking your consent (e.g. &ldquo;Continue,&rdquo;
                &ldquo;Sign-in,&rdquo; or &ldquo;Sign-up,&rdquo;) or by using our Services, you
                agree to these Terms, including the mandatory arbitration provision and class action
                waiver in the Resolving Disputes; Binding Arbitration Section
              </b>
              <br />
              <br />
              Our <Link to='/policy'>Privacy Policy</Link> explains how we collect and use your
              information while our Rules outline your responsibilities when using our Services. By
              using our Services, you&rsquo;re agreeing to be bound by these Terms and our Rules.
              Please see our <Link to='/policy'>Privacy Policy</Link> for information about how we
              collect, use, share and otherwise process information about you.
              <br />
              <br />
              If you have any questions about these Terms or our Services, please contact us at{' '}
              <Link to={'mailto:support@exgoland.io'} target='_blank'>
                support@exgoland.io.
              </Link>
            </Typography>
            <Box className={styles.imgContainer}>
              <Box component='img' src={termsOfServiceImg} />
            </Box>
          </Box>
          <Box className={styles.contentDrawerContainer}>
            <ContentDrawer key={String(isMobile)} data={termsOfServiceData} isTerms={true} isMobile={isMobile} />
          </Box>
        </Box>
      </Box>
      <Footer />
    </Box>
  )
}
