import { Box, Typography, useMediaQuery } from '@material-ui/core'
import tokenomicsImg from '../../../../assets/images/tokenomics.png'

import styles from './tokenomics.module.scss'
import { Link } from 'react-router-dom'

export default function TokenomicsPage() {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={styles.pageContainer}>
      <Box className={styles.leftSection}>
        <Typography className={styles.pageTitle}>Tokenomics</Typography>

        <Typography className={styles.pageDescription}>
          This is the total distribution of the <strong>$EXGO</strong> token. Here is the token contract:&nbsp;
          <a href="https://solscan.io/token/D5ymUBHsvoVYKAGUCGknk2Cm8UYGKTntxJ62T3C46NXS" target="_blank" rel="noopener noreferrer">
            EXGO
          </a>
        </Typography>
        
        {isMobile ? <img src={tokenomicsImg} height={ '100%' } /> : <img src={tokenomicsImg} width={ '100%' } />}
      </Box>
    </Box>
  )
}
