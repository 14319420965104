import { Box } from '@mui/system'
import BuyYourLand from '../BuyYourLand'
import Discover from '../DiscoverSection'
import Footer from '../Footer'
import Header from '../Header'
import Roadmap from '../Roadmap'
import Partners from '../Roadmap/Partners'

import styles from './home.module.scss'
import './scroll.css'

export default function Home() {
  return (
    <Box className={styles.homeContainer}>
      <Header />
      <Discover />
      <BuyYourLand />
      <Roadmap />
      <Partners />
      <Footer />
    </Box>
  )
}
