import { Box } from '@mui/material'

import styles from '../Modal/modal.module.scss'
import type { ReactElement } from 'react'

export default function Modal({
  children,
  open,
  onClose,
}: {
  children: ReactElement
  open: boolean
  onClose?: () => void
}) {
  return open ? (
    <Box className={styles.root} onClick={onClose}>
      <Box
        onClick={(e) => {
          e.stopPropagation()
        }}
        className={styles.contentWrapper}
      >
        {children}
      </Box>
    </Box>
  ) : null
}
