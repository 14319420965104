import { useEffect, useRef, useState } from 'react'
import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { Typography, Button, useMediaQuery } from '@mui/material'
import { FiberManualRecord } from '@mui/icons-material'
import { ObserverUtil } from '../../utils/observerUtil'
import { pageRows } from '../WhitepaperPage/pages/Roadmap/types'

import styles from './roadmap.module.scss'

export default function Roadmap() {
  const [isVisible, setIsVisible] = useState(false)
  const [hasBeenVisible, setHasBeenVisible] = useState(false)
  const [showMore, setShowMore] = useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width: 600px)')

  const roadmapRef = useRef(null)

  useEffect(() => {
    ObserverUtil(roadmapRef, setIsVisible, hasBeenVisible, setHasBeenVisible, 0.08)
  }, [hasBeenVisible])

  const handleMore = () => {
    setShowMore(true)
  }

  const handleClose = () => {
    setShowMore(false)
    if (roadmapRef.current !== null) {
      ;(roadmapRef.current as HTMLDivElement).scrollIntoView({ behavior: 'auto' })
    }
  }

  return (
    <div
      ref={roadmapRef}
      className={`${styles.pageContainer} ${isVisible ? styles.animate : styles.hidden}`}>
      <Typography
        variant='h4'
        textAlign='center'
        className={`${styles.headerTitle} ${isVisible ? styles.animate : styles.hidden}`}>
        Roadmap
      </Typography>
      <Box className={isMobile ? styles.roadmapMobileContainer : styles.container}>
        {(isMobile && !showMore ? pageRows.slice(0, 1) : pageRows)?.map((item, index) => (
          <Box key={index} className={styles.wrapper}>
            <Typography className={styles.roadMapTitle}>Year {item.year}</Typography>
            <Box className={styles.roadmapContainer}>
              {!isMobile && (
                <img
                  className={`${styles.pageImg} ${isVisible ? styles.animate : styles.hidden}`}
                  src={item.darkImg}
                />
              )}
              {isMobile && <Box className={styles.leftLine} />}
              <Box className={`${styles.pageRow} ${isVisible ? styles.animate : styles.hidden}`}>
                {item.columns.map((column, index) => (
                  <List key={index} className={styles.list}>
                    {isMobile && <Box className={styles.quarterCircle} />}
                    <Box>
                      {column.map((text, index) => (
                        <ListItem key={index} className={styles.listItem}>
                          {text.bullet && (
                            <ListItemIcon style={{ minWidth: '0px' }}>
                              <FiberManualRecord
                                sx={{ fontSize: 5, color: '#FFFFFF', minWidth: '0px' }}
                              />
                            </ListItemIcon>
                          )}
                          {
                            text.bold ? (
                              <Typography className={styles.listItemBoldText}  >{text.text}</Typography>
                            ) : (
                              <Typography className={styles.listItemText} >{text.text}</Typography>
                            )
                          }
                        </ListItem>
                      ))}
                    </Box>
                  </List>
                ))}
              </Box>
            </Box>
          </Box>
        ))}
      </Box>
      {isMobile && (
        <Button className={styles.moreButton} onClick={showMore ? handleClose : handleMore}>
          {showMore ? 'Show less' : 'See more'}
        </Button>
      )}
    </div>
  )
}
