import { useContext, useEffect, useState } from 'react'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import { useWallet } from '@solana/wallet-adapter-react'
import { nanoid } from 'nanoid'

import { useUserContext } from '../../context/UserContext'
import Navigation from '../../components/Navigation'
import Footer from '../../components/Footer'
import defaultLandImg from '../../assets/images/Exgoland_logo.png'
import mapPin from '../../assets/images/mapPin.svg'
import solanaIcon from '../../assets/images/solanaLogo.svg'
import usdcLogo from '../../assets/images/usdcLogo.svg'
import editLogo from '../../assets/images/nftDetailView/edit-button.svg'

import {
  mapPupUpUnmintedLandDescription,
  mapPupUpUnmintedLandDescriptionHeader,
} from '../../utils/constants/landDiscription'
import MarketplaceModal from '../../components/MarketplaceModal'
import ConfirmModal from '../../components/ConfirmModal'
import { getNeighborLands } from '../../utils/findNeighborCoordinates'
import SuccessMintModal from '../Marketplace/MintSuccessModal'
import CombineLandsModal from '../../components/CombineLandsModal'
import NftDescriptionUpdateModal from '../../components/NftDescriptionUpdateModal'
import NftImageUpdateModal from '../../components/NftImageUpdateModal'
import { PopUpProps } from '../../components/Map/types'
import SuccessCombinedModal from '../BuyLandMap/CombinedSuccessModal'
import { formatText } from '../../helpers/textFormatter'
import CopyButton from '../../components/copy-btn'
import { WalletPopup } from '../../components/walletPopup/walletPopup'
import { WalletConnectContext } from '../../providers/web3'
import { getSolPrice } from '../../services/helper.service'
import api from '../../services/axios.service'
import { INftData, LandStatus } from '../../types/nft-map-types'
import { ButtonText } from '../Marketplace/types'
import Loader from '../../components/Loader'
import styles from './nftById.module.scss'
import { getS3ObjectUrl, s3Client, getS3ImageKeyFromUri } from '../../utils/awsS3/client'
import { PutObjectCommand, DeleteObjectCommand } from '@aws-sdk/client-s3'

interface IMarketplaceData {
  coordinates: string[]
}

export default function NftById() {
  const [open, setOpen] = useState<boolean>(false)
  const [successfullMint, setSuccessfullMint] = useState<boolean>(false)
  const [successfullCombine, setSuccessfullCombine] = useState<boolean>(false)
  const [openSuccessCombine, setOpenSuccessCombine] = useState<boolean>(false)
  const [neighborLands, setNeighborLands] = useState<INftData[]>([])
  const [openCombineModal, setOpenCombineModal] = useState<boolean>(false)
  const [openNftDescriptionUpdateModal, setNftDescriptonUpdateModal] = useState<boolean>(false)
  const [openNftImageUpdateModal, setNftImageUpdateModal] = useState<boolean>(false)
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false)
  const [buttonType, setButtonType] = useState<string>('')
  const [nftData, setNftData] = useState<INftData>()
  const [totalNftNames, setTotalNftNames] = useState<[]>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [usdPrice, setUSDPrice] = useState(0)
  const [nftOwned, setNftOwned] = useState<boolean>(false)
  const [price, setPrice] = useState<number>()
  const [marketplaceData, setMarketplaceData] = useState<IMarketplaceData>({
    coordinates: ['0, 0'],
  })
  const [landShowImage, setLandShowImage] = useState<string>(defaultLandImg)

  const [mintedlandCoordinates, setMintedlandCoordinates] = useState<number[][]>([])
  const { openModal } = useContext(WalletConnectContext)
  const { checkLoggedInStatus, isLoggedin, user } = useUserContext()

  const wallet = useWallet()
  const isMobile = useMediaQuery('(max-width: 600px)')

  useEffect(() => {
    if (wallet?.publicKey && nftData?.userId?.walletAddress) {
      if (wallet.publicKey.toString() === nftData?.userId?.walletAddress) {
        setNftOwned(true)
      }
    }
  }, [wallet?.publicKey, nftData?.userId?.walletAddress])

  useEffect(() => {
    const getLandImagePreview = async () => {
      console.log('nftData nftData', nftData)

      if (nftData?.imageUrl?.length) {
        setLandShowImage(nftData.imageUrl)
      } else if (nftData?.metaDataUrl?.length) {
        setIsLoading(true)
        await fetch(nftData?.metaDataUrl)
          .then(response => response.json())
          .then(async ({ image }) => {
            // jsonData is parsed json object received from url
            console.log('image', image)
            const res = await fetch(image)

            const imgBlob = await res.blob()
            const imgObjectURL = URL.createObjectURL(imgBlob);
            console.log(imgObjectURL)
            if (imgObjectURL) {
              console.log('set imageurl')
              setLandShowImage(imgObjectURL)
            }
          })
          .catch((error) => {
            // handle your errors here
            console.error(error)
          }).finally(() => {
            setIsLoading(false)
          })
      } else {
        setLandShowImage(defaultLandImg)
      }
    }

    if (nftData) {
      console.log('getLandImagePreview call')
      getLandImagePreview()
    }
  }, [nftData])

  useEffect(() => {
    const fetchNftData = async () => {
      try {
        const { data } = await api.get('/nfts/all')
        if (!data) throw new Error('Data not found')
        if (data && data.length > 0) {
          setTotalNftNames(data.map((datum: { name: string }) => datum.name))
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchNftData()
  }, [])

  useEffect(() => {
    const getNftByAddress = async () => {
      const searchParams = new URLSearchParams(window.location.search)
      const squareValue = searchParams.get('square')

      if (!squareValue) return

      setIsLoading(true)
      try {
        const { data }: { data: INftData } = await api.get(`/nfts/${squareValue}`)

        setNftData(data)
        setMarketplaceData({
          coordinates: data.coordinates,
        })
      } catch (error) {
        setMarketplaceData({
          coordinates: [squareValue],
        })
      } finally {
        setIsLoading(false)
      }
    }
    getNftByAddress()
    queryFetcher()
  }, [])

  useEffect(() => {
    if (!sessionStorage.getItem('accessToken') && isMobile) {
      openModal()
    }
  }, [])

  const queryFetcher = () => {
    const searchParams = new URLSearchParams(window.location.search)
    const autoOpen = searchParams.get('autoOpen')
    if (autoOpen) setOpen(Boolean(parseInt(autoOpen)))

    const buttonValue = searchParams.get('buttonValue')
    if (buttonValue) setButtonType(buttonValue)

    const price = searchParams.get('price')
    if (price) setPrice(parseFloat(price))
  }
  
  const detectNeighborLandsCoordinates = async (landCoordinates: number[][] | undefined) => {
    if (!landCoordinates) return

    const neighborLands = await getNeighborLands(landCoordinates, wallet, user?.publicAddress)

    if (!neighborLands) return

    setNeighborLands(neighborLands)
  }

  useEffect(() => {
    const landCoordinates = nftData?.coordinates.map((el) => {
      return el.split(', ').map(Number)
    })
    if (nftData) detectNeighborLandsCoordinates(landCoordinates)
  }, [nftData, wallet])

  useEffect(() => {
    if (price) {
      getSolPrice().then((solPrice) => {
        setUSDPrice(price * solPrice)
      })
    }
  }, [price])

  const onCombinedSuccess = (payload: INftData) => {
    console.log('payload', payload)
    setNftData({ ...payload })
    setOpenSuccessCombine(true)
    setSuccessfullCombine(true)
  }

  const onCombined = (manualCombine = false) => {
    if (neighborLands.length) {
      return setOpenCombineModal(true)
    }
    if (manualCombine) alert('There are no available lands to combine')
  }

  const setSuccessMint = (state: boolean, nftData: INftData | undefined) => {
    setSuccessfullMint(state)
    setButtonType(ButtonText.SELL)

    if (!nftData) return
    setNftData(nftData)
    const landCoordinates = nftData.coordinates.map(Number)
    setMintedlandCoordinates([landCoordinates])
  }

  const handleMint = () => {
    if (buttonType === ButtonText.MINT) {
      const accessToken = sessionStorage.getItem('accessToken')
      if (accessToken) {
        setOpen(true)
      } else {
        openModal()
      }
    } else if (buttonType === ButtonText.SELL || buttonType === ButtonText.BUY) {
      window.open(`https://magiceden.io/marketplace/exgoland_collection?search=${nftData?.name}`, '_blank')
    }
  }

  const onChangeImage = () => {
    if (!openNftImageUpdateModal) {
      console.log('edit image')

      setNftImageUpdateModal(true)
    }
  }

  const showDescriptionModal = () => {
    console.log(openNftDescriptionUpdateModal)
    if (!openNftDescriptionUpdateModal) {
      console.log('edit description')

      setNftDescriptonUpdateModal(true)
    }
  }

  const onSaveDescription = async (val: string | undefined) => {
    console.log('onSaveDescription', val)
    if(!val) throw new Error('Description cannot be empty')
    try {
      setIsLoading(true)
      const { data: { success, newNft } } = await api.post('/nfts/updateLandDescription', {
        nftId: nftData?.id,
        description: val,
      })

      if (success) {
        setNftData(newNft)
      }
      setNftDescriptonUpdateModal(false)
    } catch (error) {
      throw new Error(error?.toString())
    } finally {
      setIsLoading(false)
    }
  }

  const onSaveImage = async (val: File | undefined) => {
    console.log('onSaveImage', val, nftData?.imageUrl)

    try {

      let imageUrl = '', s3ImageKey = ''
      if (val) {
        if (nftData?.imageUrl && nftData.imageUrl.includes('s3.amazonaws.com')) {
          s3ImageKey = getS3ImageKeyFromUri(nftData.imageUrl)
        } else {
          // just upload it
          s3ImageKey = nanoid()
        }

        console.log(nftData?.imageUrl)
        // save to aws s3
        console.log('before upload to s3')
        console.log('randomkey', s3ImageKey)

        setIsLoading(true)

        const s3Command = new PutObjectCommand({
          Bucket: process.env.REACT_APP_BUCKET,
          Key: s3ImageKey,
          Body: val,
        });
  
        const response = await s3Client.send(s3Command);
        console.log(response);
        imageUrl = getS3ObjectUrl(s3ImageKey)
        console.log('after upload to s3', imageUrl)
      }

      const { data: { success, newNft } } = await api.post('/nfts/updateLandImage', {
        nftId: nftData?.id,
        imageUrl,
      })

      if (success) {
        setNftData(newNft)
      }
      setNftImageUpdateModal(false)
    } catch (error) {
      throw new Error(error?.toString())
    } finally {
      setIsLoading(false)
    }
  }

  const deleteImageFromServer = async () => {
    if (nftData) {
      try {
        setIsLoading(true)
        
        if (nftData.imageUrl && nftData.imageUrl.includes('s3.amazonaws.com')) {
          const s3ImageKey = getS3ImageKeyFromUri(nftData.imageUrl)

          if (s3ImageKey) {
            const s3Command = new DeleteObjectCommand({
              Bucket: process.env.REACT_APP_BUCKET,
              Key: s3ImageKey,
            });

            const response = await s3Client.send(s3Command)
            console.log('image removed from s3')
            console.log(response);
          }
        }

        const { data: { success, newNft } } = await api.post('/nfts/updateLandImage', {
          nftId: nftData?.id,
          imageUrl: '',
        })

        if (success) {
          setNftData(newNft)
          setNftImageUpdateModal(false)
        }
      } catch (error) {
        throw new Error(error?.toString())
      } finally {
        setIsLoading(false)
        setOpenConfirmModal(false)
      }
    }
  }

  const onDeleteImageFromServer = () => {
    setOpenConfirmModal(true)
  }

  return (
    <>
      <WalletPopup />
      <Box className={styles.container}>
        <Navigation type='black' />
        {isLoading && (
          <Box className={styles.loaderBackground}>
            <Loader isFullScreen={false} background='inherit' loaderColor='#0a3455' />
            <Typography>
              Processing
            </Typography>
          </Box>
        )}
        (
          <>
            <NftDescriptionUpdateModal
              open={openNftDescriptionUpdateModal}
              toggleModal={setNftDescriptonUpdateModal}
              initialDescription={nftData?.description}
              onSaveDescription={onSaveDescription}
            />
            <NftImageUpdateModal
              open={openNftImageUpdateModal}
              toggleModal={setNftImageUpdateModal}
              initialImage={nftData?.imageUrl}
              onSaveImage={onSaveImage}
              onDeleteImageFromServer={onDeleteImageFromServer}
            />
            <MarketplaceModal
              landInfo={{ ...marketplaceData, price: { solana: price, usd: usdPrice } }}
              open={open}
              toggleModal={setOpen}
              setMintSuccess={setSuccessMint}
              totalNftNames={totalNftNames}
            />
            <ConfirmModal
              open={openConfirmModal}
              toggleModal={setOpenConfirmModal}
              onCancel={() => setOpenConfirmModal(false)}
              onOK={deleteImageFromServer}
            />
            <Box className={styles.nftPageContainer}>
              <Box className={styles.modalHeader}>
                <Box className={styles.firstSection}>
                  <Box className={styles.imageSection}>
                    {
                      nftOwned && (
                        <img
                          className={styles.editLogo}
                          src={editLogo}
                          onClick={onChangeImage}
                          alt='edit image'
                        />
                      )
                    }
                    
                    <img
                      className={styles.landImg}
                      src={landShowImage}
                      // src={'blob:http://localhost:3000/5d606a9f-ec22-41f2-aac1-44682396f007'}
                    />
                  </Box>
                  
                  <Box className={styles.headerInfoBox}>
                    <Typography className={styles.landName}>{nftData?.name}</Typography>
                    {(buttonType === ButtonText.MINT) && (
                      <Box className={styles.iconBox}>
                        <img src={usdcLogo} className={styles.solanaIcon} alt='solana' />
                        <Typography className={styles.text}>
                          {`${process.env.REACT_APP_INITIAL_SALE_USDC_PRICE} (${process.env.REACT_APP_INITIAL_SALE_USDC_PRICE}$)`}
                        </Typography>
                      </Box>
                    )}
                    {(buttonType === ButtonText.BUY) && price && (
                      <Box className={styles.iconBox}>
                        <img src={solanaIcon} className={styles.solanaIcon} alt='solana' />
                        <Typography className={styles.text}>
                          {`${price.toFixed(2)} ($ ${usdPrice.toFixed(2)})`}
                        </Typography>
                      </Box>
                    )}
                    {!isMobile && (
                      <Box className={styles.ownerBox}>
                        <Typography className={styles.ownerHeader}>Owner</Typography>
                        <Typography className={styles.ownerName}>
                          <span className={styles.walletAddress}>
                            {formatText(nftData?.userId?.walletAddress) ?? '@landowner'}
                          </span>
                          {nftData?.userId?.walletAddress && (
                            <CopyButton text={nftData?.userId?.walletAddress ?? ''} />
                          )}
                        </Typography>
                      </Box>
                    )}
                    <Box className={styles.descriptionBox}>
                      <Box className={styles.descriptionHeaderBox}>
                        <Typography className={styles.descriptionTitle}>Description</Typography>
                        {
                          nftOwned && (
                            <img
                              className={styles.editLogo}
                              src={editLogo}
                              onClick={showDescriptionModal}
                              alt='edit description'
                            />
                          )
                        }
                        
                      </Box>
                      {nftData?.description ? (
                        <Typography className={styles.unmintedDescriptionListItem}>
                          {nftData?.description}
                        </Typography>
                      ) : (
                        <>
                          <Typography>{mapPupUpUnmintedLandDescriptionHeader}</Typography>
                          {mapPupUpUnmintedLandDescription.map((el) => (
                            <Typography key={el} className={styles.unmintedDescriptionListItem}>
                              <Box className={styles.circle} />
                              {el}
                            </Typography>
                          ))}
                        </>
                      )}
                    </Box>
                    <Box className={styles.iconBox}>
                      <img src={mapPin} className={styles.mapPin} alt='mapPin' />
                      <div className={styles.locations}>
                        {nftData?.coordinates ? (
                          nftData?.coordinates.map((coordinate, index) => (
                            <Typography key={index} className={styles.locationText}>
                              {coordinate}
                            </Typography>
                          ))
                        ) : (
                          <Typography className={styles.locationText}>
                            {marketplaceData.coordinates}
                          </Typography>
                        )}
                      </div>
                    </Box>
                    <Box className={styles.btnBlock}>
                      {(buttonType !== ButtonText.NONE && buttonType !== ButtonText.SEND) && (
                        <Button className={styles.buyButton} onClick={handleMint}>
                          {buttonType}
                        </Button>
                      )}
                      {(neighborLands.length > 0 && !successfullCombine) && (
                        <Button
                          className={styles.buyButton}
                          onClick={() => onCombined(true)}
                          disabled={successfullCombine}
                        >
                          Combine
                        </Button>
                      )}
                    </Box>
                  </Box>
                </Box>
                <SuccessMintModal
                  isOpen={successfullMint}
                  onClose={() => {
                    setSuccessMint(false, undefined)
                    detectNeighborLandsCoordinates(mintedlandCoordinates)
                    onCombined()
                  }}
                />
                <CombineLandsModal
                  isOpen={openCombineModal}
                  onClose={() => {
                    setOpenCombineModal(false)
                  }}
                  nfts={neighborLands}
                  onCombinedSuccess={onCombinedSuccess}
                  totalNftNames={totalNftNames}
                />
                <SuccessCombinedModal
                  isOpen={openSuccessCombine}
                  onClose={() => {
                    setOpenSuccessCombine(false)
                  }}
                />
                <Box className={styles.landStatusBox}>
                  <Typography className={styles.status}>
                    {price ? LandStatus.ON_SALE : LandStatus.NOT_ON_SALE}
                  </Typography>
                  {isMobile && (
                    <Box className={styles.ownerBox}>
                      <Typography className={styles.ownerHeader}>Owner</Typography>
                      <Typography className={styles.ownerName}>
                        {formatText(nftData?.userId?.walletAddress) ?? '@landowner'}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Footer />
          </>
        )
      </Box>
    </>
  )
}
