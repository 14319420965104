import { Box, Button, TextField, Typography } from '@mui/material'
import emailjs from 'emailjs-com'
import { useEffect, useState } from 'react'
import Modal from '..'
import CloseIcon from '../../../../assets/images/x-icon.svg'
import ConfirmationModal from '../ConfirmationModal'
import type { PartnerShipModalProps } from './types'

import styles from './partnermodal.module.scss'

function PartnerShipModal({ toggleModal, isOpen }: PartnerShipModalProps) {
  const [email, setEmail] = useState<string>('')
  const [emailValidate, setEmailValidate] = useState<boolean>(false)
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('')
  const [messageErrorMessage, setMessageErrorMessage] = useState<string>('')
  const [message, setMessage] = useState<string>('')
  const [messageValidate, setMessageValidate] = useState<boolean>(false)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [isSubmited, setIsSubmited] = useState<boolean>(false)

  const validateEmail = (email: string) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return regex.test(email)
  }

  const handleClose = () => {
    setMessage('')
    setEmail('')
    setMessageErrorMessage('')
    setEmailErrorMessage('')
    toggleModal?.()
  }

  const closeSuccessModal = () => {
    setIsSubmited(false)
    handleClose()
  }

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(e.target.value)
    if (e.target.value.length === 0) {
      setEmailErrorMessage('*This field is required')
      setEmailValidate(false)
    } else if (!validateEmail(e.target.value)) {
      setEmailValidate(false)
      setEmailErrorMessage('*Incorrect email')
    } else {
      setEmailErrorMessage('')
      setEmailValidate(true)
    }
  }

  const handleChangeMessage = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setMessage(e.target.value)
    if (e.target.value.length === 0) {
      setMessageErrorMessage('*This field is required')
      setMessageValidate(false)
    } else {
      setMessageValidate(true)
      setMessageErrorMessage('')
    }
  }

  const sendEmail = async () => {
    setIsSending(true)
    try {
      await emailjs.send(
        String(process.env.REACT_APP_EMAIL_SERVICE),
        String(process.env.REACT_APP_CONTACT_US_TEMPLATE),
        {
          to_email: 'business@exgoland.io',
          phone_number: '',
          email,
          from_name: 'business@exgoland.io',
          message,
        },
        process.env.REACT_APP_EMAIL_API_KEY,
      )
      setMessageValidate(false)
      setEmailValidate(false)
    } catch (error) {
      console.error(error)
    }
    setIsSending(false)
  }

  const handleSubmit = () => {
    sendEmail()
    setEmail('')
    setMessage('')
    setIsSubmited(true)
  }

  useEffect(() => {
    function handleKeyPress(event: any) {
      if (emailValidate && messageValidate && event.key === 'Enter') {
        event.preventDefault()
        handleSubmit()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [emailValidate, messageValidate])

  return (
    <Modal open={isOpen} onClose={handleClose}>
      {!isSubmited ? (
        <Box className={styles.modalBackground}>
          <Box className={styles.content}>
            <Box className={styles.contentBackground} />
            <Button onClick={handleClose} className={styles.closeButton}>
              <img src={CloseIcon} alt='' />
            </Button>
            <Typography className={styles.title} id='parent-modal-title'>
              Why did you choose us?
            </Typography>
            <Typography id='parent-modal-title' className={styles.description}>
              Our website is under construction but we are ready to go for Partnership
            </Typography>
            <Box className={styles.formWrapper}>
              <TextField
                value={email}
                onChange={handleChangeEmail}
                className={styles.email}
                placeholder='Your Mail'
                sx={{
                  fieldset: {
                    border: 'none !important',
                  },
                  '& input::placeholder': {
                    '@media (max-width: 600px)': {
                      fontSize: '12px !important',
                    },
                  },
                }}
              />
              <Box className={styles.errorMessageBox}>
                {!emailValidate && (
                  <Typography className={styles.errorMessage}>{emailErrorMessage}</Typography>
                )}
              </Box>
              <TextField
                value={message}
                onChange={handleChangeMessage}
                placeholder='Add a comment'
                className={styles.commentInput}
                multiline
                maxRows={5}
                sx={{
                  fieldset: {
                    border: 'none !important',
                  },
                  '& textarea::placeholder': {
                    '@media (max-width: 600px)': {
                      fontSize: '12px !important',
                    },
                  },
                }}
              />
              <Box className={styles.errorMessageBox}>
                {!messageValidate && (
                  <Typography className={styles.errorMessage}>{messageErrorMessage}</Typography>
                )}
              </Box>
              <Button
                className={
                  emailValidate && messageValidate ? styles.submitBtn : styles.disableButton
                }
                onClick={() => {
                  handleSubmit()
                }}
                disabled={!emailValidate || !messageValidate}>
                {isSending ? 'Sending...' : 'Submit'}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <ConfirmationModal
          isOpen={isSubmited}
          handleOpen={closeSuccessModal}
          title='Thank you for messaging us!'
          subTitle='Your message has been received. We will get back to you within two business days.'
        />
      )}
    </Modal>
  )
}

export default PartnerShipModal
