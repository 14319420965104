import { useEffect, useState } from 'react'
import { Link, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import {
  Box,
  MenuItem,
  MenuList,
  Drawer,
  IconButton,
  ListItemText,
  makeStyles,
} from '@material-ui/core'
import { Typography, useMediaQuery } from '@mui/material'
import clsx from 'clsx'
import nextIcon from '../../assets/images/arrowNext.svg'
import prevIcon from '../../assets/images/arrowPrev.svg'
import backIcon from '../../assets/images/backIcon.svg'
import sidebarLogo from '../../assets/images/sidebarLogo.svg'
import hamburgerIcon from '../../assets/images/hamburgerIcon.svg'
import sidebarCloseIcon from '../../assets/images/sidebarCloseIcon.svg'
import { sidebarMenuItems } from './types'
import { getIsDeviceRotated } from '../../utils/getIsDeviceRotated'

import styles from './whitepaper.module.scss'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: '330px',
    flexShrink: 0,
    [theme.breakpoints.down(1161)]: {
      width: 240,
    },
  },
  drawerPaper: {
    width: '330px',
    background: 'linear-gradient(180deg, #0D2A44 0%, #0C4374 100%)',
    boxSizing: 'border-box',
    padding: '20px 27px 30px 23px !important',
    [theme.breakpoints.down(1161)]: {
      padding: '10px !important',
      width: '210px',
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    height: '0px !important',
    ...theme.mixins.toolbar,
    marginBottom: '47px',
  },
  content: {
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),

    [theme.breakpoints.down(1161)]: {
      marginLeft: '-240px',
    },
    marginLeft: '-330px',
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  sidebarItem: {
    [theme.breakpoints.down(1161)]: {
      padding: '5px !important',
      fontSize: '12px !important',
    },
  },
  sidebarItemText: {
    fontFamily: '"DM Sans", sans-serif ',
    [theme.breakpoints.down(1161)]: {
      fontSize: '12px !important',
    },
  },
  sidebarLogo: {
    [theme.breakpoints.down(1161)]: {
      width: '70%',
    },
  },
}))

function Whitepaper() {
  const isMobile = useMediaQuery('(max-width: 600px)')
  const classes = useStyles()
  const [open, setOpen] = useState(true)
  const [activeItem, setActiveItem] = useState<string>('')
  const [isMobileNavbarOpen, setIsMobileNavbarOpen] = useState<boolean>(false)
  const [nextPageName, setNextPageName] = useState<string>('EXGOLAND AI')
  const [prevPageName, setPrevPageName] = useState<string>('')
  const [activePage, setActivePage] = useState<string>('Introduction')
  const [mobileNavbarKey, setMobileNavbarKey] = useState<string>('')
  const isPhoneRotated = getIsDeviceRotated();

  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const currentPage = sidebarMenuItems?.find(
      (element) => `/whitepaper${element.path}` === location.pathname,
    )
    if (currentPage !== null && currentPage !== undefined) {
      setNextPageName(sidebarMenuItems[currentPage?.id + 1]?.name)
      setPrevPageName(sidebarMenuItems[currentPage?.id - 1]?.name)
      setActivePage(sidebarMenuItems[currentPage?.id]?.name)
    }
    window.scrollTo(0, 0)
    setActiveItem(location.pathname.slice(11))
  }, [location.pathname])

  const handleDrawerOpen = () => {
    setOpen(true)
  }

  const handleDrawerClose = () => {
    setOpen(false)
  }

  const goToHomePage = () => {
    navigate('/')
  }

  useEffect(() => {
    setOpen(true)
  }, [isPhoneRotated, isMobile])
  useEffect(() => {
    setMobileNavbarKey(String(Date.now()))
  }, [isPhoneRotated])

  const navigateToPrevPage = () => {
    const currentPage = sidebarMenuItems?.find(
      (element) => `/whitepaper${element.path}` === location.pathname,
    )
    if (currentPage !== null && currentPage !== undefined) {
      navigate(`/whitepaper${sidebarMenuItems[currentPage?.id - 1]?.path}`)
      setActiveItem(sidebarMenuItems[currentPage?.id - 1]?.path)
      window.scrollTo(0, 0)
    }
  }

  const navigateToNextPage = () => {
    const currentPage = sidebarMenuItems?.find(
      (element) => `/whitepaper${element.path}` === location.pathname,
    )
    if (currentPage !== null && currentPage !== undefined) {
      navigate(`/whitepaper${sidebarMenuItems[currentPage?.id + 1]?.path}`)
      setActiveItem(sidebarMenuItems[currentPage?.id + 1]?.path)
      window.scrollTo(0, 0)
    }
  }

  return (
    <div className={classes.root}>
      {!isMobile && (
        <Drawer
          className={classes.drawer}
          variant='persistent'
          anchor='left'
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <Box className={classes.drawerHeader}>
            <img
              src={sidebarLogo}
              className={classes.sidebarLogo}
              onClick={goToHomePage}
              style={{ cursor: 'pointer' }}
            />
            <IconButton onClick={handleDrawerClose}>
              <img src={sidebarCloseIcon} />
            </IconButton>
          </Box>
          <MenuList>
            {sidebarMenuItems.map((item, index) => (
              <Link key={index} className={styles.sidebarItem} to={`/whitepaper${item.path}`}>
                <MenuItem
                  style={{
                    color: activeItem === item.path ? '#0B3456' : '#FFFFFF',
                    fontFamily: '"DM Sans", sans-serif ',
                    fontSize: '16px',
                    padding: '10px 0 10px 28px',
                    borderRadius: '10px',
                    background: activeItem === item.path ? '#D6E6F4' : 'inherit',
                    marginBottom: '8px',
                  }}
                  className={classes.sidebarItem}
                  button
                >
                  <ListItemText
                    primary={
                      <Typography
                        sx={{
                          fontWeight: activeItem === item.path ? 700 : 500,
                        }}
                        className={classes.sidebarItemText}
                      >
                        {item.name}
                      </Typography>
                    }
                  />
                </MenuItem>
              </Link>
            ))}
          </MenuList>
        </Drawer>
      )}

      <main
        className={clsx(classes.content, {
          [classes.contentShift]: isMobile ? close : open,
        })}
      >
        {(isMobile || (isMobile && isPhoneRotated)) && (
          <>
            <Box key={mobileNavbarKey} className={styles.mobileNavbar}>
              <Typography className={styles.activePage}>{activePage}</Typography>
              <img
                src={hamburgerIcon}
                alt='hamburger'
                className={`${styles.hamburgerIcon} ${isMobileNavbarOpen ? styles.open : styles.close
                  }`}
                onClick={() => {
                  setIsMobileNavbarOpen(!isMobileNavbarOpen)
                }}
              />
            </Box>
            <Box
              className={`${styles.mobileNavbarOpen} ${isMobileNavbarOpen ? styles.open : styles.close
                }`}
            >
              <MenuList className={styles.mobileTitleList}>
                {sidebarMenuItems.map((item, index) => (
                  <Link key={index} className={styles.sidebarItem} to={`/whitepaper${item.path}`}>
                    <MenuItem
                      style={{
                        width: '100%',
                        color: activeItem === item.path ? '#0B3456' : '#FFFFFF',
                        boxSizing: 'border-box',
                        fontFamily: '"DM Sans", sans-serif ',
                        fontSize: '16px',
                        padding: '10px 10px 10px 28px',
                        borderRadius: '10px',
                        background: activeItem === item.path ? '#D6E6F4' : 'inherit',
                        marginBottom: '8px',
                      }}
                      onClick={() => {
                        setIsMobileNavbarOpen(false)
                      }}
                      button
                    >
                      <ListItemText
                        primary={
                          <Typography
                            sx={{
                              fontWeight:
                                activeItem === item.path || activePage === item.name ? 700 : 500,
                            }}
                            className={classes.sidebarItemText}
                          >
                            {item.name}
                          </Typography>
                        }
                      />
                    </MenuItem>
                  </Link>
                ))}
              </MenuList>
            </Box>
          </>
        )}
        <div className={classes.drawerHeader} />
        {!open && (
          <button className={styles.sidebarOpenBtn} onClick={handleDrawerOpen}>
            <img src={sidebarCloseIcon} />
          </button>
        )}

        <Box className={`${styles.pageContainer} ${!open ? styles.closed : ''}`}>
          <Box className={styles.backToHomePage} onClick={goToHomePage}>
            <img src={backIcon} />
            <Typography
              sx={{
                fontSize: '20px',
              }}
            >
              Back Home
            </Typography>
          </Box>

          <Routes>
            {sidebarMenuItems.map((item, index) => (
              <Route key={index} path={item.path} element={item.component} />
            ))}
          </Routes>
          <Box className={styles.navigateBtnsSection}>
            {location.pathname !== '/whitepaper' && (
              <Box
                onClick={() => {
                  navigateToPrevPage()
                }}
                className={styles.prevPageButton}
              >
                <img src={prevIcon} />
                <Box>
                  <Typography>Previous</Typography>
                  <Typography>{prevPageName}</Typography>
                </Box>
              </Box>
            )}

            {location.pathname !== '/whitepaper/token-benefits' && (
              <Box
                onClick={() => {
                  navigateToNextPage()
                }}
                className={styles.nextPageButton}
              >
                <Box>
                  <Typography>Next</Typography>
                  <Typography>{nextPageName}</Typography>
                </Box>
                <img src={nextIcon} />
              </Box>
            )}
          </Box>
        </Box>
      </main>
    </div>
  )
}

export default Whitepaper
