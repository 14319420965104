import YouTube from 'react-youtube'
import x from '../../assets/images/x.svg'

import styles from './videoModal.module.scss'

interface VideoModalProps {
  isOpen: boolean
  setIsOpen: (e: boolean) => void
}

const videoId = 'mXTZkXO2Ucc'
const opts = {
  height: '651px',
  width: '1132px',
  playerVars: {
    autoplay: 1,
  },
}

export default function VideoModal({ isOpen, setIsOpen }: VideoModalProps) {
  if (isOpen) {
    return (
      <div className={styles.modalContainer}>
        <div className={styles.modal}>
          <div className={styles.videoWrapper}>
            <div className={styles.youTubeContainer}>
              <YouTube
                videoId={videoId}
                opts={opts}
                className={styles.youTubeContainer}
                iframeClassName={styles.iframeClass}
              />
            </div>
            <div className={styles.closeModalBtnContainer}>
              <img src={x} className={styles.closeModalBtn} onClick={() => setIsOpen(false)} />
            </div>
          </div>
        </div>
        <div onClick={() => setIsOpen(false)} className={styles.wrapper} />
      </div>
    )
  }
  return null
}
