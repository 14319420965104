import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  Tooltip
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Cluster, Connection, LAMPORTS_PER_SOL, clusterApiUrl, PublicKey } from '@solana/web3.js'

import Footer from '../../components/Footer'
import Navigation from '../../components/Navigation'
import styles from './magicwallet.module.scss'
import { magicInstance } from '../../services/magic.service'
import { useUserContext } from '../../context/UserContext'
import api from '../../services/axios.service'
import { INftsData } from '../../services/nft.service'
import { getHiddenCoordinates } from '../../utils/utils'
import SendNftModal from '../../components/SendNftModal'

import '../Marketplace/marketplace.module.scss'
import { shortName } from '../../utils/validators'
import defaultLandImg from '../../assets/images/Exgoland_logo.png'
import { formatText } from '../../helpers/textFormatter'
import CopyButton from '../../components/copy-btn'
import mapPin from '../../assets/images/mapPin.svg'
import { ButtonText } from '../Marketplace/types'

function MagicWallet() {
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>('')
  const [data, setData] = useState<any>()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const { checkLoggedInStatus, isLoggedin, user } = useUserContext()
  const [loading, setLoading] = useState<boolean>(false)
  const [nftData, setNftData] = useState<INftsData[]>([])
  const [nativeBalance, setNativeBalance] = useState(0)

  const login = async () => {
    setLoading(true)
    try {
      await magicInstance.auth.loginWithEmailOTP({ email });
      await checkLoggedInStatus()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    const fetchNftData = async () => {
      try {
        const { data } = await api.get('/nfts/all/' + user.publicAddress)
        console.log('data', data)
        if (!data) throw new Error('Data not found')
        setNftData(data)
      } catch (error) {
        console.error(error)
      }
    }

    const getSolanaAccountBalance = async () => {
      const solanaNetwork = process.env.REACT_APP_RPC_URL || clusterApiUrl((process.env.REACT_APP_SOLANA_NETWORK || 'devnet') as Cluster)
      const connection = new Connection(solanaNetwork);
      const balance = await connection.getBalance(new PublicKey(user.publicAddress))
      setNativeBalance(balance/LAMPORTS_PER_SOL)
      console.log('balance', balance/LAMPORTS_PER_SOL)
    }

    if (user?.publicAddress) {
      fetchNftData()
      getSolanaAccountBalance()
    }
  }, [user?.publicAddress])

  const handleNavigate = (e: any, data: any) => {
    window.scrollTo(0, 0)
    navigate(`/nft?square=${data.address ? data.address : data.coordinates.join(', ')}&autoOpen=0&buttonValue=${ButtonText.SELL}`, {
      state: { autoOpen: false },
    })
  }

  const handleSendNft = (e: any, data: any) => {
    setData(data)
    setModalOpen(true)
  }
  
  const updateNfts = async (nftAddress: string, targetAddress: string) => {
    if (nftAddress && targetAddress) {
      setNftData(nftData.filter((d: INftsData) => d.address !== nftAddress))
      // update the db
      try {
        console.log('calling the updateNftOwner api', targetAddress)
        await api.post('/nfts/updateNftOwner', {
          nftId: data.id,
          owner: targetAddress,
        })
        console.log('DB udpated too')
      } catch (error) {
        console.log(error)
      }
    }
  }

  return (
    <>
      <Container className={styles.wrapper}>
        <Navigation type={'black'} />
        
        <Box className={styles.container}>
          {
            isLoggedin ? (
              <Box>
                {/* { 'Public Address: ' + user?.publicAddress } */}
                <Box className={styles.ownerBox}>
                  <Box sx={{ paddingRight: '20px' }}>
                    <Typography className={styles.ownerHeader}>Wallet Address</Typography>
                    <Typography className={styles.ownerName}>
                      <span className={styles.walletAddress}>
                        {formatText(user?.publicAddress) ?? '@landowner'}
                      </span>
                      {user?.publicAddress && (
                        <CopyButton text={user?.publicAddress ?? ''} />
                      )}
                    </Typography>
                  </Box>
                  <Box>
                    <Typography className={styles.ownerHeader}>Balance</Typography>
                    <Typography>{nativeBalance} SOL</Typography>
                  </Box>
                </Box>
                <Box className={styles.results}>
                  {nftData.length > 0 && nftData.map((data: INftsData, index: number) => (
                    <Box className={styles.card}>
                      <div onClick={(e) => handleNavigate(e, data)} className={styles.showMoreWindow}>
                        <span className={styles.showMoreText}>Show More</span>
                      </div>
                      <img src={data.image ?? defaultLandImg} className={styles.img} />
                      <Typography className={styles.name}>{shortName(data.name)}</Typography>
                      <Box className={styles.cardCoordinates}>
                        <img src={mapPin} />
                        <Typography className={styles.coordinates}>{data.coordinates[0]} </Typography>
                        {data.coordinates.length > 1 && (
                          <Tooltip title={`${getHiddenCoordinates(data.coordinates)}`} enterTouchDelay={0}>
                            <Typography className={styles.showMore}>{'  ...'}</Typography>
                          </Tooltip>
                        )}
                      </Box>
                      <Button className={styles.btn} onClick={(e) => handleSendNft(e, data)}>
                        {ButtonText.SEND}
                      </Button>
                    </Box>
                  ))}
                </Box>
              </Box>
            ) : (
              <Box>
                <Typography>Please sign up or login</Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      type="email"
                      name="email"
                      id="outlined-basic"
                      required
                      placeholder="Enter your email"
                      onChange={(event) => {
                          setEmail(event.target.value);
                      }}
                      sx={{ border: '1px solid gray', marginRight: '10px' }}
                    />
                    <Button variant="outlined" disabled={loading} onClick={login}>Send</Button>
                  </Box>
              </Box>
            )
          }
        </Box>

        <SendNftModal
          open={modalOpen}
          toggleModal={setModalOpen}
          data={data}
          updateNfts={updateNfts}
        />
        <Footer />
      </Container>
    </>
  )
}

export default MagicWallet
