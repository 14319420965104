import feat1 from '../../assets/images/feat1.svg'
import feat2 from '../../assets/images/feat2.svg'
import feat3 from '../../assets/images/feat3.svg'
import feat4 from '../../assets/images/feat4.svg'
import feat5 from '../../assets/images/feat5.svg'

export const featuresItems = [
  {
    title: 'Duplicate Avatar',
    icon: feat1,
    description:
      'Convert your profile picture to a duplicate avatar that looks exactly like you. Using our generators and converting tools that detects facial expressions and details.',
  },
  {
    title: 'Verified user',
    icon: feat2,
    description:
      'Get verified on EXGOLand to protect your identity and tell the world it is really you. Twitter verified users will get verified directly.',
  },
  {
    title: 'Available on All Devices',
    icon: feat3,
    description:
      'Stay always connected and access EXGOLand from anywhere at any time. Available on all IOS, Android and Windows devices.',
  },
  {
    title: 'Land Combination',
    icon: feat4,
    description:
      'Combine adjacent lands, regardless of the shape, to create a single big asset. To expand your space or sell it as one asset.',
  },
  {
    title: 'Powered by AI',
    icon: feat5,
    description:
      'AI in EXGOLAND provides personalized experiences, adaptive environments, virtual assistants, emotional virtual characters, real-time optimization, reduced latency, and an engaging user experience.',
  },
]
