import { Box, Button, Typography } from '@mui/material'
import { affiliateLoginUrl, affiliateSignupUrl, learnMoreUrl } from '../../utils/constants/URL'
import affiliateMainImg from '../../assets/images/affiliateMainImg.svg'
import tutorialPromoteLinkIcon from '../../assets/images/tutorialPromoteLinkIcon.svg'
import tutorialSignupIcon from '../../assets/images/tutorialSignupIcon.svg'
import tutorialEarnIcon from '../../assets/images/tutorialEarnIcon.svg'
import Footer from '../../components/Footer'
import Navigation from '../../components/Navigation'

import styles from './affiliate.module.scss'

function Affiliate() {
  const openAffiliateSignupPage = () => {
    window.open(affiliateSignupUrl)
  }

  const openAffiliateLoginPage = () => {
    window.open(affiliateLoginUrl)
  }

  const openLearnMorePage = () => {
    window.open(learnMoreUrl)
  }

  return (
    <>
      <Navigation type={'black'} />
      <Box className={styles.mainContainer}>
        <Box className={styles.bodyContainer}>
          <Box className={styles.firstSection}>
            <Box className={styles.affiliateProgramContainer}>
              <Typography className={styles.affiliateProgramHeader}>
                Our Affiliate Program
              </Typography>
              <Typography className={styles.affiliateProgramText}>
                Join the EXGOLAND Affiliate program, a program based on Cost-Per-Sale (CPS), where
                you receive payment for successfully referring someone who purchases land using your
                exclusive referral link.
                <br />
                <br />
                Enjoy generous commission rates and seize this opportunity by joining us today!
              </Typography>
              <Box className={styles.buttonSection}>
                <Button className={styles.button} onClick={openAffiliateSignupPage}>
                  Join Now
                </Button>
                <Button className={styles.button} onClick={openAffiliateLoginPage}>
                  Sign In
                </Button>
                <Button className={styles.button} onClick={openLearnMorePage}>
                  Learn More
                </Button>
              </Box>
            </Box>
          </Box>
          <Box className={styles.secondSection}>
            <img
              src={affiliateMainImg}
              className={styles.secondSectionImg}
              alt='affiliateMainImg'
            />
          </Box>
        </Box>
        <Box className={styles.tutorialSection}>
          <Box className={styles.tutorialBox}>
            <img className={styles.signupIcon} src={tutorialSignupIcon} alt='tutorialSignup' />
            <Typography className={styles.tutorialText}>
              <b>#1</b> SignUp
            </Typography>
          </Box>
          <Box className={styles.tutorialBox}>
            <img
              className={styles.promoteLinkIcon}
              src={tutorialPromoteLinkIcon}
              alt='tutorialPromoteLink'
            />
            <Typography className={styles.tutorialText}>
              <b>#2</b> Promote Your Link
            </Typography>
          </Box>
          <Box className={styles.tutorialBox}>
            <img className={styles.earnIcon} src={tutorialEarnIcon} alt='tutorialEarn' />
            <Typography className={styles.tutorialText}>
              <b>#3</b> Start Earning
            </Typography>
          </Box>
        </Box>
      </Box>
      <Footer />
    </>
  )
}

export default Affiliate
