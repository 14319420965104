import bs58 from 'bs58'
import { WalletContextState, useWallet } from '@solana/wallet-adapter-react'
import api from './axios.service'

export const authenticate = async (wallet: WalletContextState) => {
  try {
    const walletAddress = wallet.publicKey?.toBase58()

    const { data } = await api.post('/users/nonce', { walletAddress })
    if (!data) throw new Error('Data not found')

    const nonce = data.nonce

    const message = `By signing this message I am agreeing with terms and conditions of Exgoland\n\n Nonce: ${nonce}`

    if (wallet.signMessage) {
      const signature = await wallet.signMessage(new TextEncoder().encode(message))

      const response = await api.post('/users/authenticate', {
        walletAddress,
        signature: bs58.encode(signature),
      })

      if (response.data.accessToken) {
        sessionStorage.setItem('accessToken', response.data.accessToken)
        return true
      }
      return false
    }
  } catch (error) {
    console.error(error)
    return false
  }
}

export const authenticateMagicWallet = async (walletAddress: string) => {
  try {
    const { data } = await api.post('/users/nonce', { walletAddress })
    if (!data) throw new Error('Data not found')

    if (walletAddress) {
      const response = await api.post('/users/authenticateMagicUser', {
        walletAddress,
      })

      if (response.data.accessToken) {
        sessionStorage.setItem('accessToken', response.data.accessToken)
        return true
      }
      return false
    }
  } catch (error) {
    console.error(error)
    return false
  }
}
