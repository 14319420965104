import React, { FC } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { AccordionSummary, Typography, AccordionDetails, Accordion as DefaultAccordion } from '@mui/material';
import { AccordionTypes } from './accordion-types';
import styles from '../../pages/Marketplace/marketplace.module.scss'

const Accordion: FC<AccordionTypes> = ({
  label,
  dataToShow,
  className,
  detailsClassName
}): JSX.Element => {
  return (
    <DefaultAccordion className={className ?? styles.selector}>
      <AccordionSummary
        expandIcon={<ArrowDropDownIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'
        className={styles.summary}>
        <Typography>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails className={detailsClassName ?? styles.details}>
        {dataToShow}
      </AccordionDetails>
    </DefaultAccordion>
  )
}

export default Accordion;
