function createData(
  features: string,
  decentraland: boolean | string,
  sandbox: boolean | string,
  blocktopia: boolean | string,
  exgoland: boolean | string,
) {
  return { features, decentraland, sandbox, blocktopia, exgoland }
}

export const rows = [
  createData('Powered by AI', false, false, false, true),
  createData('Human Duplicate Avatar Creation', false, false, false, true),
  createData('Onboarding', 'Complex', 'Very Complex', 'Complex', 'Very Simple'),
  createData('VR Support', false, false, false, true),
  createData('iOS Support', false, false, false, true),
  createData('Android Support', false, false, false, true),
  createData('Complexity', 'High', 'Very High', 'High', 'Very Low'),
  createData('Graphic Quality (on 1-5 Scale)', '3', '2', '3', '4'),
  createData('Wallet connection', true, true, true, true),
  createData('2D Map', true, true, false, true),
  createData('Minting', true, true, true, true),
  createData('Develop own games', true, true, true, true),
  createData('Event creation', true, true, true, true),
  // createData('Air Drop', false, false, true, true),
  // createData('Market cap', '$8.5B', '$6.87B', '$6.63B', '$2.5M'),
]

export const mobileRows = [
  ['Powered by AI', false, false, false, true],
  ['Human Duplicate Avatar Creation', false, false, false, true],
  ['Onboarding', 'Complex', 'Very Complex', 'Complex', 'Very Simple'],
  ['VR Support', false, false, false, true],
  ['iOS Support', false, false, false, true],
  ['Android Support', false, false, false, true],
  ['Complexity', 'High', 'Very High', 'High', 'Very Low'],
  ['Graphic Quality (on 1-5 Scale)', '3', '2', '3', '4'],
  ['Wallet connection', true, true, true, true],
  ['2D Map', true, true, false, true],
  ['Minting', true, true, true, true],
  ['Develop own games', true, true, true, true],
  ['Event creation', true, true, true, true],
  // ['Air Drop', false, false, true, true],
  // ['Market cap', '$8.5B', '$6.87B', '$6.63B', '$2.5M'],
]
