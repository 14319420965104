export const exgolandURL = process.env.REACT_APP_EXGOLAND_URL ?? 'https://exgoland.io/'

export const glowUrl = 'https://glow.app'

export const glowUrlMobile = 'https://glow.app/dl'

export const phantomUrl = 'https://phantom.app'

export const phantomUrlMobile = `https://phantom.app/ul/browse/${encodeURIComponent(exgolandURL)}`

export const okxUrl = 'https://www.okx.com/web3'

export const okxUrlMobile ='https://www.okx.com/web3'

export const walletConnectUrl = 'https://walletconnect.com/'

export const affiliateSignupUrl = 'https://affiliate.exgoland.io/affiliates/signup.php#SignupForm'

export const affiliateLoginUrl = 'https://affiliate.exgoland.io/affiliates/login.php#login'

export const learnMoreUrl = 'https://exgoland.medium.com/exgoland-affiliate-program-a90e59374c27'
