import { Button, Typography } from '@material-ui/core'
import { Box } from '@mui/material'
import Modal from '..'
import SuccessIcon from '../../../../assets/images/successIcon.svg'
import CloseIcon from '../../../../assets/images/x-icon.svg'

import styles from './confirmationModal.module.scss'

export default function ConfirmationModal({
  isOpen,
  handleOpen,
  title,
  subTitle,
  isContactUs,
}: {
  isOpen: boolean
  handleOpen: any
  title: string
  subTitle?: string
  isContactUs?: boolean
}) {
  return (
    <Modal open={isOpen} onClose={handleOpen}>
      <Box className={`${styles.modalBackground} ${isContactUs ?? false ? styles.contactUs : ''}`}>
        <Box className={styles.content}>
          <Button onClick={handleOpen} className={styles.closeButton}>
            <img src={CloseIcon} style={{}} alt='' />
          </Button>
          <img src={SuccessIcon} className={styles.confirmIcon} alt='' />
          <Box className={styles.textContainer}>
            <Typography className={styles.title}>{title}</Typography>
            {subTitle != null && subTitle.length > 0 && (
              <Typography className={styles.subTitle}>{subTitle}</Typography>
            )}
          </Box>

          <Box className={styles.contentBackground} />
        </Box>
      </Box>
    </Modal>
  )
}
