import axios from 'axios'
import { IQueryParams } from '../pages/Marketplace'
import api from './axios.service'

export interface INftsData {
  address: string
  coordinates: string[]
  description: string
  id: number
  image: string
  isOnSale: boolean
  metaDataUrl: string
  name: string
  userId: string
}

export const filteredNftData = async (params: IQueryParams) => {
  try {
    const { data } = await api.get('/nfts/', {
      params,
    })
    if (!data) throw new Error('Data not found')
    if (!data.length) return data;

    const nftsData: INftsData[] = [];
    for (const key in data) {
      const nftData = await axios.get(data[key].metaDataUrl)
      if (!nftData.data) throw new Error('Nft data not found')

      nftsData.push({ ...data[key], ...nftData.data })
    }

    return nftsData;
  } catch (error) {
    console.error(error)
  }
}
