import { useState } from 'react'
import { Link } from 'react-router-dom'
import { useMediaQuery } from '@mui/material'
import { ButtonType } from '../UI/Button/types'
import copyrightIcon from '../../assets/images/copyrightIcon.svg'
import CustomButton from '../UI/Button'
import NotifyMeModal from '../shared/Modal/NotifyMeModal'
import PartnerShipModal from '../shared/Modal/PartnerShipModal'
import footerLogo from '../../assets/images/footerLogo.svg'
import { footerNavItems, footerNavItemsMobile, footerSocialIcons } from './types'

import styles from './footer.module.scss'

export default function Footer() {
  const [open, setOpen] = useState<boolean>(false)
  const [openNotifyMeModal, setOpenNotifyMeModal] = useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <div className={styles.footerContainer}>
      <div className={styles.footerSection}>
        <div className={styles.footerTopSection}>
          <img className={styles.footerLogo} alt='footerLogo' src={footerLogo} />
          <div className={styles.footerNav}>
            <div className={styles.navItems}>
              {(isMobile ? footerNavItemsMobile : footerNavItems).map((item, index) => {
                return (
                  <Link
                    to={item.path}
                    key={index}
                    className={styles.link}
                    onClick={() => {
                      window.scrollTo(0, 0)
                    }}>
                    <div className={styles.linkText}>{item.name}</div>
                  </Link>
                )
              })}
            </div>
          </div>
          {/* <div className={styles.followUs}>
            <p className={styles.title}>Follow Us</p>
            <div className={styles.socialIcons}>
              {footerSocialIcons.map((icon) => (
                <a key={icon.name} href={icon.url} target='_blank' rel='noopener noreferrer'>
                  <div className={styles.socialIcon}>
                    <img src={icon.src} alt='social icon' />
                  </div>
                </a>
              ))}
            </div>
            <div className={styles.buttonsSection}>
              <CustomButton
                text='Notify Me'
                btnType={ButtonType.DARK}
                onClick={() => {
                  setOpenNotifyMeModal(true)
                }}
              />
              <CustomButton
                text='Partnership'
                btnType={ButtonType.LIGHT}
                onClick={() => {
                  setOpen(true)
                }}
              />
              <PartnerShipModal
                isOpen={open}
                toggleModal={() => {
                  setOpen(false)
                }}
              />
              <NotifyMeModal
                isOpen={openNotifyMeModal}
                toggleModal={() => {
                  setOpenNotifyMeModal(false)
                }}
              />
            </div>
          </div> */}
        </div>
        <div className={styles.footerTopSectionSocials}>
          <div className={styles.followUs}>
            <p className={styles.title}>Follow Us</p>
            <div className={styles.socialIcons}>
              {footerSocialIcons.map((icon) => (
                <a key={icon.name} href={icon.url} target='_blank' rel='noopener noreferrer'>
                  <div className={styles.socialIcon}>
                    <img src={icon.src} alt='social icon' />
                  </div>
                </a>
              ))}
            </div>
            <div className={styles.buttonsSection}>
              <CustomButton
                text='Notify Me'
                btnType={ButtonType.DARK}
                onClick={() => {
                  setOpenNotifyMeModal(true)
                }}
              />
              <CustomButton
                text='Partnership'
                btnType={ButtonType.LIGHT}
                onClick={() => {
                  setOpen(true)
                }}
              />
              <PartnerShipModal
                isOpen={open}
                toggleModal={() => {
                  setOpen(false)
                }}
              />
              <NotifyMeModal
                isOpen={openNotifyMeModal}
                toggleModal={() => {
                  setOpenNotifyMeModal(false)
                }}
              />
            </div>
          </div>
        </div>
        <div className={styles.footerBottomSection}>
          <img src={copyrightIcon} />
          <span> Copyright 2024 - All rights Reserved by EXGOLAND</span>
        </div>
      </div>
    </div>
  )
}
