import { Typography } from '@material-ui/core'
import { useEffect, useState } from 'react'
import { Box, Button, TextField, Tooltip } from '@mui/material'
import { useWallet } from '@solana/wallet-adapter-react'
import { useUserContext } from '../../context/UserContext'

import xIcon from '../../assets/images/x-icon.svg'
import combinedLogo from '../../assets/images/combineLands.svg'
import mapPin from '../../assets/images/mapPin.svg'
import { AttributesType, combineNfts, combineNftsWithExgolandWallet } from '../../services/metaplex.service'
import api from '../../services/axios.service'
import Loader from '../Loader'
import Modal from '../shared/Modal'
import RoyaltyInput from '../UI/RoyaltyInput'
import { LandDefaultDescription } from '../../utils/constants/landDiscription'
import { getHiddenCoordinates } from '../../utils/utils'
import { INftData } from '../../types/nft-map-types'
import { landGasFee } from '../../utils/constants/metaplex'

import styles from './combineLands.module.scss'

interface IProps {
  isOpen: boolean
  onClose: () => void
  nfts: INftData[]
  onCombinedSuccess: (payload : INftData) => void,
  totalNftNames?: string[] | undefined
}

export default function CombineLandsModal({ isOpen, onClose, nfts, onCombinedSuccess, totalNftNames }: IProps) {
  const [mintLoading, setMintLoading] = useState<boolean>(false)
  const [combinedLandName, setCombinedLandName] = useState<string>('')
  const [royalty, setRoyalty] = useState<string>('')
  const [solPrice, setSOLPrice] = useState(0)
  const [combined, setCombined] = useState<boolean>(false)
  const { user } = useUserContext()

  const wallet = useWallet()

  const deletBurnedNfts = async () => {
    const nftAddresses = nfts.map((el) => el.address)
    await api.delete('/nfts/delete', {
      data: nftAddresses,
    })
  }

  const combineLands = async () => {
    const attributes: AttributesType[] = []
    if (!nfts) {
      return
    }
    try {
      nfts
        .flatMap((next) => next.coordinates)
        .map((coordinates) => coordinates.split(', ').join(' X '))
        .forEach((coordinate) => {
          attributes.push({
            trait_type: 'Coordinates',
            value: coordinate,
          })
        })
      
      let response = undefined
      if (wallet?.publicKey) {
        response = await combineNfts(nfts, wallet, combinedLandName, royalty, attributes)
      } else if (user?.publicAddress) {
        console.log('user?.publicAddress', user?.publicAddress)
        response = await combineNftsWithExgolandWallet(nfts, wallet, combinedLandName, royalty, user.publicAddress, attributes)
      }
      
      if (response) {
        console.log('response exist')
        const combinedLandsCoordinates = nfts.reduce((agr: string[], el) => {
          return agr.concat(el.coordinates)
        }, [])
  
        const { nft, uploadedImgUrl, isDefaultImg } = response
  
        if (nft) {
          setMintLoading(true)
          console.log('deletBurnedNfts')
          await deletBurnedNfts()
          console.log('/nfts/create')
          const payload = {
            metaDataUrl: nft.uri,
            address: nft.address,
            coordinates: combinedLandsCoordinates,
            name: nft.name,
            description: LandDefaultDescription,
            imageUrl: uploadedImgUrl ?? nft.image,
            isDefaultImg,
          }
          await api.post('/nfts/create', payload)
          setCombined(true)
          onCombinedSuccess(payload)
        }
      }
    } catch (error) {
      console.log(error)
    } finally {
      setMintLoading(false)
      onClose()
    }
  }

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box className={styles.container}>
        {mintLoading && (
          <Box className={styles.loaderBackground}>
            <Loader isFullScreen={false} background='inherit' loaderColor='#0a3455' />
            <Typography className={styles.processText}>
              <div>
                Processing
              </div>
              <div>
                ( DO NOT close this window until done )
              </div>
            </Typography>
          </Box>
        )}
        <Box className={styles.close}>
          <Button onClick={onClose} className={styles.closeButton}>
            <img src={xIcon} alt='' />
          </Button>
        </Box>
        <Box className={styles.logos}>
          <img src={combinedLogo} alt='Uploaded Image' className={styles.uploadedImg} />
          <Typography className={styles.about}>Do you want to combine these lands?</Typography>
          <Typography>{`Combination Gas Fee is ${landGasFee} SOL`}</Typography>
        </Box>
        <Box className={styles.inputContainer}>
          <Box className={styles.combinedLandNameInputContainer}>
            <TextField
              className={styles.combinedLandNameInput}
              value={combinedLandName}
              onChange={(event) => {
                setCombinedLandName(event.target.value)
              }}
            />
            {combinedLandName.length == 0 && (
              <Typography className={styles.placeholderDefault}>
                Combined land name <Typography className={styles.red}>*</Typography>
              </Typography>
            )}
          </Box>

          {/* {
            (totalNftNames?.includes(combinedLandName)) && (
              <Box>
                <Typography className={styles.placeholderDefault}>
                  <Typography className={styles.red}>Name Already Exist</Typography>
                </Typography>
              </Box>
            )
          } */}

          <Box className={styles.combinedLandNameInputContainer}>
            <RoyaltyInput royalty={royalty} setRoyalty={setRoyalty} />
          </Box>
        </Box>
        
        <Box className={styles.landsInfo}>
          {nfts.map((el) => (
            <Box className={styles.landItem} key={el.id}>
              <Typography className={styles.landName}>{el.name}</Typography>
              <Box className={styles.landLocation}>
                <Typography className={styles.location}>Location</Typography>
                <Box className={styles.coordinatesBox}>
                  <img src={mapPin} alt='' />
                  {el.coordinates && el.coordinates.length !== 0 && (
                    <Typography className={styles.coordinatesText}>
                      {el.coordinates[0]}{' '}
                      {el.coordinates.length > 1 && (
                        <Tooltip
                          title={`${getHiddenCoordinates(el.coordinates)}`}
                          enterTouchDelay={0}>
                          <Typography>{'  ...'}</Typography>
                        </Tooltip>
                      )}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          ))}
        </Box>
        
        <Box className={styles.buttons}>
          <Button onClick={onClose} className={styles.closeButton}>
            Cancel
          </Button>
          <Button
            className={styles.combineButton}
            disabled={combinedLandName.length === 0 || royalty.length === 0 || combined}
            onClick={() => {
              setMintLoading(true)
              combineLands()
            }}>
            Combine
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}
