import { Box, Typography, useMediaQuery } from '@mui/material'
import buyLandImg from '../../../../assets/images/buyLandImg.svg'
import creditCardIcon from '../../../../assets/images/credit.png'
import walletIcon from '../../../../assets/images/wallets.png'

import styles from './buyland.module.scss'

export default function BuyLand() {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={styles.pageContainer}>
      <Box className={styles.contentSection}>
        <Typography className={styles.title}>Buy Land</Typography>
        <Typography className={styles.description}>
          Users will have 2 ways to buy lands in EXGOLAND. By either minting it before anyone and when done it will directly be available on our collection on MagicEden. On the other hand, users will be able to buy listed lands on MagicEden marketplace.
          Users will have 2 different ways to buy lands
        </Typography>
        <Typography className={styles.description}>
          Users will have 2 different ways to buy lands
        </Typography>
        <Box className={styles.paymentMethod}>
          <div className={styles.buyLandIcon}>
            <img className={`${styles.methodIcon} ${styles.methodIconAbsolute}`} src={walletIcon} />
          </div>
          <Typography className={`${styles.description} ${styles.desc}`}>
            <span>Using Crypto Wallets: EXGOLAND</span> provides users 2 different wallet choices
            and more are yet to come. These two are Phantom and Glow Wallets.
          </Typography>
        </Box>
        <Box className={styles.paymentMethod}>
          <div className={styles.buyLandIcon}>
            <img
              className={`${styles.methodIcon} ${styles.methodIconAbsolute}`}
              src={creditCardIcon}
            />
          </div>
          <Typography className={`${styles.description} ${styles.desc}`}>
            <div className={styles.creditSide}>
              <span>Using Credit Card: EXGOLAND </span>also provides users the option to buy lands
              and be in the metaverse using their credit cards with 3 easy steps:
              <ul className={styles.creditList}>
                <li>Enter Email</li>
                <li>Enter OTP</li>
                <li>Enter Credit/Debit details or Apple/Google pay.</li>
              </ul>
            </div>
          </Typography>
        </Box>
        <Typography className={styles.description}>
          It is as simple as that. Using our third party API, you will have a wallet created for you and you will have your land in your wallet. After that, you have full control on the land you can sell it, send it or even rent it. 
        </Typography>
      </Box>
      {!isMobile && <img className={styles.buyLandImg} src={buyLandImg} />}
    </Box>
  )
}
