import { Box, Button, Tooltip, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useWallet } from '@solana/wallet-adapter-react'
import mapPin from '../../assets/images/mapPin.svg'
import solanaLogo from '../../assets/images/solanaLogo.svg'
import usdcLogo from '../../assets/images/usdcLogo.svg'
import defaultLandImg from '../../assets/images/Exgoland_logo.png'
import { shortName } from '../../utils/validators'
import { ButtonText } from './types'
import { useNavigate } from 'react-router'
import { getHiddenCoordinates } from '../../utils/utils'
import { MintType } from '../../types/nft-map-types'
import { isDevelopment } from '../../utils/isDevelopment'
import { INftsData } from '../../services/nft.service'

import styles from './marketplace.module.scss'
import api from '../../services/axios.service'

interface IData {
  address: string,
  attributes: any[],
  coordinates: string[],
  createdAt: Date,
  description: string,
  id: number,
  image: string,
  isDefaultImg: boolean,
  isOnSale: boolean,
  metaDataUrl: string,
  name: string
  price: any
  properties: any
  seller_fee_basis_points: number
  userId: string
}

function Card({
  data,
  solPrice,
  handleOpen,
}: {
  data: INftsData
  solPrice: number
  handleOpen: (buttonType: string, nft_address: string) => void
}) {
  const [buttonType, setButtonType] = useState<string>(MintType.NONE)
  const [solAmount, setSOLAmount] = useState(0)

  const wallet = useWallet()
  const navigate = useNavigate()
  const location = data.coordinates.join(', ')
  const usdPrice = solPrice * solAmount

  const fetchNftData = async () => {
    const isLocal = isDevelopment()

    if (isLocal) {
      if (data.address) {
        if (wallet.publicKey?.toBase58() === data.userId) return setButtonType(MintType.SELL)
        return setButtonType(MintType.NONE)
      }
      return setButtonType(MintType.MINT)
    }

    if (data && data.address) {
      try {
        const nftMetadata = await api.get('/nfts/tokenMetadata/' + data.address)
        console.log('nftMetadata', nftMetadata.data)
        if (nftMetadata.status === 200) {
          const { owner, price } = nftMetadata.data
          if (price) setSOLAmount(price)

          if (wallet.publicKey?.toBase58() === owner && !price) {
            setButtonType(MintType.SELL)
          } else if (wallet.publicKey?.toBase58() !== owner && price) {
            setButtonType(MintType.BUY)
          } else {
            setButtonType(MintType.NONE)
          }
        } else {
          setButtonType(MintType.NONE)
        }
      } catch (error) {
        console.log(error)
        setButtonType(MintType.NONE)
      }
    } else {
      setButtonType(MintType.MINT)
    }
  }

  useEffect(() => {
    fetchNftData()
  }, [data])

  const autoOpen = buttonType === ButtonText.MINT ? true : false

  const handleMint = () => {
    const accessToken = sessionStorage.getItem('accessToken')
    if (buttonType === ButtonText.SHOW_MORE) {
      window.scrollTo(0, 0)
      navigate(`/nft?square=${location}`, { state: { autoOpen, buttonType, price: solAmount } })
    } else if (buttonType === ButtonText.MINT && !accessToken) {
      handleOpen(ButtonText.DISABLED, '')
    } else if (buttonType === ButtonText.MINT && accessToken) {
      navigate(`/nft?square=${location}&autoOpen=1&buttonValue=${buttonType}&price=${solAmount}`, { state: { autoOpen, buttonType, price: solAmount } })
    } else if (buttonType === ButtonText.SELL || buttonType === ButtonText.BUY) {
      window.open(`https://magiceden.io/marketplace/exgoland_collection?search=${data.name}`, '_blank')
    }
  }

  const handleNavigate = () => {
    window.scrollTo(0, 0)
    navigate(`/nft?square=${data.address ? data.address : location}&autoOpen=0&buttonValue=${buttonType}&price=${solAmount}`, {
      state: { autoOpen: false, buttonType, price: solAmount },
    })
  }

  return (
    <Box className={styles.card}>
      <div onClick={handleNavigate} className={styles.showMoreWindow}>
        <span className={styles.showMoreText}>{ButtonText.SHOW_MORE}</span>
      </div>
      <img src={data.image ?? defaultLandImg} className={styles.img} />
      <Typography className={styles.name}>{shortName(data.name)}</Typography>
      <Box className={styles.cardCoordinates}>
        <img src={mapPin} />
        <Typography className={styles.coordinates}>{data.coordinates[0]} </Typography>
        {data.coordinates.length > 1 && (
          <Tooltip title={`${getHiddenCoordinates(data.coordinates)}`} enterTouchDelay={0}>
            <Typography className={styles.showMore}>{'  ...'}</Typography>
          </Tooltip>
        )}
      </Box>
      {(buttonType === MintType.MINT) && (
        <Box className={styles.iconBox}>
          <img src={usdcLogo} className={styles.usdcLogo}/>
          <Typography className={styles.solPrice}>{process.env.REACT_APP_INITIAL_SALE_USDC_PRICE}</Typography>
          <Typography className={styles.usdPrice}>
            ($ {process.env.REACT_APP_INITIAL_SALE_USDC_PRICE})
          </Typography>
        </Box>
      )}
      {(buttonType === MintType.BUY) && (
        <Box className={styles.iconBox}>
          <img src={solanaLogo} className={styles.usdcLogo}/>
          <Typography className={styles.solPrice}>{solAmount.toFixed(2)}</Typography>
          <Typography className={styles.usdPrice}>
            ($ {usdPrice.toFixed(2)})
          </Typography>
        </Box>
      )}
      {buttonType !== ButtonText.SHOW_MORE && buttonType !== MintType.NONE && (
        <Button className={styles.btn} onClick={handleMint}>
          {buttonType}
        </Button>
      )}
    </Box>
  )
}

export default Card
