export function isMailValid(mail: string) {
  const mailformat = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
  if (mailformat.test(mail)) {
    return true
  } else {
    return false
  }
}

export function isPhoneNumberValid(phoneNumber: string) {
  const phoneformat = /(^374|^0)?(\d{8})$/
  return phoneformat.test(phoneNumber)
}

export function isNameValid(name: string) {
  if (name.length < 3) return 'Your name must be at least 3 characters long'

  return true
}

export function shortName(name: string) {
  if ((name !== null || name !== undefined) && name.length > 11) {
    return `${name.substring(0, 11) + '...'}`
  } else {
    return name
  }
}
