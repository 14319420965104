import { Box, Typography, useMediaQuery } from '@material-ui/core'
import tokenLockupProgramImg from '../../../../assets/images/tokenLockupProgram.png'

import styles from './tokenlockupprogram.module.scss'

export default function TokenLockupProgramPage() {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={styles.pageContainer}>
      <Box className={styles.leftSection}>
        <Typography className={styles.pageTitle}>Token Lock-up Program</Typography>
        
        {isMobile ? <img src={tokenLockupProgramImg} height={ '100%' } /> : <img src={tokenLockupProgramImg} width={ '100%' } />}
      </Box>
    </Box>
  )
}
