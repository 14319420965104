import { Box } from '@mui/material'
import Typography from '@mui/material/Typography'
import distributedLedgers from '../../../../assets/images/distributedLedgers.png'
import infiniteShardingParadigm from '../../../../assets/images/infiniteSharingParadigm.png'
import instantHypercubeRouting from '../../../../assets/images/instantHypercubeRouting.png'
import proofOfStakeApproach from '../../../../assets/images/posApproach.png'
import styles from './blockchainSelection.module.scss'

const blockchainSelectionList = [
  { img: distributedLedgers, name: 'Distributed Ledgers' },
  { img: infiniteShardingParadigm, name: 'Infinite Sharing Paradigm' },
  { img: instantHypercubeRouting, name: 'Instant Hypercube Routing' },
  { img: proofOfStakeApproach, name: 'Proof-of Stake Approach' },
]

export default function BlockchainSelection() {
  return (
    <>
      <Box className={styles.blockchainSelection}>
        <Typography sx={{ fontFamily: 'DM Sans' }} className={styles.blockchainSelectionTitle}>
          Blockchain Selection
        </Typography>
        <Typography
          className={styles.description}
          sx={{ maxWidth: '900px', mb: '20px', fontFamily: 'DM Sans' }}>
          At the core of the platform is the ON Block chain — a scalable and flexible block chain
          architecture that consists of a master chain and up to 292 accompanying block chains.
          <br />
          <br />
          Below are some notable design choices that allow the ON Block chain to process millions of
          transactions per second.
        </Typography>
        <Box className={styles.blockchainSelectionList}>
          {blockchainSelectionList.map((el, index) => (
            <Box className={styles.blockchainSelectionListItem} key={index}>
              <Box className={styles.img} component='img' src={el.img} sx={{ width: '106px' }} />
              <Box className={styles.blockchainSelectionListItemText}>
                <Typography
                  className={styles.textItem}
                  sx={{ maxWidth: '154px', fontWeight: 700, fontFamily: 'DM Sans' }}>
                  {el.name}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>

      
      <Box>
        <Typography className={styles.poweredByAiTitle}>Powered By AI</Typography>
        <Typography className={styles.firstContent} sx={{ maxWidth: '900px', mb: '20px', fontFamily: 'DM Sans' }}>
          We introduce to you the first ever metaverse powered by AI. This will make our metaverse way
          more immersive and way more smarter. <strong>EXGOLAND</strong> will be an intelligent
          virtual world that has climate, environment and time controlled by our unique AI.
          <Typography className={styles.poweredByAiSecondTitle}>
            Why EXGOLAND is the right choice?
          </Typography>
          The high security we have since our AI will be able to track transactions, movements and
          engagement to make sure everything is going safely and securely. Moreover, this enhances the
          security that <strong>EXGOLAND</strong> has so it will never be hacked.
          <br />
          <br />
          The benefits that the AI brings to our metaverse are limitless, but lets talk on a very
          important and interest one. Map navigation, this is a very important point for users to know
          where and how to go. Our AI will be navigating all users to the locations that they want and
          help them in anything they ask.
        </Typography>
      </Box>
    </>
  )
}
