import { useRef } from 'react'
import { Link } from 'react-router-dom'
import CloseIcon from '@mui/icons-material/Close'
import { Box, Grid, Typography } from '@mui/material'
import { exgolandURL } from '../../../utils/constants/URL'
import { ShareModalItems } from '../../../utils/constants/shareModalItems'
import { ShareModalProps } from './types'

import styles from './shareModal.module.scss'

const iconStyle = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '46px',
  height: '46px',
  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.15)',
  borderRadius: '5px',
  transition: 'transform 0.3s ease',
  cursor: 'pointer',
  ':hover': {
    transform: 'scale(1.2)',
  },
}

export default function ShareModal({ handleClose }: ShareModalProps) {
  const labelRef = useRef<HTMLElement | null>(null)

  const showLabel = () => {
    navigator.clipboard.writeText(exgolandURL)
    if (labelRef?.current !== null) {
      labelRef.current.style.display = 'block'
    }
  }

  const hideLabel = () => {
    if (labelRef?.current !== null) {
      labelRef.current.style.display = 'none'
    }
  }

  return (
    <>
      <Box className={styles.wrapper} onClick={handleClose} />
      <Box className={styles.shareModalContainer}>
        <Typography className={styles.shareModalTitle} id='parent-modal-title'>
          Share on:
        </Typography>
        <Grid container className={styles.iconContainer}>
          <CloseIcon className={styles.close} onClick={handleClose} />
          {ShareModalItems.map((item, index) => (
            <Grid item key={index}>
              {item.path.length !== 0 ? (
                <Link to={item.path} target='_blank'>
                  <Box sx={iconStyle}>
                    <img src={item.imgSrc} alt={item.imgSrc} />
                  </Box>
                </Link>
              ) : (
                <Box sx={iconStyle} onClick={showLabel} onMouseLeave={hideLabel}>
                  <img src={item.imgSrc} alt={item.imgSrc} />

                  <span ref={labelRef} className={styles.copyText}>
                    Copied to clipboard!
                  </span>
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  )
}
