import { useEffect, useRef, useState } from 'react'
import { Typography, Box, Button, useMediaQuery } from '@mui/material'
import { ObserverUtil } from '../../utils/observerUtil'
import arrowIcon from '../../assets/images/arrowIcon.svg'
import { partners } from './types'

import styles from './roadmap.module.scss'

export default function Partners() {
  const [isVisible, setIsVisible] = useState(false)
  const [hasBeenVisible, setHasBeenVisible] = useState(false)
  const [showMore, setShowMore] = useState<boolean>(false)
  const isMobile = useMediaQuery('(max-width: 600px)')

  const partnersRef = useRef(null)

  useEffect(() => {
    ObserverUtil(partnersRef, setIsVisible, hasBeenVisible, setHasBeenVisible)
  }, [hasBeenVisible])

  function handleMore() {
    setShowMore(!showMore)
    if (partnersRef.current !== null) {
      ;(partnersRef.current as HTMLDivElement).scrollIntoView({ behavior: 'auto' })
    }
  }

  return (
    <Box className={styles.partnersWrapper}>
      <Typography textAlign='center' className={styles.partnersTitle}>
        Our Partners
      </Typography>
      <Box
        ref={partnersRef}
        className={`${styles.partners} ${isVisible ? styles.animate : styles.hidden}`}
      >
        {(isMobile && !showMore ? partners.slice(0, 4) : partners).map((partner, index) => (
          <Box
            className={
              styles.lines
              }
            key={index}
          >
            <a className={styles.partnerItem} rel='noreferrer' target='_blank' href={partner.url}>
              <img key={index} src={partner.icon} />
            </a>
          </Box>
        ))}
        {isMobile && (
          <Button
            onClick={handleMore}
            className={`${styles.arrowBtn} ${showMore ? styles.rotate : ''}`}
          >
            <img src={arrowIcon} />
          </Button>
        )}
      </Box>
    </Box>
  )
}
