import magicEdenIcon from '../../assets/images/magicEden.svg'
import openSeaIcon from '../../assets/images/opensea.svg'

interface MarketplaceLinksTypes {
  name: string
  icon: string
  link: string
}

export const marketplaceLinks: MarketplaceLinksTypes[] = [
  {
    name: 'Magic Eden',
    icon: magicEdenIcon,
    link: 'https://magiceden.io/',
  },
  {
    name: 'Opensea',
    icon: openSeaIcon,
    link: 'https://opensea.io/collection/official-nft-marketplace',
  },
]
