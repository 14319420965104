export const GeneralFaqsItems = [
  {
    title: 'What does Mint mean?',
    description:
      {
        text: [
          'We describe this word as “Be The First Owner”.',
          'However this is the exact definition:',
          'Minting an NFT is the act of creating a unique digital item on the blockchain. It involves generating a token with distinct metadata (details) that represents ownership of the digital asset.'
        ]
      },
  },
  {
    title: 'How to mint a land in EXGOLAND?',
    description:
      {
        linkText: {
          text: 'This is a short video to show you how easy to mint a land in EXGOLAND:',
          title: 'How to Mint a Land in EXGOLAND? - YouTube',
          link: 'https://www.youtube.com/watch?v=z1epo87sR8E&ab_channel=EXGOLAND'
        },
        text: [
          'However, these are the steps to do so:',
          'Step 1: Connect Your wallet',
          'Step 2: Go to ‘Buy Land’',
          'Step 3: Choose a Green Colored Land ',
          'Step 4: Click ‘Mint’ ',
          'Step 5: Enter your details and agree on the terms by clicking the checkbox. ',
          'Step 6: Click mint and approve the pop ups ',
          'Step 7: Congratulations you have successfully owned a land in EXGOLAND',
        ]
      },
  },
  {
    title: 'Do I need a wallet to buy a land in EXGOLAND?',
    description: {
      text: [
        'For now YES, but we are currently implementing the ability to buy a land with your credit/debit card.',
      ],
    }
  },
  {
    title: 'How does EXGOLAND Affiliate Program work?',
    description:
      {
        linkText: {
          text: 'This is a video to explain fully what is EXGOLAND Affiliate Program and how it works.',
          title: 'EXGOLAND Affiliate Program Tutorial - YouTube',
          link: 'https://www.youtube.com/watch?v=X73EQ63khjc&ab_channel=EXGOLAND'
        },
      },
  },
  {
    title: 'How can I sell my land?',
    description:
      {
        linkText: {
          text: 'Here is a video tutorial on how to sell your land:',
          title: 'How To Sell your Land In EXGOLAND? - YouTube',
          link: 'https://www.youtube.com/watch?v=xPEvnjv2-tw&t=8s&ab_channel=EXGOLAND'
        },
      },
  },
  {
    title: 'Where can I sell my Land NFT?',
    description:
      {
        linkText: {
          text: 'You can currently sell it on Magic Eden marketplace. This is the collection link:',
          title: 'EXGOLAND - Magic Eden',
          link: 'https://magiceden.io/marketplace/exgoland_collection'
        },
      },
  },
  {
    title: 'What is Combine? how can I combine my lands?',
    description:
      {
        text: [
          'Combine is a feature that allows you to combine you adjacent lands and make a bigger lands for bigger projects and investments.'
        ],
        linkText: {
          text: 'This is a video tutorial on how to combine you lands:',
          title: 'How To Combine a Land In EXGOLAND? - YouTube',
          link: 'https://www.youtube.com/watch?v=zOKQA3dS6_Q&ab_channel=EXGOLAND'
        },
      }
  },
  {
    title: 'What does Royalty mean?',
    description:
      {
        text: [
          'Royalty represents the share creators get out of the proceeds in any secondary sale. This way you benefit from being the first owner of the land. It is set as a percentage and we have it range between 0% to 15%.',
        ]
      },
  },
]
