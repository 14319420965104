import { FC, useContext } from 'react'
import { Box, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useWallet } from '@solana/wallet-adapter-react'
import WalletButtons from '../Top/walletButtons'
import styles from '../Top/top.module.scss'
import { WalletConnectContext } from '../../providers/web3'
import { useUserContext } from '../../context/UserContext'

export const WalletPopup: FC = () => {
  const { isModalOpen, closeModal, isCentered, showCrossMint } = useContext(WalletConnectContext)
  const wallet = useWallet()
  const { isLoggedin } = useUserContext()

  return (
    <Box className={styles.relative}>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          className={`${styles.walletsModal} ${
            (wallet.connected || isLoggedin) ? styles.connectedWalletModal : styles.disconnectedWalletModal
          } ${isCentered ? `${styles.centered}` : ''} ${
            showCrossMint ? `${styles.crossmint}` : ''
          }`}>
          <CloseIcon className={styles.close} onClick={closeModal} />
          <Typography className={styles.title}>
            {(wallet.connected || isLoggedin) ? 'My wallet' : 'Connect Your Wallet'}
          </Typography>
          <WalletButtons />
        </Box>
      </Modal>
      {isModalOpen && <Box className={styles.curtain} onClick={closeModal} />}
    </Box>
  )
}
