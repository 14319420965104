import { useRef, useEffect, useState, useCallback, MouseEvent, useMemo } from 'react'
import { Box, Button } from '@mui/material'
import { useMediaQuery } from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useWallet } from '@solana/wallet-adapter-react'
import minusIcon from '../../assets/images/darkMinusIcon.svg'
import plusIcon from '../../assets/images/darkPlusIcon.svg'
import exgolandLogo from '../../assets/images/exgolandMapLogo.svg'
import mapPin from '../../assets/images/whiteMapPin.svg'
import chart from '../../assets/images/chart.svg'
import Loader from '../Loader'
import MapInfoModal from './MapInfoModal'
import { getLandCoordianates } from '../../utils/constants/cameraZoomSettings'
import { MapLandTypes, mapZoomSettings, Path, Props } from './types'
import { MintedLandsColor } from '../../utils/constants/landsColors'
import { getTopLeftCoordinates, getUserCombinedLands } from '../../utils/getSquareWithCoordinates'
import { getMovingSettings } from '../../utils/getMapPagePinCoorinatesSizes'
import { ExgolandLogoCoordinatesByRect, exgolandLogoCoordinates } from '../../utils/constants/mapConstants'
import { ISquareCoord } from '../../types/nft-map-types'

import styles from './map.module.scss'

export default function Map({ onLandSelect, nftData }: Props) {
  const canvasRef = useRef<any>(null)
  const canvasData = useRef<{
    size: { width: number; height: number }
    ctx: CanvasRenderingContext2D | null
    isDirty: boolean
    ctxMatrix: number[]
  }>({
    size: { width: 0, height: 0 },
    ctx: null,
    isDirty: true,
    ctxMatrix: [1, 0, 0, 1, 0, 0],
  })
  const draggingState = useRef({
    isDragged: false,
    isDragging: false,
    dragStart: { x: 0, y: 0 },
  })
  const cameraState = useRef({
    cameraZoom: 0.12,
    cameraOffset: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
    initialPinchDistance: null,
  })
  const previousHovered = useRef<{
    content: string | HTMLImageElement | null
    paths: Path[] | null
  }>({
    content: null,
    paths: null,
  })
  const selectedSection = useRef<{
    paths: Path[] | null
    x: number | null
    y: number | null
    content: string | HTMLImageElement | null
  }>({
    paths: null,
    x: null,
    y: null,
    content: null,
  })

  const wallet = useWallet()

  const [svgData, setSVGData] = useState<string>()
  const [canvasPaths, setCanvasPaths] = useState<Path[]>([])
  const [isLoading, setLoading] = useState<boolean>(true)
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState<number>(window.innerHeight)
  const [squareCoordinates, setSquareCoordinates] = useState<ISquareCoord[]>([])

  const isMobile = useMediaQuery('(max-width: 600px)')

  const userNfts = useMemo(() => {
    if (!wallet || !wallet.publicKey || !nftData) return []

    return nftData.filter((el) => {
      return el.userId?.walletAddress === wallet?.publicKey?.toBase58()
    })
  }, [wallet, nftData])

  const isInteractivePath = useCallback(
    (path: Path) =>
      path.content !== MapLandTypes.NOT_INTERACTIVE &&
      path.content !== MapLandTypes.WATER,
    [],
  )

  const getNeighborLands = (landCoordinates: number[]) => {
    const result = []
    for (let i = 0; i < userNfts.length; i++) {
      for (let j = 0; j < userNfts[i].coordinates.length; j++) {
        const [x, y] = userNfts[i].coordinates[j].split(', ').map(Number)
        if (
          (landCoordinates[0] + 1 === x && landCoordinates[1] === y) ||
          (landCoordinates[0] - 1 === x && landCoordinates[1] === y) ||
          (landCoordinates[1] + 1 === y && landCoordinates[0] === x) ||
          (landCoordinates[1] - 1 === y && landCoordinates[0] === x) ||
          (landCoordinates[0] - 1 === x && landCoordinates[1] - 1 === y) ||
          (landCoordinates[0] - 1 === x && landCoordinates[1] + 1 === y) ||
          (landCoordinates[0] + 1 === x && landCoordinates[1] - 1 === y) ||
          (landCoordinates[0] + 1 === x && landCoordinates[1] + 1 === y) ||
          (landCoordinates[0] === x && landCoordinates[1] === y)
        ) {
          result.push(userNfts[i])
          break
        }
      }
    }
    return result
  }

  const updateContextData = () => {
    const { ctxMatrix } = canvasData.current

    canvasData.current.isDirty = false
    ctxMatrix[3] = ctxMatrix[0] = cameraState.current.cameraZoom
    ctxMatrix[2] = ctxMatrix[1] = 0
    ctxMatrix[4] = cameraState.current.cameraOffset.x
    ctxMatrix[5] = cameraState.current.cameraOffset.y
  }

  const applyChanges = () => {
    const { ctx, ctxMatrix, isDirty } = canvasData.current
    if (isDirty) updateContextData()
    ctx?.setTransform(
      ctxMatrix[0],
      ctxMatrix[1],
      ctxMatrix[2],
      ctxMatrix[3],
      ctxMatrix[4],
      ctxMatrix[5],
    )
  }

  const panCanvas = (coordinates: { x: number; y: number }) => {
    if (canvasData.current.isDirty) updateContextData()

    const { maxLeft, maxRight, maxBottom, maxTop } = getMovingSettings(
      cameraState.current.cameraZoom,
    )

    cameraState.current.cameraOffset.x = Math.max(
      Math.min(cameraState.current.cameraOffset.x + coordinates.x, maxLeft),
      maxRight,
    )

    cameraState.current.cameraOffset.y = Math.max(
      Math.min(cameraState.current.cameraOffset.y + coordinates.y, maxTop),
      maxBottom,
    )

    canvasData.current.isDirty = true
  }

  const scaleAt = (coordinates: { x: number; y: number }, amount: number) => {
    if (draggingState.current.isDragging) return

    if (canvasData.current.isDirty) updateContextData()
    const { maxLeft, maxRight, maxBottom, maxTop } = getMovingSettings(
      cameraState.current.cameraZoom,
    )
    const { maxZoom, minZoom } = mapZoomSettings
    const oldZoom = cameraState.current.cameraZoom
    cameraState.current.cameraZoom = Math.max(Math.min(oldZoom * amount, maxZoom), minZoom)

    const { cameraOffset } = cameraState.current
    cameraOffset.x =
      coordinates.x - (coordinates.x - cameraOffset.x) * (cameraState.current.cameraZoom / oldZoom)
    cameraOffset.y =
      coordinates.y - (coordinates.y - cameraOffset.y) * (cameraState.current.cameraZoom / oldZoom)

    cameraOffset.x = Math.max(Math.min(cameraOffset.x, maxLeft), maxRight)
    cameraOffset.y = Math.max(Math.min(cameraOffset.y, maxTop), maxBottom)
    canvasData.current.isDirty = true
  }

  const drawImage = (path: Path) => {
    if (!canvasData.current.ctx || !path.content) return
    const ctx = canvasData.current.ctx
    const img = path.content as HTMLElement
    ctx.drawImage(
      img as HTMLImageElement,
      0,
      0,
      (img as HTMLImageElement).width,
      (img as HTMLImageElement).height,
      path.coordinates.x,
      path.coordinates.y,
      path.coordinates.width,
      path.coordinates.height,
    )
  }

  const fillPath = (path: Path, active = false) => {
    const { ctx } = canvasData.current
    if (!ctx) return

    if (typeof path.content === 'string') {
      if (active) {
        if (typeof path.content === 'string') {
          ctx.fillStyle = 'red'
          ctx.fill(path.path)
        }
        return true
      } else {
        ctx.fillStyle = path.content
        ctx.fill(path.path)
      }
    } else if (path.content !== null) {
      if (path.content.complete) {
        drawImage(path)
        if (active) {
          ctx.fillStyle = 'red'
          ctx.fill(path.path)
          return true
        }
      } else {
        path.content.onload = function() {
          drawImage(path)
        }
      }
    }
  }

  const drawCanvas = () => {
    const { ctx } = canvasData.current
    if (!ctx) return

    ctx.setTransform(1, 0, 0, 1, 0, 0)
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height)

    applyChanges()
    if (canvasPaths.length === 0) {
      const parser = new DOMParser()
      if (svgData) {
        const svg = parser.parseFromString(svgData, 'image/svg+xml')

        const pathsArray = []
        const rects = svg.querySelectorAll('rect')
        for (let i = 0; i < rects.length; i++) {
          const rect = rects[i]
          const rectColor = rect.attributes.getNamedItem('fill')?.value
          const path = new Path2D()
          if (rectColor?.includes('url') === true) {
            const img = new Image()
            img.src = exgolandLogo
            img.crossOrigin = 'anonymous'

            path.rect(
              rect.x.baseVal.value,
              rect.y.baseVal.value,
              rect.width.baseVal.value,
              rect.height.baseVal.value,
            )

            const newPath = {
              path,
              coordinates: {
                x: rect.x.baseVal.value,
                y: rect.y.baseVal.value,
                width: rect.width.baseVal.value,
                height: rect.height.baseVal.value,
              },
              content: img,
            }

            fillPath(newPath)
            pathsArray.push(newPath)
          } else {
            path.rect(
              rect.x.baseVal.value,
              rect.y.baseVal.value,
              rect.width.baseVal.value,
              rect.height.baseVal.value,
            )

            const newPath: any = {
              path,
              coordinates: {
                x: rect.x.baseVal.value,
                y: rect.y.baseVal.value,
                width: rect.width.baseVal.value,
                height: rect.height.baseVal.value,
              },
              content: rectColor ?? '',
            }
            let shouldSkip = false
            const { landX, landY } = getLandCoordianates(newPath)
            const { topLeftCoordinates, notPrimaryCombinedLands } =
              getTopLeftCoordinates(squareCoordinates)

            shouldSkip = notPrimaryCombinedLands.some((el) =>
              el.find((subEl) => landX === subEl[0] && landY === subEl[1]),
            )

            if (shouldSkip) continue

            topLeftCoordinates?.forEach((el) => {
              if (landX === el.topLeft[0] && landY === el.topLeft[1]) {
                path.rect(
                  rect.x.baseVal.value,
                  rect.y.baseVal.value,
                  rect.width.baseVal.value * el.size + (el.size - 1) * 20,
                  rect.height.baseVal.value * el.size + (el.size - 1) * 20,
                )

                newPath.path = path
                newPath.coordinates.width = newPath.coordinates.width * el.size + (el.size - 1) * 20
                newPath.coordinates.height =
                  newPath.coordinates.height * el.size + (el.size - 1) * 20
              }
            })
            let nft
            if (nftData !== undefined) {
              nft = nftData.find((obj) => obj.coordinates.includes(`${landX}, ${landY}`))
            }
            if (nft) {
              if (nft.isDefaultImg) {
                newPath.content = MintedLandsColor
              } else {
                const landImg = new Image()
                landImg.crossOrigin = 'anonymous'
                landImg.src = nft.imageUrl
                newPath.content = landImg
              }
            }

            pathsArray.push(newPath)
            fillPath(newPath)
          }
        }
        setCanvasPaths(pathsArray)
      }
    } else {
      canvasPaths.forEach((path) => fillPath(path))
    }
  }

  const touchEvent = (event: TouchEvent) => {
    if (event.type === 'touchstart') {
      draggingState.current.isDragging = true
      const rect = canvasRef.current.getBoundingClientRect()
      const touch = event.touches[0]
      draggingState.current.dragStart = {
        x: touch.clientX - rect.left,
        y: touch.clientY - rect.top,
      }
    }
    if (event.type === 'touchend') {
      draggingState.current.isDragging = false
    }
    if (event.type === 'touchmove') {
      event.preventDefault()
      event.stopPropagation()
      const rect = canvasRef.current.getBoundingClientRect()
      const touch = event.touches[0]
      const x = touch.clientX - rect.left
      const y = touch.clientY - rect.top

      const { dragStart } = draggingState.current
      const xChange = x - dragStart.x
      const yChange = y - dragStart.y
      dragStart.x = x
      dragStart.y = y
      if (draggingState.current.isDragging) {
        panCanvas({ x: xChange, y: yChange })
        requestAnimationFrame(drawCanvas)
      }
    }
  }

  const mouseEvent = (event: MouseEvent) => {
    if (event.type === 'mousedown') {
      draggingState.current.isDragging = true
    } else if (event.type === 'mouseup' || event.type === 'mouseout') {
      draggingState.current.isDragging = false
    } else if (event.type === 'mousemove') {
      const rect = (event.target as any)?.getBoundingClientRect()
      const x = event.clientX - rect.left
      const y = event.clientY - rect.top

      const { dragStart } = draggingState.current
      const xChange = x - dragStart.x
      const yChange = y - dragStart.y
      dragStart.x = x
      dragStart.y = y
      if (draggingState.current.isDragging) {
        draggingState.current.isDragged = true
        panCanvas({ x: xChange, y: yChange })
        requestAnimationFrame(drawCanvas)
      } else {
        const { ctx } = canvasData.current
        if (!ctx) return

        const { paths: previousPath } = previousHovered.current
        const path = canvasPaths.find((p) => ctx.isPointInPath(p.path, x, y))

        if (path === undefined || !isInteractivePath(path)) {
          canvasRef.current.style.cursor = 'default'
          if (previousPath !== null && previousPath.length) {
            for (let i = 0; i < previousPath.length; i++) {
              const isSelected = selectedSection.current.paths?.some(
                (path) =>
                  path.coordinates.x === previousPath[i].coordinates.x &&
                  path.coordinates.y === previousPath[i].coordinates.y,
              )
              if (isSelected) break

              previousPath[i].content = previousHovered.current.content
            }
            previousHovered.current = {
              content: null,
              paths: null,
            }
          }
          drawCanvas()
          return
        }

        if (!path) return
        if (previousPath?.includes(path)) return

        const { landX, landY } = getLandCoordianates(path)
        if (landX === exgolandLogoCoordinates.x && landY === exgolandLogoCoordinates.y) return

        canvasRef.current.style.cursor = 'pointer'

        const combinedLand = nftData?.filter((el) => el.coordinates.includes(`${landX}, ${landY}`))

        if (combinedLand?.length) {
          if (previousPath) {
            for (let i = 0; i < previousPath.length; i++) {
              const isSelected = selectedSection.current.paths?.some(
                (path) =>
                path.coordinates.x === previousPath[i].coordinates.x &&
                path.coordinates.y === previousPath[i].coordinates.y,
                )
                if (isSelected) break
                
                previousPath[i].content = previousHovered.current.content
              }
              previousHovered.current = {
              content: null,
              paths: null,
            }
          }
          drawCanvas()
          
          const pathCoordinate: number[][] = []
          for (let i = 0; i < combinedLand[0].coordinates.length; i++) {
            const coordinate = combinedLand[0].coordinates[i].split(', ').map(Number)
            const x = coordinate[0] * 84 + 20
            const y = coordinate[1] * 84

            pathCoordinate.push([x, y])
          }

          const paths: Path[] = []

          pathCoordinate.forEach((el) => {
            const path = canvasPaths.find(
              (p) => p.coordinates.x === el[0] && p.coordinates.y === el[1],
            )
            if (path) paths.push(path)
          })

          previousHovered.current = {
            content: paths[0].content,
            paths: paths,
          }
          for (let i = 0; i < paths.length; i++) {
            paths[i].content = MapLandTypes.SELECTED
          }
          drawCanvas()
          return
        }

        if (previousPath) {
          for (let i = 0; i < previousPath.length; i++) {
            const isSelected = selectedSection.current.paths?.some(
              (path) =>
                path.coordinates.x == previousPath[i].coordinates.x &&
                path.coordinates.y == previousPath[i].coordinates.y,
            )
            if (isSelected) break

            previousPath[i].content = previousHovered.current.content
          }
        }
        if (
          selectedSection.current.paths?.some(
            (p) => p.coordinates.x === path.coordinates.x && p.coordinates.y === path.coordinates.y,
          )
        ) {
          previousHovered.current = {
            content: null,
            paths: null,
          }
        } else {
          previousHovered.current = {
            content: path.content,
            paths: [path],
          }
        }

        path.content = MapLandTypes.SELECTED
        drawCanvas()
      }
    }
  }

  const mouseWheelEvent = (event: WheelEvent) => {
    event.preventDefault()

    const x = event.clientX
    const y = event.clientY
    if (event.deltaY < 0) {
      if (cameraState.current.cameraZoom === mapZoomSettings.maxZoom) return
      scaleAt({ x, y }, 1.1)
    } else {
      if (cameraState.current.cameraZoom === mapZoomSettings.minZoom) return
      scaleAt({ x, y }, 1 / 1.1)
    }
    requestAnimationFrame(drawCanvas)
  }

  const handleClick = (event: MouseEvent<HTMLCanvasElement>) => {
    if (draggingState.current.isDragging) return

    if (draggingState.current.isDragged) {
      draggingState.current.isDragged = false
      return
    }

    captureScreenshot(event)

    const rect = event.currentTarget.getBoundingClientRect()
    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    const path = canvasPaths.find((p) => canvasData.current.ctx?.isPointInPath(p.path, x, y))
    if (path === undefined || !isInteractivePath(path)) return

    const { landX, landY } = getLandCoordianates(path)
    if (landX === exgolandLogoCoordinates.x && landY === exgolandLogoCoordinates.y) return

    const neighborLands = getNeighborLands([landX, landY])
    const combinedLand = nftData?.filter((el) => el.coordinates.includes(`${landX}, ${landY}`))
    let isCombined = false

    if (selectedSection.current.paths) {
      for (let i = 0; i < selectedSection.current.paths.length; i++) {
        if (selectedSection.current.paths[i].coordinates.x === path.coordinates.x &&  selectedSection.current.paths[i].coordinates.y === path.coordinates.y) return
      }

      for (let i = 0; i < selectedSection.current.paths.length; i++) {
        const previousPath = canvasPaths.find(
          (p) =>
            selectedSection.current.paths &&
            p.coordinates.x === selectedSection.current.paths[i].coordinates.x &&
            p.coordinates.y === selectedSection.current.paths[i].coordinates.y,
        )
        if (previousPath) {
          previousPath.content = selectedSection.current.content
        }
      }
    }
    if (combinedLand?.length) {
      const pathCoordinate: number[][] = []
      for (let i = 0; i < combinedLand[0].coordinates.length; i++) {
        const coordinate = combinedLand[0].coordinates[i].split(', ').map(Number)
        const x = coordinate[0] * 84 + 20
        const y = coordinate[1] * 84

        pathCoordinate.push([x, y])
      }

      const paths: Path[] = []

      pathCoordinate.forEach((el) => {
        const path = canvasPaths.find((p) => p.coordinates.x === el[0] && p.coordinates.y === el[1])
        if (path) paths.push(path)
      })

      selectedSection.current = {
        content: previousHovered.current.content,
        x: null,
        y: null,
        paths: paths,
      }
      for (let i = 0; i < paths.length; i++) {
        paths[i].content = MapLandTypes.SELECTED
      }
      isCombined = true
      drawCanvas()
    }

    if (neighborLands.length) {
      const pathCoordinates: number[][] = []
      for (let j = 0; j < neighborLands.length; j++) {
        for (let i = 0; i < neighborLands[j].coordinates.length; i++) {
          const coordinate = neighborLands[j].coordinates[i].split(', ').map(Number)
          const x = coordinate[0] * 84 + 20
          const y = coordinate[1] * 84

          pathCoordinates.push([x, y])
        }
      }

      const paths: Path[] = []

      pathCoordinates.forEach((el) => {
        const path = canvasPaths.find((p) => p.coordinates.x === el[0] && p.coordinates.y === el[1])
        if (path) paths.push(path)
      })

      const contents = paths.map((el) => {
        return el.content
      })

      for (let i = 0; i < paths.length; i++) {
        paths[i].content = MapLandTypes.SELECTED
      }

      drawCanvas()
      captureScreenshot(event, true)

      for (let i = 0; i < paths.length; i++) {
        paths[i].content = contents[i]
      }
    }

    if (!isCombined) {
      selectedSection.current = {
        paths: [path],
        x: path.coordinates.x,
        y: path.coordinates.y,
        content: previousHovered.current.content,
      }

      path.content = MapLandTypes.SELECTED
    }

    requestAnimationFrame(drawCanvas)
    onLandSelect({
      x: landX,
      y: landY,
    })
  }

  useEffect(() => {
    const fetchMapSVG = async () => {
      const response = await fetch('/newest_map.svg')
      const dataText = await response.text()
      setSVGData(dataText)
    }
    if (nftData !== undefined) {
      fetchMapSVG()
    }
  }, [nftData])

  useEffect(() => {
    const canvas = canvasRef.current
    if (canvas === null) return

    if (svgData === undefined) return

    setLoading(false)

    canvas.addEventListener('touchmove', touchEvent)
    canvas.addEventListener('touchstart', touchEvent)
    canvas.addEventListener('touchend', touchEvent)
    canvas.addEventListener('wheel', mouseWheelEvent)

    if (canvasPaths.length === 0) {
      const navbarHeight = isMobile ? 72 : 110

      canvas.width = windowWidth
      canvas.height = windowHeight - navbarHeight

      const context = canvas.getContext('2d')
      canvasData.current.ctx = context

      const parser = new DOMParser()
      const svg = parser.parseFromString(svgData, 'image/svg+xml')
      const svgElement = svg.documentElement
      const svgWidth = Number(svgElement.getAttribute('width'))
      const svgHeight = Number(svgElement.getAttribute('height'))
      canvasData.current.size = { width: svgWidth, height: svgHeight }

      const mapCenterX = canvasRef.current.width - svgWidth / 2
      const mapCenterY = canvasRef.current.height - svgHeight / 2
      panCanvas({
        x: mapCenterX * cameraState.current.cameraZoom,
        y: mapCenterY * cameraState.current.cameraZoom,
      })
      requestAnimationFrame(drawCanvas)
    }

    setLoading(false)

    return () => {
      canvas.removeEventListener('touchmove', touchEvent)
      canvas.removeEventListener('touchstart', touchEvent)
      canvas.removeEventListener('touchend', touchEvent)
      canvas.removeEventListener('wheel', mouseWheelEvent)
    }
  }, [svgData, canvasPaths])

  useEffect(() => {
    if (nftData !== undefined) {
      const combinedSquaresCoordinates = getUserCombinedLands(nftData)
      setSquareCoordinates(combinedSquaresCoordinates)
    }
  }, [nftData])

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (canvasRef.current && !canvasRef.current.contains(event.target as Node)) {
        // Action to perform when clicking outside the canvas

        if (selectedSection.current.paths)
          for (let i = 0; i < selectedSection.current.paths.length; i++) {
            const previousPath = canvasPaths.find(
              (p) =>
                p.coordinates.x === selectedSection.current.x &&
                p.coordinates.y === selectedSection.current.y,
            )

            if (previousPath && canvasPaths.length) {
              previousPath.content = selectedSection.current.content

              canvasPaths?.forEach((path) => {
                if (canvasData.current.ctx) fillPath(path)
              })
            }
          }
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [selectedSection])

  // useEffect(() => {
  //   const resizeCanvas = (canvas: any) => {
  //     setWindowWidth(window.innerWidth)
  //     setWindowHeight(window.innerHeight)
  //   }

  //   if (ctx) {
  //     const canvas = canvasRef.current

  //     if (canvas === null) return

  //     const navbarHeight = isMobile ? 72 : 110

  //     canvas.width = window.outerWidth
  //     canvas.height = window.outerHeight - navbarHeight

  //     adjustZoom(0)
  //   }

  //   window.addEventListener('resize', resizeCanvas)

  //   return () => {
  //     window.removeEventListener('resize', resizeCanvas)
  //   }
  // }, [window.innerWidth, window.innerHeight, window.orientation])

  const captureScreenshot = (
    event: React.MouseEvent<HTMLCanvasElement>,
    isCombinedScreenshot = false,
  ) => {
    const canvas = canvasRef.current
    const canvasContext = canvas?.getContext('2d')

    if (canvas && canvasContext) {
      const rect = canvas.getBoundingClientRect()
      const clickX = event.clientX - rect.left
      const clickY = event.clientY - rect.top
      const captureSize = 350

      const startX = clickX - captureSize / 2
      const startY = clickY - captureSize / 2

      const tempCanvas = document.createElement('canvas')
      tempCanvas.width = captureSize
      tempCanvas.height = captureSize
      const tempContext = tempCanvas.getContext('2d')

      if (tempContext) {
        // Draw the background color on the temporary canvas
        tempContext.fillStyle = '#2A427E'
        tempContext.fillRect(0, 0, captureSize, captureSize)

        // Draw the content from the main canvas on top of the background
        tempContext.drawImage(
          canvas,
          startX,
          startY,
          captureSize,
          captureSize,
          0,
          0,
          captureSize,
          captureSize,
        )

        if (isCombinedScreenshot) {
          const screenshot = tempCanvas.toDataURL('image/png')
          localStorage.setItem('combinedScreenshotUrl', screenshot)
          return
        }

        const screenshot = tempCanvas.toDataURL('image/png')
        localStorage.setItem('screenshotUrl', screenshot)
      }
    }
  }

  return (
    <Box className={styles.content}>
      <Box className={styles.mapWrapper}>
        <div className={styles.container}>
          <Box className={styles.selectSection}>
            <Box className={styles.mapPinImg}>
              <img src={mapPin} />
            </Box>
            <Link to={'/marketplace'}>
              <Box className={styles.chartImg}>
                <img src={chart} />
              </Box>
            </Link>
          </Box>
          <Box className={styles.zoomBtns}>
            <Button
              onClick={() => {
                scaleAt(
                  {
                    x: canvasRef.current.width / 2,
                    y: canvasRef.current.height / 2,
                  },
                  1.1,
                )
                requestAnimationFrame(drawCanvas)
              }}>
              <img src={plusIcon} />
            </Button>
            <Button
              onClick={() => {
                scaleAt(
                  {
                    x: canvasRef.current.width / 2,
                    y: canvasRef.current.height / 2,
                  },
                  1 / 1.1,
                )
                requestAnimationFrame(drawCanvas)
              }}>
              <img src={minusIcon} />
            </Button>
          </Box>
          <MapInfoModal />
          {isLoading && (
            <Loader
              isFullScreen={true}
              background='inherit'
              loaderColor='#0a3455'
              isLoaderSmall={true}
            />
          )}
          <Box className={styles.canvasBox}>
            <canvas
              ref={canvasRef}
              className={isLoading ? styles.hidden : styles.canvas}
              onClick={handleClick}
              onMouseDown={mouseEvent}
              onMouseUp={mouseEvent}
              onMouseOut={mouseEvent}
              onMouseMove={mouseEvent}
            />
          </Box>
        </div>
      </Box>
    </Box>
  )
}
