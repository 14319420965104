import { useMediaQuery } from '@material-ui/core'
import { Box } from '@mui/material'
import Footer from '../Footer'
import Roadmap from '../Roadmap'
import Navigation from '../Navigation'

export default function RoadmapPage() {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box sx={{ paddingTop: isMobile ? '72px' : '109px' }}>
      <Navigation type='black' />
      <Roadmap />
      <Footer />
    </Box>
  )
}
