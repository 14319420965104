import { useState, useEffect } from 'react'
import { Checkbox, Box, Button, Typography, TextField } from '@mui/material'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined';
import { DropzoneArea } from 'material-ui-dropzone';
import { AttachFile, Audiotrack, Description, PictureAsPdf, Theaters, Image } from '@material-ui/icons';


import Modal from '../shared/Modal'
import xIcon from '../../assets/images/x-icon.svg'

import styles from './nftDescriptionUpdateModal.module.scss'

export default function NftDescriptionUpdateModal({
  open,
  toggleModal,
	initialDescription,
	onSaveDescription
}: {
  open: boolean
  toggleModal: (open: boolean) => void,
	initialDescription: string | undefined,
	onSaveDescription: (value: string | undefined) => void
}) {
	const [description, setDescription] = useState(initialDescription)

	useEffect(() => {
		if (initialDescription && initialDescription !== description) {
			setDescription(initialDescription)
		}
	}, [initialDescription])

	const handleClose = () => {
		console.log('close')
    toggleModal(false)
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
        <Box className={styles.mintLandContainer}>
					<Button onClick={handleClose} className={styles.closeButton}>
						<img src={xIcon} alt='' />
					</Button>

					<Typography className={styles.modalTitle}>
						Edit your land description below
					</Typography>

					<Box className={styles.inputsContainer}>
						<TextField
							className={styles.descriptionInput}
							value={description}
							multiline
							rows={4}
							placeholder='Write a description'
							onChange={(event) => {
								setDescription(event.target.value)
							}}
						/>
					</Box>

					<Box className={styles.btnsSection}>
						<Button
							className={(initialDescription === description) ? styles.mintDisabledBtn :  styles.mintActiveBtn}
							onClick={() => onSaveDescription(description)}
							disabled={initialDescription === description || !description}
						>
							Save
						</Button>
					</Box>
        </Box>
      </Modal>
    </div>
  )
}
