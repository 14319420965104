import { List, ListItem, Typography, useMediaQuery } from '@material-ui/core'
import { Box } from '@mui/system'
import styles from './techToolsUsed.module.scss'

import autodeskMayaImg from '../../../../assets/images/autodeskMayaImg.svg'
import photonImg from '../../../../assets/images/photonImg.svg'
import substancePainterImg from '../../../../assets/images/substancePainterImg.svg'
import unityImg from '../../../../assets/images/unityImg.svg'
import unrealEngine from '../../../../assets/images/unrealEngine.svg'

export default function TechToolsUsedPage() {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={styles.userBenefitsContainer}>
        <Box className={styles.userBenefits}>
            <Typography className={styles.technicalToolsUsedTitle}>Technical Tools Used</Typography>
            <List>
            <ListItem className={styles.technicalToolsUsedListItem}>
                <span className={styles.listItemMarker} />
                <Typography>Unreal Engine for visualization, simulation and gaming.</Typography>
            </ListItem>
            <ListItem className={styles.technicalToolsUsedListItem}>
                <span className={styles.listItemMarker} />
                <Typography>Multiplayer game network Photon unity</Typography>
            </ListItem>
            <ListItem className={styles.technicalToolsUsedListItem}>
                <span className={styles.listItemMarker} />
                <Typography>3D environment using Autodesk Maya</Typography>
            </ListItem>
            <ListItem className={styles.technicalToolsUsedListItem}>
                <span className={styles.listItemMarker} />
                <Typography>Texturing using Substance Painter</Typography>
            </ListItem>
            </List>
            <Box className={styles.technicalToolsImgs}>
                <Box component='img' src={unrealEngine} style={{width: '80px'}}/>
                <Box component='img' src={photonImg} />
                <Box component='img' src={autodeskMayaImg} />
                <Box component='img' src={substancePainterImg} />
            </Box>
        </Box>
    </Box>
  )
}
