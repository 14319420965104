import { ButtonType, ButtonProps } from './types'
import styles from './button.module.scss'

export default function CustomButton({ btnType, text, onClick, disabled = false }: ButtonProps) {
  const getStyle = (buttonType: ButtonType) => {
    switch (buttonType) {
      case ButtonType.DARK:
        return styles.darkBtn
      default:
        return styles.lightBtn
    }
  }

  return (
    <button
      onClick={onClick}
      className={`${styles.button} ${getStyle(btnType)}`}
      disabled={disabled}
    >
      <span style={{ fontFamily: 'DM Sans' }}>{text}</span>
    </button>
  )
}
