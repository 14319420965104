import { Box, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { FiberManualRecord } from '@mui/icons-material'
import { Typography, useMediaQuery } from '@mui/material'
import { pageRows } from './types'

import styles from './roadmap.module.scss'


export default function Roadmap() {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={styles.pageContainer}>
      <Typography className={styles.roadmapTitle}>Roadmap</Typography>
      {pageRows.map((item, index) => (
        <>
          <Typography className={styles.yearText}>Year {item.year}</Typography>
          <Box className={styles.mobileContainer}>
            {isMobile  ? (
              <Box className={styles.leftLine} />
            ) : (
              <img className={styles.pageImg} src={item.img} />
            )}

            <Box className={styles.pageRow}>
              {item.columns.map((column, index) => (
                <List key={index} className={styles.list}>
                  {isMobile && <Box className={styles.quarterCircle} />}
                  {column.map((text, index) => (
                    <ListItem key={index} className={styles.listItem}>
                      
                      {text.bullet && (
                        <ListItemIcon className={styles.listiyemicon}>
                          <FiberManualRecord className={styles.icon} />
                        </ListItemIcon>
                      )}
                      
                      <Typography className={styles.listItemText} >{text.text}</Typography>
                    </ListItem>
                  ))}
                </List>
              ))}
            </Box>
          </Box>
        </>
      ))}
    </Box>
  )
}
