import { Box, Typography, useMediaQuery } from '@mui/material'
import Navigation from '../Navigation'
import Partners from '../Roadmap/Partners'
import Footer from '../Footer'
import pic from '../../assets/images/about_us/who-we-are.png'
import underline from '../../assets/images/about_us/underline.svg'
import styles from './aboutUs.module.scss'

export default function AboutUs() {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={styles.container}>
      <Navigation type='black' />
      {!isMobile && (
        <Box className={styles.header}>
          <h2>About us</h2>
        </Box>
      )}
      <Box className={styles.whoWeAreWrapper}>
        <Box className={styles.whoWeAreContainer}>
          <Box className={styles.whoWeAre}>Who we are</Box>
          <Box className={styles.underline}>
            <img src={underline} />
          </Box>
        </Box>
      </Box>
      <Box className={styles.dataContainer}>
        <Box className={styles.textContainer}>
          <Typography className={styles.text}>
            <span style={{ fontWeight: 700 }}>EXGOLAND</span> Technology L.L.C is a web 3.0 company
            that was originally established in Dubai, UAE on September 2022. It is the first mover in
            the MENA region. At EXGOLAND Technology, we are creating the future by developing
            several solutions that assist users worldwide from metaverse to marketplace, gaming and
            digital assets.
          </Typography>
          <br />
          <br />
          <Typography className={styles.text}>
            We introduce to you <span style={{ fontWeight: 700 }}>EXGOLAND</span>: A Distinctive
            Virtual Reality Experience Powered by Artificial Intelligence.{' '}
            <span style={{ fontWeight: 700 }}>EXGOLAND</span> is a unique metaverse powered by AI
            that has so many unique features that solves many problems that people are facing now.
            Our value proposition is “The true feeling and real living of the metaverse with more
            gaining than paying”.
          </Typography>
        </Box>

        <Box className={styles.imgContainer}>
          <img src={pic} />
        </Box>
      </Box>
      {!isMobile && (
        <Box className={styles.partnersWrapper}>
          <Partners />
        </Box>
      )}
      <Footer />
    </Box>
  )
}
