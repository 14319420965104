import { useMediaQuery } from '@material-ui/core'
import { List, ListItem, ListItemText, Typography } from '@mui/material'
import { Box } from '@mui/system'
import chartImg from '../../../../assets/images/solana.png'
import tokenImg from '../../../../assets/images/network_platform_token_img.png'

import styles from './networkPlatform.module.scss'

export default function NetworkPlatform() {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={styles.networkPlatformContainer}>
      <Box className={styles.networkPlatformBox}>
        <Typography className={styles.networkPlatformTitle}>Network Platform</Typography>
        <Box className={styles.networkPlatformBoxSubTitle}>(Solana)</Box>
        <Typography className={styles.networkPlatformSecondTitle}>
          You May Have a Question Why SOLANA?
        </Typography>
        <Typography
          sx={{
            mt: '16px',
            fontFamily: 'DM Sans',
            fontWeight: 400,
          }}>
          Solana is one of the most revolutionary blockchain solutions in the world of crypto today.
          It has been touted as the “Ethereum Killer” and every day it grows to show its potential
          in competing with Ethereum. Solana is an excellent ecosystem for building games and other
          digital real estates. It has partnered with plenty of gaming, VR, AR, and NFT companies to
          create a virtual world that invites millions of users to participate, play, stake, trade
          and earn from their metaverse activities. For this reason, Solana has provided an ideal
          platform for developers to build games and infrastructure that supports the metaverse on
          their blockchain.
        </Typography>
        {/* {isMobile && (
          <>
            <Typography className={styles.mobileTokenDistributionTitle}>
              Token Distribution
            </Typography>
            <Box component='img' src={chartImg} className={styles.mobileChartImg} />
          </>
        )} */}
        {/* <Typography className={`${styles.networkPlatformTitle} ${styles.itroductionOfToken}`}>
          Introduction of Token
        </Typography>
        <List component='ol' className={styles.list}>
          <ListItem className={styles.listItem}>
            <ListItemText className={styles.listItemText}>
              <strong>Token name</strong>: EXGO
            </ListItemText>
          </ListItem>
          <ListItem className={styles.listItem}>
            <ListItemText className={styles.listItemText}>
              <strong>Token tracker: </strong>EXG
            </ListItemText>
          </ListItem>
          <ListItem className={styles.listItem}>
            <ListItemText className={styles.listItemText}>
              <strong>Blockchains: Solana, Ethereum, Binance and Polygon</strong>
            </ListItemText>
          </ListItem>
          <ListItem className={styles.listItem}>
            <ListItemText className={styles.listItemText}>
              <strong> Token supply: 1,000,000,000 EXG</strong>
            </ListItemText>
          </ListItem>
          <ListItem className={styles.listItem}>
            <ListItemText className={styles.listItemText}>
              <strong>Benefits of token</strong>:EXGO Token will be used exclusively on{' '}
              <strong>EXGOLAND,</strong> so the only token will be used is EXGO.
            </ListItemText>
          </ListItem>
        </List> */}
      </Box>
      {/* <Box className={styles.tokenDistributionBox}>
        {!isMobile && (
          <Typography variant='h4' fontWeight={600}>
            Token Distribution
          </Typography>
        )}
        <Box className={styles.tokenDistribitionImgBox}>
          {!isMobile && <Box component='img' src={chartImg} />}
          <Box component='img' src={tokenImg} className={styles.tokenImgMobile} />
        </Box>
      </Box> */}
      
      <Box className={styles.tokenDistribitionImgBox}>
        <Box component='img' src={tokenImg} className={styles.tokenImgMobile} />
      </Box>
    </Box>
  )
}
