export function ObserverUtil(
  ref: any,
  setIsVisible: any,
  hasBeenVisible?: boolean,
  setHasBeenVisible?: any,
  threshold?: number,
) {
  const isForSingleUse = hasBeenVisible != undefined && setHasBeenVisible != undefined
  const observer = new IntersectionObserver(

    ([entry]) => {
      if (!isForSingleUse) {
        if (entry.isIntersecting) {
          setIsVisible(true)
        } else {
          setIsVisible(false)
        }
      } else if (isForSingleUse && entry.isIntersecting && !hasBeenVisible) {
        setIsVisible(true)
        setHasBeenVisible(true)
      }
    },
    {
      root: null,
      rootMargin: '0px',
      threshold: threshold ?? 0.35,
    },
  )

  if (ref.current !== undefined && ref.current !== null) {
    observer.observe(ref.current)
  }
  return () => {
    if (ref.current !== undefined && ref.current !== null) {
      observer.unobserve(ref.current)
    }
  }
}
