import { useEffect, useRef, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'

import { ObserverUtil } from '../../utils/observerUtil'
import Navigation from '../Navigation'
import Loader from '../Loader'
import watchSVG from '../../assets/images/watch.svg'
import headerLogo from '../../assets/images/logo-big.png'
import watchWhiteSVG from '../../assets/images/watchWhiteSVG.svg'

import styles from './header.module.scss'
import VideoModal from '../Videomodal'

export default function Header() {
  const [watchVideoHover, setWatchVideoHover] = useState(false)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isVisible, setIsVisible] = useState(false)
  const [hasBeenVisible, setHasBeenVisible] = useState(false)
  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false)

  const headerRef = useRef(null)

  function isVideoReady() {
    const video = document.getElementById('video') as HTMLVideoElement
    if (video instanceof HTMLVideoElement && video.readyState >= 4) {
      setIsVideoLoaded(true)
    } else {
      setTimeout(() => {
        isVideoReady()
      }, 100)
    }
  }

  useEffect(() => {
    isVideoReady()
  }, [])

  useEffect(() => {
    ObserverUtil(headerRef, setIsVisible, hasBeenVisible, setHasBeenVisible)
  }, [hasBeenVisible])

  return (
    <Box ref={headerRef} className={styles.header} id='home'>
      <video className={styles.headerBackgroundVideo} id='video' autoPlay muted loop playsInline>
        <source src='https://exgolandproduction.s3.amazonaws.com/y2mate.is+-+EXGOLAND-mXTZkXO2Ucc-1080pp-1696060269+(1).mp4' type='video/mp4' />
      </video>
      {!isVideoLoaded && (
        <Box className={styles.headerBackgroundVideo}>
          <Loader isFullScreen={false} background='black' loaderColor='#0a3455' />
        </Box>
      )}

      <Box className={styles.headerContainer}>
        <Navigation />
        <img
          className={`${styles.headerLogo} ${isVisible ? styles.animate : styles.hidden}`}
          src={headerLogo}
        />
        <Typography
          className={`${styles.headerText} ${isVisible ? styles.animate : styles.hidden} `}
        >
          The MetaVerse For Visionaries
        </Typography>
        <Button
          className={`${styles.headerButton} ${isVisible ? styles.animate : styles.hidden}`}
          onMouseEnter={() => {
            setWatchVideoHover(true)
          }}
          onMouseLeave={() => {
            setWatchVideoHover(false)
          }}
          onClick={() => {
            setIsOpen(true)
          }}
        >
          <img src={watchVideoHover ? watchWhiteSVG : watchSVG} alt='watchVideo' />
          Watch video
        </Button>
        <VideoModal setIsOpen={setIsOpen} isOpen={isOpen} />
      </Box>
    </Box>
  )
}
