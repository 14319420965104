import { useState } from 'react'
import { Link } from 'react-router-dom'
import { Box, Button, Typography, useMediaQuery } from '@mui/material'
import Modal from '../shared/Modal'
import xIcon from '../../assets/images/x-icon.svg'
import { marketplaceLinks } from './types'

import styles from './chooseMarketplace.module.scss'

export default function ChooseMarketplaceModal({
  open,
  toggleModal,
}: {
  open: boolean
  toggleModal: (open: boolean) => void
}) {
    const [selectedLink, setSelectedLink] = useState<string>('')
    const isMobile = useMediaQuery('(max-width: 600px)')

    const handleChooseMarketplace = (link: string) => {
        setSelectedLink(link)
    }

    const handleClose = () => {
      toggleModal(false)
    }

  return (
      <Modal open={open} onClose={handleClose}>
        <Box className={styles.modalContainer}>
          <Button onClick={handleClose} className={styles.closeButton}>
            <img src={xIcon} alt='' />
          </Button>
          <Typography className={styles.modalTitle}>Choose the marketpace to continue!</Typography>
          <Box className={styles.line} />
          <Box className={styles.buttonsSection}>
            {marketplaceLinks.map((item, index) => (
              <Button
                onClick={() => handleChooseMarketplace(item.link)}
                key={index}
                className={`${styles.button} ${
                  item.link === selectedLink && styles.selectedButton
                }`}>
                <img src={item.icon} />
                <Typography>{item.name}</Typography>
              </Button>
            ))}
          </Box>
          <Box className={styles.line} />
          <Button
            disabled={selectedLink === ''}
            className={`${styles.continueButton} ${
              selectedLink === '' && styles.disabledContinueButton
            }`}>
            <Link to={selectedLink} target='_blank'>
             {isMobile ? 'Confirm Checkout' : 'Continue'} 
            </Link>
          </Button>
        </Box>
      </Modal>
  )
}
