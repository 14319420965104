import { useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import AboutUs from './components/AboutUs'
import ComingSoon from './components/ComingSoon'
import Contact from './components/Contact'
import Home from './components/Home'
import Policy from './components/Policy'
import RoadmapPage from './components/RoadmapPage'
import TermsOfService from './components/TermsOfService'
import Whitepaper from './components/WhitepaperPage'
import RewardPage from './components/reward'
import Affiliate from './pages/Afiiliate'
import BlogNews from './pages/BlogNews'
import BuyLandMap from './pages/BuyLandMap'
import Marketplace from './pages/Marketplace'
import { Web3Provider } from './providers/web3'
import NftById from './pages/NftById'
import Faqs from './components/Footer/faq/Faqs'
import MagicWallet from './pages/MagicWallet'

import { UserProvider } from './context/UserContext'

declare global {
  interface Window {
    dataLayer:any;
  }
}

const App = (): JSX.Element => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search)
    const refCode = queryParams.get('ref')
    if (refCode != null) {
      localStorage.setItem('refCode', refCode)
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  }, [])

  useEffect(() => {
    window.dataLayer.push({
      event: 'pageview',
      page: {
        url: location,
        path: location?.pathname
      }
    })
  }, [location])

  return (
    <Web3Provider>
      <UserProvider>
        <div>
          <Routes>
            <Route path='/' element={<Home />} />
            <Route path='/buy-land' element={<BuyLandMap />} />
            <Route path='/roadmap' element={<RoadmapPage />} />
            <Route path='/whitepaper/*' element={<Whitepaper />} />
            {/* <Route path='/reward' element={<RewardPage />} /> */}
            <Route path='/conditions' element={<Home />} />
            <Route path='/faq' element={<Faqs />} />
            <Route path='/about' element={<AboutUs />} />
            <Route path='/policy' element={<Policy />} />
            <Route path='/blog' element={<BlogNews />} />
            <Route path='/nft' element={<NftById />} />
            <Route path='/contact' element={<Contact />} />
            <Route path='/affiliate-page' element={<Affiliate />} />
            <Route path='/terms-and-conditions' element={<TermsOfService />} />
            <Route path='/marketplace' element={<Marketplace />} />
            <Route path='/land_map' element={<BuyLandMap />} />

            <Route path='/magic_wallet' element={<MagicWallet />} />
          </Routes>
        </div>
      </UserProvider>
    </Web3Provider>
  )
}

export default App
