import axios from 'axios'

export const getSolPrice = async () => {
  try {
    const { data } = await axios.get('https://api.binance.com/api/v3/ticker/price?symbol=SOLUSDT')

    return data.price
  } catch (e) {
    console.log(e)
    return 0
  }
}
