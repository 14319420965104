import { useMediaQuery } from '@material-ui/core'
import { Box, Typography } from '@mui/material'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Checked from '../../../../assets/images/checked.svg'
import table from '../../../../assets/images/table.svg'
import Unchecked from '../../../../assets/images/unchecked.svg'

import styles from './comparison.module.scss'
import { rows } from './data'

export default function Comparison() {
  const isIPad = useMediaQuery('(max-width: 900px)')

  return (
    <Box className={styles.comparisonContainer}>
      {!isIPad && (
        <TableContainer component={Paper} className={styles.compContainer}>
          <Table sx={{ minWidth: 650 }} aria-label='simple table'>
            <TableHead>
              <TableRow sx={{ backgroundColor: '#0C375C' }}>
                <TableCell className={styles.title}>Features</TableCell>
                <TableCell className={styles.title} align='center'>
                  Decentraland
                </TableCell>
                <TableCell className={styles.title} align='center'>
                  Sandbox
                </TableCell>
                <TableCell className={styles.title} align='center'>
                  APE Coin
                </TableCell>
                <TableCell className={styles.title} align='center'>
                  Exgoland
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow key={row.features}>
                  <TableCell className={styles.featuresCell} component='th' scope='row'>
                    {row.features}
                  </TableCell>
                  <TableCell className={styles.cell} align='center'>
                    {typeof row.decentraland === 'string' ? (
                      row.decentraland
                    ) : row.decentraland === true ? (
                      <img src={Checked} />
                    ) : (
                      <img src={Unchecked} />
                    )}
                  </TableCell>
                  <TableCell className={styles.cell} align='center'>
                    {typeof row.sandbox === 'string' ? (
                      row.sandbox
                    ) : row.sandbox === true ? (
                      <img src={Checked} />
                    ) : (
                      <img src={Unchecked} />
                    )}
                  </TableCell>
                  <TableCell className={styles.cell} align='center'>
                    {typeof row.blocktopia === 'string' ? (
                      row.blocktopia
                    ) : row.blocktopia === true ? (
                      <img src={Checked} />
                    ) : (
                      <img src={Unchecked} />
                    )}
                  </TableCell>
                  <TableCell className={styles.cell} align='center'>
                    {typeof row.exgoland === 'string' ? (
                      row.exgoland
                    ) : row.exgoland === true ? (
                      <img src={Checked} />
                    ) : (
                      <img src={Unchecked} />
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {isIPad && (
        <Box className={styles.mobileTableBox}>
          <img src={table} />
        </Box>
      )}
      {/* <Typography className={styles.bottomTitle}>Use of Funds</Typography>
      <Typography className={styles.bottomText}>
        EXGOLAND will use the funds from token to develop and grow the company, from adding team
        members to getting an experienced staff and even using some in marketing.
      </Typography> */}
    </Box>
  )
}
