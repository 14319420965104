import { Button, Typography } from '@material-ui/core'
import { Box } from '@mui/material'
import Modal from '..'
import ErrorIcon from '../../../../assets/images/errorIcon.svg'
import CloseIcon from '../../../../assets/images/x-icon.svg'

import styles from './errorModal.module.scss'

export default function ErrorModal({
    isOpen,
    handleOpen,
    title,
    subTitle,
}: {
    isOpen: boolean
    handleOpen: any
    title: string
    subTitle?: string
}) {
    return (
        <Modal open={isOpen} onClose={handleOpen}>
            <Box className={styles.modalBackground}>
                <Box className={styles.content}>
                    <Button onClick={handleOpen} className={styles.closeButton}>
                        <img src={CloseIcon} alt='' />
                    </Button>
                    <img src={ErrorIcon} className={styles.ErrorIcon} alt='' />
                    <Box className={styles.textContainer}>
                        <Typography className={styles.title}>{title}</Typography>
                        {subTitle != null && subTitle.length > 0 && (
                            <Typography className={styles.subTitle}>{subTitle}</Typography>
                        )}
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}
