import { Box, Typography } from '@mui/material'
import navbarLogo from '../../../../assets/images/logo-small.png'
import styles from './aboutInfo.module.scss'

const AboutInfo = () => (
  <Box className={styles.aboutInfo}>
    <Typography className={styles.logo}>
      Top questions about <img src={navbarLogo} alt='navbarLogo' />
    </Typography>
    <Typography className={styles.about}>
      Need something cleared up? Here are most frequently asked questions
    </Typography>
  </Box>
)

export default AboutInfo
