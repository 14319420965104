export const headerNavBarItems = [
  {
    id: '0',
    title: 'Home',
    path: '/',
  },
  {
    id: '1',
    title: 'Buy Land',
    path: '/buy-land',
  },
  {
    id: '2',
    title: 'Roadmap',
    path: '/roadmap',
  },
  {
    id: '3',
    title: 'Whitepaper',
    path: '/whitepaper',
  },
  {
    id: '4',
    title: 'My Wallet',
    path: '/magic_wallet',
  },
]
