import { Box, TextField, Typography } from '@mui/material'
import { RoyaltyInputProps } from './types'
import arrowDropIcon from '../../../assets/images/arrowDropIcon.svg'
import mintInfoIcon from '../../../assets/images/mintInfoIcon.svg'

import styles from './royaltyInput.module.scss'

export default function RoyaltyInput({ royalty, setRoyalty, minRoyaltyValue, maxRoyaltyValue }: RoyaltyInputProps) {

    const minRoyalty = minRoyaltyValue ?? 0
    const maxRoyalty = maxRoyaltyValue ?? 15
    const exceptionRoyaltyValue = '00'

    const handleChangeRoyalty = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (
            Number(event.target.value) >= minRoyalty &&
            Number(event.target.value) <= maxRoyalty &&
            event.target.value != exceptionRoyaltyValue
        ) {
            setRoyalty(event.target.value)
        }
    }

    const handleAddRoyalty = () => {
        if (Number(royalty) < 15 && Number(royalty) + 1 <= 15) {
            setRoyalty(royalty.length !== 0 ? String(Number(royalty) + 1) : String(1))
        }
    }

    const handleReduceRoyalty = () => {
        setRoyalty(
            royalty.length !== 0 && Number(royalty) && Number(royalty) - 1 >= 0 ? String(Number(royalty) - 1) : String(0),
        )
    }

    return (
        <Box className={styles.royaltyInputContainer}>
            <TextField className={styles.royaltyInput} value={royalty} onChange={handleChangeRoyalty} autoComplete='off' />
            {royalty.length == 0 ? (
                <Typography className={styles.placeholderDefault}>
                    Royalty(%)<Typography className={styles.red}>*</Typography>
                </Typography>
            ) : <Typography className={styles.percentage}>%</Typography>}
            <Box className={styles.arrowButtonsContainer} >
                <img
                    className={styles.arrowDropUpIcon}
                    src={arrowDropIcon}
                    alt='arrowDropUpIcon'
                    onClick={handleAddRoyalty}
                />
                <img
                    className={styles.arrowDropDownIcon}
                    src={arrowDropIcon}
                    alt='arrowDropDownIcon'
                    onClick={handleReduceRoyalty}
                />
            </Box>
            <Box className={styles.infoIconBox}>
                <img className={styles.infoIcon} src={mintInfoIcon} alt='infoIcon' />
            </Box>
        </Box>
    )
}
