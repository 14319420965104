import { Link } from 'react-router-dom'
import { Typography } from '@mui/material'

import styles from '../drawer/contentDrawer.module.scss'

export const termsOfServiceData = {
  content: [
    {
      title: <Typography>Your Account and Responsibilities </Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            You’re responsible for your use of the Services and any content you provide, including
            compliance with applicable laws. Content on the Services may be protected by others’
            intellectual property rights. Please don’t copy, upload, download, or share content
            unless you have the right to do so.
          </Typography>
          <Typography className={styles.content}>
            {' '}
            Your use of the Services must comply with our Rules.
          </Typography>
          <Typography className={styles.content}>
            You may need to register for an account to access some or all of our Services. Help us
            keep your account protected. Safeguard your password to the account, and keep your
            account information current. We recommend that you do not share your password with
            others.
          </Typography>
          <Typography className={styles.content}>
            If you’re accepting these Terms and using the Services on behalf of someone else (such
            as another person or entity), you represent that you’re authorized to do so, and in that
            case the words “you” or “your” in these Terms include that other person or entity. To
            use our Services, you must be at least 13 years old.
          </Typography>
          <Typography className={styles.content}>
            If you use the Services to access, collect, or use personal information about other
            EXGOLAND users (“Personal Information”), you agree to do so in compliance with
            applicable laws. You further agree not to sell any Personal Information, where the term
            “sell” has the meaning given to it under applicable laws.
          </Typography>
          <Typography className={styles.content}>
            For Personal Information you provide to us (e.g. as a Newsletter Editor), you represent
            and warrant that you have lawfully collected the Personal Information and that you or a
            third party has provided all required notices and collected all required consents before
            collecting the Personal Information. You further represent and warrant that EXGOLAND’s
            use of such Personal Information in accordance with the purposes for which you provided
            us the Personal Information will not violate, misappropriate or infringe any rights of
            another (including intellectual property rights or privacy rights) and will not cause us
            to violate any applicable laws.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>User Content on the Services</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            EXGOLAND may review your conduct and content for compliance with these Terms and our
            Rules, and reserves the right to remove any violating content.
          </Typography>
          <Typography className={styles.content}>
            EXGOLAND reserves the right to delete or disable content alleged to be infringing the
            intellectual property rights of others, and to terminate accounts of repeat infringers.
            We respond to notices of alleged copyright infringement if they comply with the law;
            please report such notices using our{' '}
            <Link to='/policy' target='_blank'>
              Copyright Policy.
            </Link>
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Rights and Ownership</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            You retain your rights to any content you submit, post or display on or through the
            Services.
          </Typography>
          <Typography className={styles.content}>
            Unless otherwise agreed in writing, by submitting, posting, or displaying content on or
            through the Services, you grant EXGOLAND a nonexclusive, royalty-free, worldwide, fully
            paid, and sublicensable license to use, reproduce, modify, adapt, publish, translate,
            create derivative works from, distribute, publicly perform and display your content and
            any name, username or likeness provided in connection with your content in all media
            formats and distribution methods now known or later developed on the Services.
          </Typography>
          <Typography className={styles.content}>
            EXGOLAND needs this license because you own your content and EXGOLAND therefore can’t
            display it across its various surfaces (i.e., mobile, web) without your permission.{' '}
          </Typography>
          <Typography className={styles.content}>
            This type of license also is needed to distribute your content across our Services. For
            example, you post a picture on EXGOLAND. It is reproduced as versions on both our
            website and app, and distributed to multiple places within EXGOLAND, such as the
            homepage or reading lists. A modification might be that we show a snippet of your work
            (and not the full post) in a preview, with attribution to you. A derivative work might
            be a list of top authors or quotes on EXGOLAND that uses portions of your content, again
            with full attribution. This license applies to our Services only, and does not grant us
            any permissions outside of our Services.
          </Typography>
          <Typography className={styles.content}>
            So long as you comply with these Terms, EXGOLAND gives you a limited, personal,
            non-exclusive, and non-assignable license to access and use our Services.
          </Typography>
          <Typography className={styles.content}>
            The Services are protected by copyright, trademark, and other US and foreign laws. These
            Terms don’t grant you any right, title or interest in the Services, other users’ content
            on the Services, or EXGOLAND trademarks, logos or other brand features.
          </Typography>
          <Typography className={styles.content}>
            Separate and apart from the content you submit, post or display on our Services, we
            welcome feedback, including any comments, ideas and suggestions you have about our
            Services. We may use this feedback for any purpose, in our sole discretion, without any
            obligation to you. We may treat feedback as nonconfidential.
          </Typography>
          <Typography className={styles.content}>
            We may stop providing the Services or any of its features within our sole discretion. We
            also retain the right to create limits on use and storage and may remove or limit
            content distribution on the Services.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Termination</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            You’re free to stop using our Services at any time. We reserve the right to suspend or
            terminate your access to the Services with or without notice.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Transfer and Processing Data</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            In order for us to provide our Services, you agree that we may process, transfer and
            store information about you in the US and other countries, where you may not have the
            same rights and protections as you do under local law.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Indemnification</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            To the fullest extent permitted by applicable law, you will indemnify, defend and hold
            harmless EXGOLAND, and our officers, directors, agents, partners and employees
            (individually and collectively, the <i>“EXGOLAND Parties”</i>) from and against any
            losses, liabilities, claims, demands, damages, expenses or costs (<i>“Claims”</i>)
            arising out of or related to your violation, misappropriation or infringement of any
            rights of another (including intellectual property rights or privacy rights) or your
            violation of the law. You agree to promptly notify EXGOLAND Parties of any third-party
            Claims, cooperate with EXGOLAND Parties in defending such Claims and pay all fees, costs
            and expenses associated with defending such Claims (including attorneys’ fees). You also
            agree that the EXGOLAND Parties will have control of the defense or settlement, at
            EXGOLAND’s sole option, of any third-party Claims.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Disclaimers — Service is “As Is”</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            EXGOLAND aims to give you great Services but there are some things we can’t guarantee.
            Your use of our Services is at your sole risk. You understand that our Services and any
            content posted or shared by users on the Services are provided “as is” and “as
            available” without warranties of any kind, either express or implied, including implied
            warranties of merchantability, fitness for a particular purpose, title, and
            non-infringement. In addition, EXGOLAND doesn’t represent or warrant that our Services
            are accurate, complete, reliable, current or error-free. No advice or information
            obtained from EXGOLAND or through the Services will create any warranty or
            representation not expressly made in this paragraph. EXGOLAND may provide information
            about third-party products, services, activities or events, or we may allow third
            parties to make their content and information available on or through our Services
            (collectively, <i>“Third-Party Content”</i>). We do not control or endorse, and we make
            no representations or warranties regarding, any Third-Party Content. You access and use
            Third-Party Content at your own risk. Some locations don’t allow the disclaimers in this
            paragraph and so they might not apply to you.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Limitation of Liability</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            We don’t exclude or limit our liability to you where it would be illegal to do so; this
            includes any liability for the gross negligence, fraud or intentional misconduct of
            EXGOLAND or the other EXGOLAND Parties in providing the Services. In countries where the
            following types of exclusions aren’t allowed, we’re responsible to you only for losses
            and damages that are a reasonably foreseeable result of our failure to use reasonable
            care and skill or our breach of our contract with you. This paragraph doesn’t affect
            consumer rights that can’t be waived or limited by any contract or agreement.
          </Typography>
          <Typography className={styles.content}>
            <b>
              In countries where exclusions or limitations of liability are allowed, EXGOLAND and
              EXGOLAND Parties won’t be liable for:
            </b>
          </Typography>
          <Typography className={styles.content}>
            <b>
              (a) Any indirect, consequential, exemplary, incidental, punitive, or special damages,
              or any loss of use, data or profits, under any legal theory, even if EXGOLAND or the
              other EXGOLAND Parties have been advised of the possibility of such damages.
            </b>
          </Typography>
          <Typography className={styles.content}>
            <b>
              (b) Other than for the types of liability we can’t limit by law (as described in this
              section), we limit the total liability of EXGOLAND and the other EXGOLAND Parties for
              any claim arising out of or relating to these Terms or our Services, regardless of the
              form of the action, to the greater of $50.00 USD or the amount paid by you to use our
              Services.
            </b>
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Resolving Disputes; Binding Arbitration</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            We want to address your concerns without needing a formal legal case. Before filing a
            claim against EXGOLAND, you agree to contact us and attempt to resolve the claim
            informally by sending a written notice of your claim by email at{' '}
            <Link to={'mailto:support@exgoland.io'} target='_blank'>
              support@exgoland.io.
            </Link>
            The notice must (a) include your name, residence address, email address, and telephone
            number; (b) describe the nature and basis of the claim; and (c) set forth the specific
            relief sought. Our notice to you will be sent to the email address associated with your
            online account and will contain the information described above. If we can’t resolve
            matters within thirty (30) days after any notice is sent, either party may initiate a
            formal proceeding.{' '}
          </Typography>
          <Typography className={styles.content}>
            <b>
              Please read the following section carefully because it requires you to arbitrate
              certain disputes and claims with EXGOLAND and limits the manner in which you can seek
              relief from us, unless you opt out of arbitration by following the instructions set
              forth below. No class or representative actions or arbitrations are allowed under this
              arbitration provision. In addition, arbitration precludes you from suing in court or
              having a jury trial.
            </b>
          </Typography>
          <Typography className={styles.content}>
            (a)
            <b>
              {' '}
              No Representative Actions. You and EXGOLAND agree that any dispute arising out of or
              related to these Terms or our Services is personal to you and EXGOLAND and that any
              dispute will be resolved solely through individual action, and will not be brought as
              a class arbitration, class action or any other type of representative proceeding.
            </b>
          </Typography>
          <Typography className={styles.content}>
            (b)<b> Arbitration of Disputes.</b> Except for small claims disputes in which you or
            EXGOLAND seeks to bring an individual action in small claims court located in the county
            where you reside or disputes in which you or EXGOLAND seeks injunctive or other
            equitable relief for the alleged infringement or misappropriation of intellectual
            property,{' '}
            <b>
              you and EXGOLAND waive your rights to a jury trial and to have any other dispute
              arising out of or related to these Terms or our Services, including claims related to
              privacy and data security, (collectively, <i>“Disputes”</i>) resolved in court.
            </b>
          </Typography>
          <Typography className={styles.content}>
            (c) The arbitration will allow for the discovery or exchange of non-privileged
            information relevant to the Dispute. The arbitrator, EXGOLAND, and you will maintain the
            confidentiality of any arbitration proceedings, judgments and awards, including
            information gathered, prepared and presented for purposes of the arbitration or related
            to the Dispute(s) therein. The arbitrator will have the authority to make appropriate
            rulings to safeguard confidentiality, unless the law provides to the contrary. The duty
            of confidentiality doesn’t apply to the extent that disclosure is necessary to prepare
            for or conduct the arbitration hearing on the merits, in connection with a court
            application for a preliminary remedy, or in connection with a judicial challenge to an
            arbitration award or its enforcement, or to the extent that disclosure is otherwise
            required by law or judicial decision.
          </Typography>
          <Typography className={styles.content}>
            (d)
            <b>
              {' '}
              Any Dispute must be filed within one year after the relevant claim arose; otherwise,
              the Dispute is permanently barred, which means that you and EXGOLAND will not have the
              right to assert the claim.
            </b>
          </Typography>
          <Typography className={styles.content}>
            (g)
            <b>
              {' '}
              You have the right to opt out of binding arbitration within 30 days of the date you
              first accepted the terms of this section by sending an email of your request to{' '}
            </b>
            <Link to={'mailto:support@exgoland.io'} target='_blank'>
              support@exgoland.io
            </Link>
            {'. '}
            In order to be effective, the opt-out notice must include your full name and address and
            clearly indicate your intent to opt out of binding arbitration. By opting out of binding
            arbitration, you are agreeing to resolve Disputes in accordance with the next section
            regarding “Governing Law and Venue.”
          </Typography>
          <Typography className={styles.content}>
            (h) If any portion of this section is found to be unenforceable or unlawful for any
            reason, (1) the unenforceable or unlawful provision shall be severed from these Terms;
            (2) severance of the unenforceable or unlawful provision shall have no impact whatsoever
            on the remainder of this section or the parties’ ability to compel arbitration of any
            remaining claims on an individual basis pursuant to this section; and (3) to the extent
            that any claims must therefore proceed on a class, collective, consolidated, or
            representative basis, such claims must be litigated in a civil court of competent
            jurisdiction and not in arbitration, and the parties agree that litigation of those
            claims shall be stayed pending the outcome of any individual claims in arbitration.
            Further, if any part of this section is found to prohibit an individual claim seeking
            public injunctive relief, that provision will have no effect to the extent such relief
            is allowed to be sought out of arbitration, and the remainder of this section will be
            enforceable.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Land Transfer and Royalty Clause</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            1. <b>Transfer of Land Ownership</b>: If a user ("Original Owner") sells or transfers their land to another user ("New Owner"), and the New Owner wishes to combine this land with other lands they own, the following terms shall apply.
          </Typography>

          <Typography className={styles.content}>
            2. <b>Royalty Adjustments</b>: Upon the combination of lands, the New Owner shall have the exclusive right and authority to remove or adjust the royalty set by the Original Owner. The New Owner may establish a new royalty rate for the combined lands at their sole discretion.
          </Typography>

          <Typography className={styles.content}>
            3. <b>Acknowledgment</b>: By selling or transferring their land, the Original Owner acknowledges and agrees that they forfeit any rights to the royalty rate previously set for that specific land once it is combined with other lands by the New Owner.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Governing Law and Venue</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            These Terms and any dispute that arises between you and EXGOLAND will be governed by
            laws of United Arab Emirates except for its conflict of law principles.
          </Typography>

          <Typography className={styles.content}>
            Some countries have laws that require agreements to be governed by the local laws of the
            consumer’s country. This paragraph doesn’t override those laws.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Amendments</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            We may make changes to these Terms from time to time. If we make changes, we’ll provide
            you with notice of them by sending an email to the email address associated with your
            account, offering an in-product notification, or updating the date at the top of these
            Terms. Unless we say otherwise in our notice, the amended Terms will be effective
            immediately, and your continued use of our Services after we provide such notice will
            confirm your acceptance of the changes. If you don’t agree to the amended Terms, you
            must stop using our Services.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Severability</Typography>,
      body: (
        <Typography>
          {' '}
          <Typography className={styles.content}>
            If any provision or part of a provision of these Terms is unlawful, void or
            unenforceable, that provision or part of the provision is deemed severable from these
            Terms and does not affect the validity and enforceability of any remaining provisions.
          </Typography>
        </Typography>
      ),
    },
    {
      title: <Typography>Miscellaneous</Typography>,
      body: (
        <Typography>
          <Typography className={styles.content}>
            EXGOLAND’s failure to exercise or enforce any right or provision of these Terms will not
            operate as a waiver of such right or provision. These Terms, and the terms and policies
            listed in the Other Terms and Policies that May Apply to You Section, reflect the entire
            agreement between the parties relating to the subject matter hereof and supersede all
            prior agreements, statements and understandings of the parties. The section titles in
            these Terms are for convenience only and have no legal or contractual effect. Use of the
            word “including” will be interpreted to mean “including without limitation.” Except as
            otherwise provided herein, these Terms are intended solely for the benefit of the
            parties and are not intended to confer third-party beneficiary rights upon any other
            person or entity. You agree that communications and transactions between us may be
            conducted electronically.
          </Typography>
        </Typography>
      ),
    },
  ],
}
