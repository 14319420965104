import { FC, useState } from 'react'
import { CopyBtnTypes } from './copyBtnTypes'
import CopyIcon from '../../assets/images/copyIcon.svg'
import styles from './copy.module.scss'

const CopyButton: FC<CopyBtnTypes> = ({ text, width, height, isWhite }) => {
  const [showToolTip, setShowToolTip] = useState(false)

  const copyText = () => {
    navigator.clipboard.writeText(text)
    setShowToolTip(true)
    setTimeout(() => {
      setShowToolTip(false)
    }, 1000)
  }

  return (
    <div className={`${styles.button} ${showToolTip ? `${styles.disabled}` : ''}`}>
      <div className={`${styles.tooltip} ${showToolTip ? `${styles.show}` : ''}`}>
        <span className={styles.arrow}></span>
        <span className={styles.message}>Copied !</span>
      </div>
      <img
        className={isWhite ? styles.white : ''}
        onClick={copyText}
        src={CopyIcon}
        width={width ?? 20}
        height={height ?? 20}
        alt='copy icon'
      />
    </div>
  )
}

export default CopyButton
