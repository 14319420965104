import { Box, Button, TextField, Typography } from '@mui/material'
import emailjs from 'emailjs-com'
import { useEffect, useState } from 'react'
import Modal from '..'
import CloseIcon from '../../../../assets/images/x-icon.svg'
import ConfirmationModal from '../ConfirmationModal'
import type { NotifyMeModalProps } from './types'

import styles from './notifyMeModal.module.scss'

function NotifyMeModal({ toggleModal, isOpen }: NotifyMeModalProps) {
  const [email, setEmail] = useState<string>('')
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isValid, setIsValid] = useState<boolean>(false)
  const [isSubmited, setIsSubmited] = useState<boolean>(false)
  const [isSending, setIsSending] = useState<boolean>(false)

  const closeSuccessModal = () => {
    setIsSubmited(false)
    toggleModal?.()
  }

  const handleClose = () => {
    setEmail('')
    setErrorMessage('')
    toggleModal?.()
  }

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setEmail(e.target.value)
    if (e.target.value.length === 0) {
      setErrorMessage('*This field is required')
    } else if (!validateEmail(e.target.value)) {
      setIsValid(false)
      setErrorMessage('*Incorrect email')
    } else {
      setErrorMessage('')
      setIsValid(true)
    }
  }

  const validateEmail = (email: string) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i
    return regex.test(email)
  }

  const sendEmail = async () => {
    setIsSending(true)
    try {
      await emailjs.send(
        String(process.env.REACT_APP_EMAIL_SERVICE),
        String(process.env.REACT_APP_NOTIFY_ME_TEMPLATE),
        {
          message: email,
        },
        process.env.REACT_APP_EMAIL_API_KEY,
      )
      setIsValid(false)
    } catch (error) {
      console.error(error)
    }
    setIsSending(false)
  }

  const handleSubmit = () => {
    sendEmail()
    setEmail('')
    setIsSubmited(true)
  }

  useEffect(() => {
    function handleKeyPress(event: any) {
      if (isValid && event.key === 'Enter') {
        event.preventDefault()
        handleSubmit()
      }
    }
    document.addEventListener('keydown', handleKeyPress)
    return () => {
      document.removeEventListener('keydown', handleKeyPress)
    }
  }, [isValid])

  return (
    <Modal open={isOpen} onClose={handleClose}>
      {!isSubmited ? (
        <Box className={styles.modalBackground}>
          <Box className={styles.content}>
             <Box className={styles.contentBackground} />
            <Button onClick={handleClose} className={styles.closeButton}>
              <img src={CloseIcon} style={{}} alt='' />
            </Button>
            <Typography className={styles.title} id='parent-modal-title'>
              Get to know about our launch
            </Typography>
            <Typography id='parent-modal-title' className={styles.description}>
              Subscribe to our newsletter and we will send you a notification about the launch of
              our brand new site.
            </Typography>
            <Box className={styles.formWrapper}>
              <TextField
                onChange={handleChangeEmail}
                value={email}
                className={styles.email}
                placeholder='Your Mail'
                sx={{
                  fieldset: {
                    border: 'none !important',
                  },
                  '& input::placeholder': {
                    color: '#787777 !important',
                    fontFamily: 'DM Sans',
                    '@media (max-width: 600px)': {
                      fontSize: '12px !important',
                    },
                  },
                }}
              />
              <Box className={styles.errorMessageBox}>
                {!isValid && (
                  <Typography className={styles.errorMessage}>{errorMessage}</Typography>
                )}
              </Box>
              <Button
                className={isValid ? styles.submitBtn : styles.disableButton}
                onClick={() => {
                  handleSubmit()
                }}
                disabled={!isValid}>
                {isSending ? 'Sending...' : 'Submit'}
              </Button>
            </Box>
          </Box>
        </Box>
      ) : (
        <ConfirmationModal
          isOpen={isSubmited}
          handleOpen={closeSuccessModal}
          title='Thank you for subscription. You will receive newsletters shortly.'
        />
      )}
    </Modal>
  )
}

export default NotifyMeModal
