import { useState, useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { Box, Button, Typography } from '@mui/material'
import { Cluster, Connection, PublicKey, Transaction, clusterApiUrl } from '@solana/web3.js'
import { TokenStandard, fetchDigitalAssetWithTokenByMint, findTokenRecordPda, mplTokenMetadata, transferV1 } from '@metaplex-foundation/mpl-token-metadata'
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults'
import { bundlrUploader } from '@metaplex-foundation/umi-uploader-bundlr'
import { createNoopSigner, signerIdentity } from '@metaplex-foundation/umi'
import { fromWeb3JsPublicKey, toWeb3JsLegacyTransaction } from '@metaplex-foundation/umi-web3js-adapters'

import api from '../../services/axios.service'
import Modal from '../shared/Modal'
import Loader from '../Loader'
import xIcon from '../../assets/images/x-icon.svg'
import { useUserContext } from '../../context/UserContext'
import styles from './sendNftModal.module.scss'
import { magicInstance } from '../../services/magic.service'

export default function SendNftModal({
  open,
  toggleModal,
  data,
  updateNfts,
}: {
  open: boolean,
  toggleModal: (open: boolean) => void
  data: any,
  updateNfts: (nftAddress: string, targetAddress: string) => void
}) {
  const [targetAddress, setTargetAddress] = useState<string>('')
  const [isValidAddress, setIsValidAddress] = useState<boolean>(false)
  const { checkLoggedInStatus, isLoggedin, user } = useUserContext()
  const [loading, setLoading] = useState<boolean>(false)
  
  useEffect(() => {
    const checkValidAddress = async (addressToVerify: string) => {
      let isValid = false
      try {
        isValid = await PublicKey.isOnCurve(new PublicKey(addressToVerify));
      } catch (error) {
        console.log(error)
      } finally {
        return isValid
      }
    }

    const timeOutId = setTimeout(async () => {
      let isValid = false
      if (targetAddress) {
        isValid = await checkValidAddress(targetAddress)
      }
      setIsValidAddress(isValid)
    }, 500)

    return () => clearTimeout(timeOutId)
  }, [targetAddress])

  const handleClose = () => {
    toggleModal(false)
  }

  const sendNft = async () => {
    setLoading(true)
    console.log('user', user)

    if (isLoggedin) {
      // const recipientPubKey = new PublicKey(targetAddress);
      // const payer = new PublicKey(user.publicAddress);
      try {
        const solanaNetwork = process.env.REACT_APP_RPC_URL || clusterApiUrl((process.env.REACT_APP_SOLANA_NETWORK || 'devnet') as Cluster);
        const connection = new Connection(solanaNetwork);
        const hash = await connection.getRecentBlockhash();
        const mintPubkey = new PublicKey(data.address);
        const receiveAdress = new PublicKey(targetAddress);
        const payerAddress = new PublicKey(user.publicAddress)
  
        let transactionMagic = new Transaction({
          feePayer: payerAddress,
          recentBlockhash: hash.blockhash
        });

        const noopSigner = createNoopSigner(fromWeb3JsPublicKey(payerAddress))

        const umi = createUmi(solanaNetwork)
          .use(bundlrUploader())
          .use(mplTokenMetadata())
          .use(signerIdentity(noopSigner));

        const {
          token: { publicKey: tokenPublicKey }
        } = await fetchDigitalAssetWithTokenByMint(umi, fromWeb3JsPublicKey(mintPubkey))
        
        const tokenRecord = findTokenRecordPda(umi, {
          mint: fromWeb3JsPublicKey(mintPubkey),
          token: tokenPublicKey
        })

        const umiInstruction = await transferV1(umi, {
          mint: fromWeb3JsPublicKey(mintPubkey),
          authority: noopSigner,
          tokenOwner: fromWeb3JsPublicKey(payerAddress),
          destinationOwner: fromWeb3JsPublicKey(receiveAdress),
          tokenStandard: TokenStandard.ProgrammableNonFungible,
          tokenRecord
        }).buildWithLatestBlockhash(umi)

        console.log({
          mint: fromWeb3JsPublicKey(mintPubkey),
          authority: noopSigner,
          tokenOwner: fromWeb3JsPublicKey(payerAddress),
          destinationOwner: fromWeb3JsPublicKey(receiveAdress),
          tokenStandard: TokenStandard.ProgrammableNonFungible,
          tokenRecord
        })

        const web3Instruction = toWeb3JsLegacyTransaction(umiInstruction)

        transactionMagic.add(web3Instruction);

        console.log('tx created')
  
        const serializeConfig = {
          requireAllSignatures: true,
          verifySignatures: true,
        };
    
        const signedTransaction = await magicInstance.solana.signTransaction(transactionMagic, serializeConfig);
        console.log('raw tx signed')

        const tx = Transaction.from(signedTransaction.rawTransaction);
        const signature = await connection.sendRawTransaction(tx.serialize(), { skipPreflight: true });
        console.log('signature', signature);

        // update the nft list
        updateNfts(data.address, targetAddress)
        console.log('nft list updated')
      } catch (error) {
        console.log('error', error)
      } finally {
        setTargetAddress('')
        handleClose()
        setLoading(false)
      }
    } else {
      setTimeout(() => { setLoading(false) }, 3000)
    }
  }

  return (
    <div>
      {loading && (
        <Box className={styles.loaderBackground}>
          <Loader isFullScreen={false} background='inherit' loaderColor='#0a3455' />
          <Typography>Processing</Typography>
        </Box>
      )}
      <Modal open={open} onClose={handleClose}>
        <Box className={styles.mintLandContainer} sx={{ width: '400px' }}>
            <Button onClick={handleClose} className={styles.closeButton}>
              <img src={xIcon} alt='' />
            </Button>
            <Typography className={styles.modalTitle}>
               {data?.name}
            </Typography>

            <Box className={styles.inputsContainer}>
              <Box className={styles.landNameInputContainer}>
                <TextField
                  className={styles.landNameInput}
                  value={targetAddress}
                  onChange={(event) => {
                    setTargetAddress(event.target.value)
                  }}
                />
                {targetAddress.length === 0 && (
                  <Typography className={styles.placeholderDefault}>
                    Receipient's Solana Address<Typography className={styles.red}>*</Typography>
                  </Typography>
                )}
                <Typography sx={{ color:'red', fontSize: 'small', paddingLeft: '26px' }}>
                  {
                    targetAddress.length === 0 ?
                      'Address cannot be empty.'
                      : isValidAddress ?
                        'Address is valid.'
                        : 'Address is not valid.'
                  }
                </Typography>
              </Box>

            </Box>
            
            <Box className={styles.btnsSection}>
              <Button onClick={handleClose}>Cancel</Button>
              <Button
                className={`${!targetAddress ? styles.mintDisabledBtn : styles.mintActiveBtn}`}
                onClick={sendNft}
                disabled={!targetAddress || loading || !data}
              >
                Send
              </Button>
            </Box>
        </Box>
      </Modal>
    </div>
  )
}
