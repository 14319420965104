import { Typography } from '@mui/material'
import Roadline from '../../../../assets/images/roadline.svg'
import roadmapImg from '../../../../assets/images/roadmapImg.svg'
import StaightLine from '../../../../assets/images/staightRoadline1.svg'

import styles from './roadmap.module.scss'

export const pageRows = [
  {
    img: roadmapImg,
    darkImg: Roadline,
    staightLine: StaightLine,
    year: 2022,
    columns: [
      [
        {text: 'Q2 2022', bullet: false, bold: true},
        {text: 'Content Brainstorming', bullet: false, bold: false},
        {text: 'Engage in brainstorming sessions about content specifics and potential features.', bullet: true, bold: false},
        {text: 'Technical Research', bullet: false, bold: false},
        {text: 'Research the best technologies and platforms suitable for the desired features of EXGOLAND.', bullet: true, bold: false},
      ],
      [
        {text: 'Q3 2022', bullet: false, bold: true},
        {text: 'Further Research', bullet: false},
        {text: 'Delve deeper into technological solutions and potential challenges.', bullet: true},
        {text: 'Team & Partnerships', bullet: false},
        {text: 'Begin the process of building a dedicated team for EXGOLAND.', bullet: true},
        {text: 'Establish partnerships, especially co-founder collaborations.', bullet: true},
        {text: 'Recruitment & Onboarding', bullet: false},
        {text: 'Initiate the search and onboarding process for developers and other essential team members.', bullet: true}
      ],
      [
        {text: 'Q4 2022', bullet: false, bold: true},
        {text: 'Business Planning', bullet: false},
        {text: 'Draft, finalize, and approve the business plan for EXGOLAND.', bullet: true},
        {text: 'Development Kickoff', bullet: false},
        {text: 'Initiate the start of development based on research and planning.', bullet: true},
        {text: 'Community Building', bullet: false},
        {text: 'Launch a "coming soon" page to engage potential users and create anticipation.', bullet: true},
        {text: 'Start building a community on social media platforms.', bullet: true},
        {text: 'Begin the process of project promotion and marketing to attract attention and gather feedback.', bullet: true},
      ],
    ],
  },
  {
    img: roadmapImg,
    darkImg: Roadline,
    year: 2023,
    columns: [
      [
        {text: 'Q1 2023', bullet: false, bold: true},
        {text: 'Documentation', bullet: false},
        {text: 'Finalize the technical document or whitepaper, detailing the technical aspects and vision of EXGOLAND.', bullet: true},
        {text: 'Project Mapping', bullet: false},
        {text: 'Detail and finalize the roadmap for subsequent development stages.', bullet: true},
        {text: 'Smart Contract Creation', bullet: false},
        {text: 'Initiate the creation of smart contracts, foundational for the metaverse\'s transactional aspects.', bullet: true}
      ],
      [
        {text: 'Q2 2023', bullet: false, bold: true},
        {text: 'Metaverse Mappin', bullet: false},
        {text: 'Finalize the build of the metaverse map, laying out the virtual world\'s geography.', bullet: true},
        {text: 'Website Developmen', bullet: false},
        {text: 'Finalize the build of the official EXGOLAND website, offering information, updates, and access points to users.', bullet: true},
        {text: 'Custom Metaverse Solution', bullet: false},
        {text: 'Conduct research into custom metaverse solutions to identify unique offerings', bullet: true},
        {text: 'Begin outlining the structure and features of the custom metaverse.', bullet: true},
      ],
      [
        {text: 'Q3 2023', bullet: false, bold: true},
        {text: 'Minting Initiation', bullet: false},
        {text: 'Start the minting process for virtual assets within EXGOLAND.', bullet: true},
      ],
    ],
  },
  {
    img: roadmapImg,
    darkImg: Roadline,
    year: 2024,
    columns: [
      [
        {text: 'Q4 2023 - Q1 2024', bullet: false, bold: true},
        {text: 'Token creation', bullet: false},
        {text: 'Token listing on DEX.', bullet: true},
        {text: 'Token listing on CEX.', bullet: true},
        {text: 'Website enhancement', bullet: false},
        {text: 'AI Integration', bullet: false},
        {text: 'Initiate the embedding process for collaborative AI and virtual assistance.', bullet: true},
        {text: 'Begin the integration of Natural Language Processing and predictive analytics modules.', bullet: true},
        {text: 'Graphic Foundations', bullet: false},
        {text: 'Lay the groundwork for high-quality displays using Unreal Engine and Autodesk Maya.', bullet: true},
        {text: 'Begin basic 3D asset texturing with Substance Painter.', bullet: true},
      ],
      [
        {text: 'Q2 - Q3 2024', bullet: false, bold: true},
        {text: 'AI Enhancements', bullet: false},
        {text: 'Refine and expand the features of personalized virtual environments.', bullet: true},
        {text: 'Progress in duplicate avatar and user verification systems.', bullet: true},
        {text: 'Graphics and User Experience', bullet: false},
        {text: 'Detail-oriented development of graphics and interactive elements.', bullet: true},
        {text: 'Start device compatibility testing to ensure consistent user experience across platforms.', bullet: true},
      ],
    ]
  },
  {
    img: roadmapImg,
    darkImg: Roadline,
    year: 2025,
    columns: [
      [
        {text: 'Q4 2024 - Q1 2025', bullet: false, bold: true},
        {text: 'Feedback Loops', bullet: false},
        {text: 'Internal testing of all integrated features and functionalities.', bullet: true},
        {text: 'Collect feedback and iterate based on internal evaluations.', bullet: true},
        {text: 'Infrastructure Refinement', bullet: false},
        {text: 'Detailed work on the virtual economy infrastructure.', bullet: true},
        {text: 'Testing and refining transaction systems in the built-in marketplace.', bullet: true},
      ],
      [
        {text: 'Q2 - Q3 2025', bullet: false, bold: true},
        {text: 'Beta Release', bullet: false},
        {text: 'Launch a public beta version of EXGOLAND for select users.', bullet: true},
        {text: 'Monitor interactions, collect feedback, and identify areas for improvement.', bullet: true},
        {text: 'Promotion and Community Engagement', bullet: false},
        {text: 'Initiate marketing campaigns to create awareness.', bullet: true},
        {text: 'Engage with the community for feedback and expectations.', bullet: true},
      ],
    ]
  },
  {
    img: roadmapImg,
    darkImg: Roadline,
    year: 2026,
    columns: [
      [
        {text: 'Q4 2025 - Q2 2026', bullet: false, bold: true},
        {text: 'Final Iterations', bullet: false},
        {text: 'Make necessary adjustments based on feedback from the public beta.', bullet: true},
        {text: 'Focus on stability, security, and scalability of the platform.', bullet: true},
        {text: 'Final Testing & Promotions', bullet: false},
        {text: 'Conduct final rigorous testing rounds.', bullet: true},
        {text: 'Boost marketing campaigns leading up to the official launch.', bullet: true},
      ],
      [
        {text: 'Q3 -Q4 2026', bullet: false, bold: true},
        {text: 'Go Live', bullet: false},
        {text: 'Immediate post-launch monitoring to address any real-time issues.', bullet: true},
        {text: 'Officially launch EXGOLAND to the public.', bullet: true},
        {text: 'Plan for future updates based on user feedback and technological advancements.', bullet: true},
      ],
    ],
  },
]
