export const BundlerTimeout = 60000;

export const BundlrDevnetUrl = 'https://devnet.bundlr.network'
export const BundlrDevnetProviderUrl = 'https://api.devnet.solana.com';

export const NftCommitment = 'finalized'
export const CombinedLandsDescription = 'Combined Description'
export const CombinedLandsName = 'Combined Lands'

export const ImgaeType = 'image/jpeg'

export const landGasFee = 0.025
