export const industryTrendsTexts = [
  {
    title: 'Augmented & Virtual Reality in Gaming',
    text: 'Augmented Reality in gaming not only presents the AI-integrated characters but also provides an immersive gaming experience providing a platform to develop more immersive responsive, adaptive, and challenging games.',
  },
  {
    title: 'Metaverse Events',
    text: 'Enhance your global presence by hosting any possible event you can think of whether it could be concerts, NFT-based digital auctions or meetups in the virtual world formed through Metaverse.',
  },
  {
    title: 'Digital Twin',
    text: 'Take an opportunity to create the digital twin that can enhance the business operations and customer experience offering them a chance to interact with you through accurate replicas of things, places and even humans.',
  },
  {
    title: 'Cloud Computing',
    text: 'Stepping into the world of virtually will generate lots of data. Collecting, processing, storing, and analyzing them can be a tough task. Use cloud computing tools that can offer a more personalized and seamless user experience.',
  },
  {
    title: 'Virtual Art Galleries',
    text: 'With the enhanced popularity of digital art, NFT collectables have opened up gates for virtual representation. Offer an immersive space to artists and digital creators to represent their work to a worldwide audience through virtual art galleries.',
  },
]
