import { List, ListItem, Typography } from '@mui/material'
import { Box } from '@mui/system'
import autodeskMayaImg from '../../../../assets/images/autodeskMayaImg.svg'
import photonImg from '../../../../assets/images/photonImg.svg'
import substancePainterImg from '../../../../assets/images/substancePainterImg.svg'
import unityImg from '../../../../assets/images/unityImg.svg'
import userBenefit from '../../../../assets/images/userBenefit.png'
import styles from './userBenefits.module.scss'

export default function UserBenefits() {
  return (
    <Box className={styles.userBenefitsContainer}>
      <Box className={styles.userBenefits}>
        <Typography className={styles.userBenefitsTitle}>User Benefits</Typography>
        <Typography sx={{ maxWidth: '845px', fontFamily: 'DM Sans', fontSize: '16px' }}>
          Users will have various benefits for using the EXGOLAND metaverse, where they will be able to
          create their own revenue generation model through <span>EXGOLAND</span> with various
          communities present in the metaverse. They will be able to gain knowledge by various means
          of educational modules that will get implemented. Entertainment will be one section where
          users will be able to create and attend various events and participate into those events
          to get exposures through <span>EXGOLAND</span>.
          <br />
          <br />
          Users will have option to trade and earn. Where they will be able to trade their lands
          that they have purchased. Users will be able to buy, sell & rent out their lands. They
          will be able to purchase the land and create their own custom 3D environment by uploading
          3D models which they have created.
          <br />
          <br />
          <span>EXGOLAND</span> will also provide the users with Builder, where they can use the
          builder to build their own digital assets or buy ready developed assets that can be easily
          be installed, which can further be used in the <span>EXGOLAND.</span>
          <br />
          <br />
          <span>EXGOLAND</span> Gives users the ability to create games or even buy ready developed
          games that are ready to be installed directly and start having fun right away. EXGOLAND
          provides the metaverse experience in all formats like Mobile, Web & VR, so users to
          onboard themselves on the metaverse by ease.
        </Typography>
        <Box>
        {/* <Typography className={styles.technicalToolsUsedTitle}>Technical Tools Used</Typography>
        <List
          sx={{
            mt: '10px',
          }}>
          <ListItem className={styles.technicalToolsUsedListItem}>
            <span className={styles.listItemMarker} />
            <Typography>Technical Tools Used</Typography>
          </ListItem>
          <ListItem className={styles.technicalToolsUsedListItem}>
            <span className={styles.listItemMarker} />
            <Typography>Multiplayer game network Photon unity</Typography>
          </ListItem>
          <ListItem className={styles.technicalToolsUsedListItem}>
            <span className={styles.listItemMarker} />
            <Typography>3D environment using Autodesk Maya</Typography>
          </ListItem>
          <ListItem className={styles.technicalToolsUsedListItem}>
            <span className={styles.listItemMarker} />
            <Typography>Texturing using Substance Painter</Typography>
          </ListItem>
        </List> */}
        {/* <Box className={styles.technicalToolsImgs}>
          <Box component='img' src={unityImg} />
          <Box component='img' src={photonImg} />
          <Box component='img' src={autodeskMayaImg} />
          <Box className={styles.technicalToolsLastImg}>
            <Box component='img' src={substancePainterImg} />
          </Box>
        </Box> */}
      </Box>
      </Box>
      <div>
        <img src={userBenefit} alt="userBenefit" />
      </div>

    </Box>
  )
}
