import {
  TokenStandard,
  burnV1,
  createProgrammableNft,
  fetchAllDigitalAssetByOwner,
  fetchDigitalAssetWithTokenByMint,
  findMetadataPda,
  findTokenRecordPda,
  mplTokenMetadata,
  transferV1,
} from '@metaplex-foundation/mpl-token-metadata'
import {
  clusterApiUrl,
  Cluster,
  Transaction,
  PublicKey,
  Connection,
  SystemProgram,
  VersionedTransaction,
} from '@solana/web3.js'
import type { WalletContextState } from '@solana/wallet-adapter-react'
// import { nftStorageUploader } from '@metaplex-foundation/umi-uploader-nft-storage'
import { bundlrUploader } from '@metaplex-foundation/umi-uploader-bundlr'
// import { irysUploader, createIrysUploader } from '@metaplex-foundation/umi-uploader-irys'
import { walletAdapterIdentity } from '@metaplex-foundation/umi-signer-wallet-adapters'
import { createUmi } from '@metaplex-foundation/umi-bundle-defaults'
import {
  transferTokens,
  findAssociatedTokenPda,
  setComputeUnitLimit,
  fetchToken,
  setComputeUnitPrice,
} from '@metaplex-foundation/mpl-toolbox'
// import { PublicKey } from '@metaplex-foundation/umi-public-keys'
// import { createUmi } from '@metaplex-foundation/umi';
import base58 from 'bs58'
import {
  fromWeb3JsPublicKey,
  toWeb3JsLegacyTransaction,
  toWeb3JsTransaction,
} from '@metaplex-foundation/umi-web3js-adapters'
import {
  GenericFile,
  createGenericFileFromBrowserFile,
  createGenericFileFromJson,
  createNoopSigner,
  generateSigner,
  keypairIdentity,
  percentAmount,
  publicKey,
  signerIdentity,
  transactionBuilder,
} from '@metaplex-foundation/umi'
import { WebIrys } from '@irys/sdk'
import { PutObjectCommand } from '@aws-sdk/client-s3'
import { nanoid } from 'nanoid'

import {
  BundlerTimeout,
  BundlrDevnetProviderUrl,
  BundlrDevnetUrl,
  CombinedLandsDescription,
  CombinedLandsName,
  ImgaeType,
  NftCommitment,
} from '../utils/constants/metaplex'
import { convertDataUrlToFile } from '../utils/ConvertDataToImg'
import { LandDefaultDescription } from '../utils/constants/landDiscription'
import { INftData } from '../types/nft-map-types'
import { magicInstance } from './magic.service'
import { getRequiredCU, getPriorityFeeEstimate } from '../utils/umi'
import { getS3ObjectUrl, s3Client } from '../utils/awsS3/client'

const MAX_RETRIES = 5;
const RETRY_DELAY_MS = 500;
const solanaNetwork =
  process.env.REACT_APP_RPC_URL ||
  clusterApiUrl((process.env.REACT_APP_SOLANA_NETWORK || 'devnet') as Cluster)

// This is your timer. It will reject after some time.
async function timer(seconds: number) {
  await new Promise((_resolve, reject: any) =>
    setTimeout(() => reject('took too long'), seconds * 1000),
  )
}

// async function selfTerminating(job: Function) {
//   const result = await Promise.race([job(), timer(60 * 2)])

//   return result
// }

enum PriorityLevel {
	NONE, // 0th percentile
	LOW, // 25th percentile
	MEDIUM, // 50th percentile
	HIGH, // 75th percentile
	VERY_HIGH, // 95th percentile
  // labelled unsafe to prevent people using and draining their funds by accident
	UNSAFE_MAX, // 100th percentile 
	DEFAULT, // 50th percentile
}

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))
const IrysMultiplier = 2

type ImageFile = File | null

export type AttributesType = {
  trait_type: string
  value: string
}

interface IBundlrOptions {
  address?: string
  providerUrl?: string
  timeout: number
}

const irysNodeUrl = process.env.REACT_APP_SOLANA_NETWORK === 'devnet' ? 'https://devnet.irys.xyz' : 'https://node1.irys.xyz'
const usdcWallet = process.env.REACT_APP_USDC_WALLET_ADDRESS || 'HAYuiNbZb2RZNDcrr7mU3TbehBajQL8kHscWW8a45jp3'
const usdcTokenAddress = process.env.REACT_APP_USDC_TOKEN_ADDRESS || 'Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr'
const defaultMicrolamportsPrioirityFee = 3_000_000

const getUmiInstance = (wallet: WalletContextState) => {
  console.log('solanaNetwork', solanaNetwork)
  const umi = createUmi(solanaNetwork)
    .use(walletAdapterIdentity(wallet))
    // .use(irysUploader())
    .use(mplTokenMetadata())

  return umi
}

const getIrysClient = async (wallet: WalletContextState) => {
  // const irysClient = await createIrysUploader(umi, {
  //   providerUrl: solanaNetwork,
  //   // priceMultiplier: IrysMultiplier,
  // }).irys()
	const irysWallet = { rpcUrl: solanaNetwork, name: 'solana', provider: wallet };

  const webIrys = new WebIrys({
    url: irysNodeUrl,
    token: 'solana',
    wallet: irysWallet
  })

  await webIrys.ready()

  return webIrys
}

export const find = async (wallet: WalletContextState) => {
  const umi = getUmiInstance(wallet)

  const assets = await fetchAllDigitalAssetByOwner(umi, umi.identity.publicKey)

  return assets
}

export const fetchAllNftsByOwner = async (walletAddress: string) => {
  const solanaNetwork =
    process.env.REACT_APP_RPC_URL ||
    clusterApiUrl((process.env.REACT_APP_SOLANA_NETWORK || 'devnet') as Cluster)
  const umi = createUmi(solanaNetwork)

  const assets = await fetchAllDigitalAssetByOwner(umi, publicKey(walletAddress))

  return assets
}

export const fetchTokenOwner = async (wallet: WalletContextState, nftAddress: string) => {
  const umi = getUmiInstance(wallet)

  try {
    const {
      token: { owner },
    } = await fetchDigitalAssetWithTokenByMint(umi, publicKey(nftAddress))

    return owner?.toString()
  } catch (error) {
    console.log(error)
  }
}

// Size of Irys transaction header.
const HEADER_SIZE = 2_000

// Minimum file size for cost calculation.
const MINIMUM_SIZE = 80_000

const getUploadeFileBytes = (files: GenericFile[]) => {
  const bytes: number = files.reduce(
    (sum, file) => sum + HEADER_SIZE + Math.max(MINIMUM_SIZE, file.buffer.byteLength),
    0,
  )

  return bytes
}

export const createPnft = async (
  name: string,
  image: ImageFile,
  wallet: WalletContextState,
  royalty: string,
  attributes: AttributesType[],
) => {
  console.log('attributes', attributes)
  // return
  const screenShotUrl = localStorage.getItem('screenshotUrl')
  const landsImage = convertDataUrlToFile(screenShotUrl, 'Land\'s Image')
  if (!landsImage) throw new Error('Wrong image format')

  const collectionAddress = process.env.REACT_APP_COLLECTION_NFT_ADDRESS

  if (!collectionAddress || !royalty) return

  const usdcAmount = +(process.env.REACT_APP_INITIAL_SALE_USDC_PRICE || 299)
  const usdcDecimals = 1000000

  const umi = getUmiInstance(wallet)

  console.log('UMI instance created', umi)

  const source = findAssociatedTokenPda(umi, {
    mint: publicKey(usdcTokenAddress),
    owner: umi.identity.publicKey,
  })
  const destination = findAssociatedTokenPda(umi, {
    mint: publicKey(usdcTokenAddress),
    owner: publicKey(usdcWallet),
  })

  console.log(source.toString())
  console.log(destination.toString())
  
  try {
    console.log('Upload images')

    const imageFile = image && await createGenericFileFromBrowserFile(image)
    const metadataImageFile = await createGenericFileFromBrowserFile(landsImage)
    let jsonFile = createGenericFileFromJson({
      name,
      description: LandDefaultDescription,
      image: 'https://gateway.irys.xyz/EajfHxwLfmfxMgbdEHGMJOBeRkHB32k37e7J5PR2MVI',
      seller_fee_basis_points: parseFloat(royalty) * 100,
      attributes,
    })

    console.log('Image files created')

    let uri = null,
      imageUri: string | null = null
    // await selfTerminating(async () => {
    try {
      const irysClient = await getIrysClient(wallet)

      console.log('Irys client created', irysClient)

      let bytes = 0
      // if (!image) {
      bytes = getUploadeFileBytes([metadataImageFile, jsonFile])        
      // } else {
      //   bytes = getUploadeFileBytes([metadataImageFile, imageFile, jsonFile])
      // }
      console.log('total bytes', bytes)
      const atomicPrice = await irysClient.getPrice(bytes)
      const price = irysClient.utils.fromAtomic(atomicPrice).toString()
      console.log('upload price (atomic)', atomicPrice)
      console.log('upload price (sol)', price)

      // Get loaded balance in atomic units
      const atomicBalance = await irysClient.getLoadedBalance();
      const balance = irysClient.utils.fromAtomic(atomicBalance).toString()
      console.log('node balance (atomic)', atomicBalance)
      console.log('node balance (sol)', balance)

      if (price >= balance) {
        console.log('price is equal or bigger than balance, so extra funding is needed')
        const fundTx = await irysClient.fund(atomicPrice, IrysMultiplier)
        console.log(
          `Successfully funded ${irysClient.utils.fromAtomic(fundTx.quantity)} ${irysClient.token}`,
        )  
      } else {
        console.log('balance is bigger than price, so no need to fund for now')
      }

      // Upload the transaction
      const metadataImgRcpt = await irysClient.upload(Buffer.from(metadataImageFile.buffer))

      // const metadataImgRcpt = await tx1.upload()
      console.log(`Tx uploaded. https://gateway.irys.xyz/${metadataImgRcpt.id}`)
      const metadataImageUri = `https://gateway.irys.xyz/${metadataImgRcpt.id}`

      if (imageFile) {
        console.log('before upload to s3')
        const randomKey = nanoid()
        console.log('randomkey', randomKey)

        const s3Command = new PutObjectCommand({
          Bucket: process.env.REACT_APP_BUCKET,
          Key: randomKey,
          Body: Buffer.from(imageFile.buffer),
        });

        const response = await s3Client.send(s3Command);
        console.log(response);
        imageUri = getS3ObjectUrl(randomKey)
        console.log('after upload to s3', imageUri)
      }

      console.log('Upload JSONs')
      jsonFile = createGenericFileFromJson({
        name,
        description: LandDefaultDescription,
        image: metadataImageUri,
        seller_fee_basis_points: parseFloat(royalty) * 100,
        attributes,
      })

      // Upload the transaction
      const jsonRcpt = await irysClient.upload(Buffer.from(jsonFile.buffer))
      console.log(`JSON uploaded ==> https://gateway.irys.xyz/${jsonRcpt.id}`)

      uri = `https://gateway.irys.xyz/${jsonRcpt.id}`
    } catch (error) {
      console.log(error)
    }

    if (uri) {
      console.log('Create a programmable nft')

      const mint = generateSigner(umi)
      const tempTx = transactionBuilder()
        .add(setComputeUnitPrice(umi, {
          microLamports: defaultMicrolamportsPrioirityFee
        }))
        .add(setComputeUnitLimit(umi, { units: 600_000 }))
        .add(
          transferTokens(umi, {
            destination: destination,
            source: source,
            amount: usdcAmount * usdcDecimals,
          }),
        )
        .add(
          createProgrammableNft(umi, {
            mint,
            name,
            uri,
            sellerFeeBasisPoints: percentAmount(parseFloat(royalty)),
            collection: {
              verified: false,
              key: publicKey(collectionAddress),
            },
          }),
        )
      
      const txCU = await getRequiredCU(umi, await tempTx.buildWithLatestBlockhash(umi))
      console.log('calculated compute unit', txCU)

      for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
        try {
          console.log('attempt', attempt)
          let txBuilder = transactionBuilder()
            .add(setComputeUnitLimit(umi, { units: txCU }))
            .add(
              transferTokens(umi, {
                destination: destination,
                source: source,
                amount: usdcAmount * usdcDecimals,
              }),
            )
            .add(
              createProgrammableNft(umi, {
                mint,
                name,
                uri,
                sellerFeeBasisPoints: percentAmount(parseFloat(royalty)),
                collection: {
                  verified: false,
                  key: publicKey(collectionAddress),
                },
              }),
            )
          
          if (process.env.REACT_APP_SOLANA_NETWORK === 'mainnet-beta') {
            const tmpTx = toWeb3JsTransaction(
              await txBuilder.buildWithLatestBlockhash(umi)
            )
            const feeEstimate = await getPriorityFeeEstimate(PriorityLevel.VERY_HIGH, tmpTx)
            txBuilder = txBuilder.prepend(setComputeUnitPrice(umi, {
              microLamports: feeEstimate?.priorityFeeEstimate || defaultMicrolamportsPrioirityFee
            }))
          }
          txBuilder = await txBuilder.setLatestBlockhash(umi)

          const { signature } = await txBuilder.sendAndConfirm(umi, {
            confirm: { commitment: 'confirmed' },
            send: { maxRetries: 0 },
          })

          if (signature) {
            console.log(`Transaction successful! ${base58.encode(signature)}`)
            console.log('mint', mint.publicKey.toString())

            const nft = {
              uri,
              address: mint.publicKey.toString(),
              name: name,
              image: landsImage,
            }

            return { nft, uploadedImgUrl: imageUri, signature }
          }
          
        } catch (error) {
          if (error?.toString().includes('User rejected the request')) {
            break
          }
          
          console.log('Attempt', attempt + 1, 'failed to mint tokens:', error);
          if (attempt < MAX_RETRIES - 1) {
            console.log(`Retrying in ${RETRY_DELAY_MS / 1000} seconds...`);
            await sleep(RETRY_DELAY_MS);
          }
        }
      }

      throw new Error('All attempts for nft mint failed.');
    }
  } catch (error) {
    console.log(error)
  }
}

export const createPnftWithExgolandWallet = async (
  name: string,
  image: ImageFile,
  wallet: WalletContextState,
  royalty: string,
  attributes: AttributesType[],
  walletAddress: string,
) => {
  const screenShotUrl = localStorage.getItem('screenshotUrl')
  const landsImage = convertDataUrlToFile(screenShotUrl, 'Land\'s Image')
  if (!landsImage) throw new Error('Wrong image format')

  const collectionAddress = process.env.REACT_APP_COLLECTION_NFT_ADDRESS

  if (!collectionAddress || !royalty || !process.env.REACT_APP_USDC_WALLET_PK || !walletAddress)
    return

  const umi = getUmiInstance(wallet)

  const usdcKeypair = umi.eddsa.createKeypairFromSecretKey(
    base58.decode(process.env.REACT_APP_USDC_WALLET_PK),
  )
  umi.use(keypairIdentity(usdcKeypair))

  console.log('UMI instance created')

  try {
    console.log('Upload images')

    const imageFile = await createGenericFileFromBrowserFile(image || landsImage)
    const metadataImageFile = await createGenericFileFromBrowserFile(landsImage)
    const [metadataImageUri] = await umi.uploader.upload([metadataImageFile])
    const [imageUri] = await umi.uploader.upload([imageFile])

    console.log(imageUri)
    console.log('Upload JSONs')

    const uri = await umi.uploader.uploadJson({
      name,
      description: LandDefaultDescription,
      image: metadataImageUri,
      seller_fee_basis_points: parseFloat(royalty) * 100,
      attributes,
    })

    console.log('Create a programmable nft')

    const mint = generateSigner(umi)
    const { signature } = await createProgrammableNft(umi, {
      mint,
      name,
      uri,
      sellerFeeBasisPoints: percentAmount(parseFloat(royalty)),
      collection: {
        verified: false,
        key: publicKey(collectionAddress),
      },
    }).sendAndConfirm(umi, { send: { commitment: 'finalized' } })

    console.log('token', mint.publicKey)

    console.log('from', umi.identity.publicKey)
    console.log('to', walletAddress)
    const mintPublicKey = mint.publicKey
    console.log('mint.publicKey', mintPublicKey)

    console.log('sleep')

    await sleep(10000)

    console.log('after sleep')

    const {
      token: { publicKey: tokenPublicKey },
    } = await fetchDigitalAssetWithTokenByMint(umi, mint.publicKey)
    console.log('tokenPublicKey', tokenPublicKey)

    const tokenRecord = findTokenRecordPda(umi, {
      mint: mintPublicKey,
      token: tokenPublicKey,
    })

    console.log('tokenRecord', tokenRecord)

    await transferV1(umi, {
      mint: mint.publicKey,
      authority: umi.identity,
      tokenOwner: umi.identity.publicKey,
      destinationOwner: publicKey(walletAddress),
      tokenStandard: TokenStandard.ProgrammableNonFungible,
      tokenRecord,
    }).sendAndConfirm(umi)

    console.log('transfer done')

    const nft = {
      uri: uri,
      address: mint.publicKey.toString(),
      name: name,
      image: landsImage,
    }

    return { nft, uploadedImgUrl: imageUri, signature }
  } catch (error) {
    console.log(error)
  }
}

export const combineNfts = async (
  nfts: INftData[],
  wallet: WalletContextState,
  combinedLandName: string,
  royalty: string,
  attributes?: AttributesType[],
) => {
  try {
    const collectionAddress = process.env.REACT_APP_COLLECTION_NFT_ADDRESS

    if (!wallet.publicKey || !nfts || !combinedLandName || !royalty || !collectionAddress) return

    const screenShotUrl = localStorage.getItem('combinedScreenshotUrl')
    const landsImage = convertDataUrlToFile(screenShotUrl, 'Combine Land\'s Image')

    if (!landsImage) throw new Error('Wrong image format')

    const imagesUrls = nfts.map((el) => {
      return { uri: el.imageUrl, type: ImgaeType }
    })

    const metaDatasUrl = nfts.map((el) => {
      return { metaDataUrl: el.metaDataUrl }
    })

    const defaultImgNfts = nfts.filter((el) => {
      return !el.isDefaultImg
    })

    const irysClient = await getIrysClient(wallet)

    const imageFile = await createGenericFileFromBrowserFile(landsImage)

    let jsonFileTemplate = createGenericFileFromJson({
      name: combinedLandName,
      description: LandDefaultDescription,
      image: 'https://gateway.irys.xyz/EajfHxwLfmfxMgbdEHGMJOBeRkHB32k37e7J5PR2MVI',
      seller_fee_basis_points: parseFloat(royalty) * 100,
      properties: {
        files: imagesUrls,
        metaDatasUrl,
      },
      attributes,
    })
    const bytes = getUploadeFileBytes([imageFile, jsonFileTemplate])
    console.log('total bytes', bytes)
    const atomicPrice = await irysClient.getPrice(bytes)
    const price = irysClient.utils.fromAtomic(atomicPrice).toString()
    console.log('upload price (atomic)', atomicPrice)
    console.log('upload price (sol)', price)


    // Get loaded balance in atomic units
    const atomicBalance = await irysClient.getLoadedBalance();
    const balance = irysClient.utils.fromAtomic(atomicBalance).toString()
    console.log('node balance (atomic)', atomicBalance)
    console.log('node balance (sol)', balance)

    if (price >= balance) {
      console.log('price is equal or bigger than balance, so extra funding is needed')
      const fundTx = await irysClient.fund(atomicPrice, IrysMultiplier)
      console.log(
        `Successfully funded ${irysClient.utils.fromAtomic(fundTx.quantity)} ${irysClient.token}`,
      )  
    } else {
      console.log('balance is bigger than price, so no need to fund for now')
    }

    const receipt1 = await irysClient.upload(Buffer.from(imageFile.buffer))
    console.log(`Tx uploaded. https://gateway.irys.xyz/${receipt1.id}`)
    const metadataImageUri = `https://gateway.irys.xyz/${receipt1.id}`

    console.log('Upload JSONs')
    const jsonFile = createGenericFileFromJson({
      name: combinedLandName,
      description: LandDefaultDescription,
      image: metadataImageUri,
      seller_fee_basis_points: parseFloat(royalty) * 100,
      properties: {
        files: imagesUrls,
        metaDatasUrl,
      },
      attributes,
    })

    const receipt3 = await irysClient.upload(Buffer.from(jsonFile.buffer))
    console.log(`JSON uploaded ==> https://gateway.irys.xyz/${receipt3.id}`)
    const uri = `https://gateway.irys.xyz/${receipt3.id}`

    const umi = getUmiInstance(wallet)
    const mint = generateSigner(umi)

    let txBuilder = transactionBuilder()
      .add(
        createProgrammableNft(umi, {
          mint,
          name: combinedLandName,
          uri,
          sellerFeeBasisPoints: percentAmount(parseFloat(royalty)),
          collection: {
            verified: false,
            key: publicKey(collectionAddress),
          },
        })
      )

    const txCU = await getRequiredCU(umi, await txBuilder.buildWithLatestBlockhash(umi))
    console.log('calculated compute unit', txCU)

    let mintTxSignature = null
    for (let attempt = 0; attempt < MAX_RETRIES; attempt++) {
      console.log('attepnt: ', attempt)
      try {
        txBuilder = transactionBuilder()
          .add(setComputeUnitLimit(umi, { units: txCU + 20_000 }))
          .add(
            createProgrammableNft(umi, {
              mint,
              name: combinedLandName,
              uri,
              sellerFeeBasisPoints: percentAmount(parseFloat(royalty)),
              collection: {
                verified: false,
                key: publicKey(collectionAddress),
              },
            })
          )
        console.log('tx builder with compute unit limit')
        if (process.env.REACT_APP_SOLANA_NETWORK === 'mainnet-beta') {
          const tmpTx = toWeb3JsTransaction(
            await txBuilder.buildWithLatestBlockhash(umi)
          )
          const feeEstimate = await getPriorityFeeEstimate(PriorityLevel.VERY_HIGH, tmpTx)
          txBuilder = txBuilder.prepend(setComputeUnitPrice(umi, {
            microLamports: feeEstimate?.priorityFeeEstimate || defaultMicrolamportsPrioirityFee
          }))
        }
        txBuilder = await txBuilder.setLatestBlockhash(umi);
        console.log('tx builder built')
        const { signature } = await txBuilder.sendAndConfirm(umi, {
          confirm: { commitment: 'confirmed' },
          send: { maxRetries: 0 },
        })
        console.log('tx sent')
        if (signature) {
          console.log('success', `Mint successful! ${base58.encode(signature)}`)
          mintTxSignature = signature
          break
        }
      } catch (error) {
        console.log('Attempt', attempt + 1, 'failed to mint tokens:', error);
        if (attempt < MAX_RETRIES - 1) {
          console.log(`Retrying in ${RETRY_DELAY_MS / 1000} seconds...`);
          await sleep(RETRY_DELAY_MS);
        }
      }
    }

    if (!mintTxSignature) {
      throw new Error('All attempts for minting failed.');
    }


    // BUNR NFTS
    console.log('Burning nfts!')
    const collectionMetadataPda = findMetadataPda(umi, {
      mint: publicKey(collectionAddress),
    })

    for (const nft of nfts) {
      let txBuilder = transactionBuilder()
        .add(
          burnV1(umi, {
            mint: publicKey(nft.address),
            tokenStandard: TokenStandard.ProgrammableNonFungible,
            collectionMetadata: collectionMetadataPda,
          })
        )
      
      if (process.env.REACT_APP_SOLANA_NETWORK === 'mainnet-beta') {
        const tmpTx = toWeb3JsTransaction(
          await txBuilder.buildWithLatestBlockhash(umi)
        )
        const feeEstimate = await getPriorityFeeEstimate(PriorityLevel.VERY_HIGH, tmpTx)
        txBuilder = txBuilder.prepend(setComputeUnitPrice(umi, {
          microLamports: feeEstimate?.priorityFeeEstimate || defaultMicrolamportsPrioirityFee
        }))
      }

      txBuilder = await txBuilder.setLatestBlockhash(umi);

      const { signature } = await txBuilder.sendAndConfirm(umi, {
        confirm: { commitment: 'confirmed' },
      })

      sleep(500)

      console.log('success', `Burn successful! ${base58.encode(signature)}`)
    }

    console.log('Burn finished!')

    let isDefaultImg = true
    let uploadedImgUrl = undefined

    if (defaultImgNfts.length) {
      uploadedImgUrl = defaultImgNfts[defaultImgNfts.length - 1].imageUrl
      isDefaultImg = false
    }

    const nft = {
      uri,
      address: mint.publicKey.toString(),
      name: combinedLandName,
      image: metadataImageUri,
    }
    return { nft, uploadedImgUrl, isDefaultImg }
  } catch (error) {
    console.log(error)
  }
}

export const combineNftsWithExgolandWallet = async (
  nfts: INftData[],
  wallet: WalletContextState,
  combinedLandName: string,
  royalty: string,
  walletAddress: string,
  attributes?: AttributesType[],
) => {
  try {
    const collectionAddress = process.env.REACT_APP_COLLECTION_NFT_ADDRESS

    if (
      !walletAddress ||
      !nfts ||
      !combinedLandName ||
      !royalty ||
      !collectionAddress ||
      !process.env.REACT_APP_USDC_WALLET_PK
    )
      return

    const screenShotUrl = localStorage.getItem('combinedScreenshotUrl')
    const landsImage = convertDataUrlToFile(screenShotUrl, 'Combine Land\'s Image')

    if (!landsImage) throw new Error('Wrong image format')

    const imagesUrls = nfts.map((el) => {
      return { uri: el.imageUrl, type: ImgaeType }
    })

    const metaDatasUrl = nfts.map((el) => {
      return { metaDataUrl: el.metaDataUrl }
    })

    const defaultImgNfts = nfts.filter((el) => {
      return !el.isDefaultImg
    })

    const umi = getUmiInstance(wallet)
    const usdcKeypair = umi.eddsa.createKeypairFromSecretKey(
      base58.decode(process.env.REACT_APP_USDC_WALLET_PK),
    )
    umi.use(keypairIdentity(usdcKeypair))
    const imageFile = await createGenericFileFromBrowserFile(landsImage)
    const [metaplexFile] = await umi.uploader.upload([imageFile])

    const uri = await umi.uploader.uploadJson({
      name: combinedLandName,
      description: LandDefaultDescription,
      image: metaplexFile,
      seller_fee_basis_points: parseFloat(royalty) * 100,
      properties: {
        files: imagesUrls,
        metaDatasUrl,
      },
      attributes,
    })

    const mint = generateSigner(umi)
    await createProgrammableNft(umi, {
      mint,
      name: combinedLandName,
      uri,
      sellerFeeBasisPoints: percentAmount(parseFloat(royalty)),
      collection: {
        verified: false,
        key: publicKey(collectionAddress),
      },
    }).sendAndConfirm(umi)

    console.log('sleep')
    await sleep(16000)
    console.log('after sleep')

    const mintPublicKey = mint.publicKey
    const {
      token: { publicKey: tokenPublicKey },
    } = await fetchDigitalAssetWithTokenByMint(umi, mint.publicKey)
    console.log('tokenPublicKey', tokenPublicKey)

    const tokenRecord = findTokenRecordPda(umi, {
      mint: mintPublicKey,
      token: tokenPublicKey,
    })

    console.log('tokenRecord', tokenRecord)

    await transferV1(umi, {
      mint: mint.publicKey,
      authority: umi.identity,
      tokenOwner: umi.identity.publicKey,
      destinationOwner: publicKey(walletAddress),
      tokenStandard: TokenStandard.ProgrammableNonFungible,
      tokenRecord,
    }).sendAndConfirm(umi)

    console.log('transfer done')
    const noopSigner = createNoopSigner(fromWeb3JsPublicKey(new PublicKey(walletAddress)))
    umi.use(signerIdentity(noopSigner))

    const solanaNetwork =
      process.env.REACT_APP_RPC_URL ||
      clusterApiUrl((process.env.REACT_APP_SOLANA_NETWORK || 'devnet') as Cluster)
    const connection = new Connection(solanaNetwork)
    const hash = await connection.getRecentBlockhash()

    let transactionMagic = new Transaction({
      feePayer: new PublicKey(walletAddress),
      recentBlockhash: hash.blockhash,
    })

    const collectionMetadataPda = findMetadataPda(umi, {
      mint: publicKey(collectionAddress),
    })

    for (const nft of nfts) {
      const umiInstruction = await burnV1(umi, {
        mint: publicKey(nft.address),
        tokenStandard: TokenStandard.ProgrammableNonFungible,
        collectionMetadata: collectionMetadataPda,
      }).buildWithLatestBlockhash(umi)

      const web3Instruction = toWeb3JsLegacyTransaction(umiInstruction)
      transactionMagic.add(web3Instruction)
    }

    console.log('transaction created', transactionMagic)
    const serializeConfig = {
      requireAllSignatures: true,
      verifySignatures: true,
    }
    const signedTransaction = await magicInstance.solana.signTransaction(
      transactionMagic,
      serializeConfig,
    )
    console.log('raw tx signed')

    const tx = Transaction.from(signedTransaction.rawTransaction)
    const signature = await connection.sendRawTransaction(tx.serialize(), { skipPreflight: true })
    console.log('signature', signature)

    let isDefaultImg = true
    let uploadedImgUrl = undefined

    if (defaultImgNfts.length) {
      uploadedImgUrl = defaultImgNfts[defaultImgNfts.length - 1].imageUrl
      isDefaultImg = false
    }

    const nft = {
      uri,
      address: mint.publicKey.toString(),
      name: combinedLandName,
      image: metaplexFile,
    }
    return { nft, uploadedImgUrl, isDefaultImg }
  } catch (error) {
    console.log(error)
  }
}
