import SearchIcon from '@mui/icons-material/Search'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Link,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { useState } from 'react'
import hideIcon from '../../../../assets/images/circleMinus.svg'
import showIcon from '../../../../assets/images/circlePlus.svg'
import { GeneralFaqsItems } from '../../../../utils/constants/generalFaqs'

import styles from './generalsFaq.module.scss'

type GeneralFaqsItemType = {
  title: string,
  description: {
    text?: string[],
    linkText?: {
      text: string,
      title: string,
      link: string
    },
  }
}

const GeneralFaqs = () => {
  const [searchText, setSearchText] = useState('')
  const [textState, setTextState] = useState(new Array(GeneralFaqsItems.length).fill(false))
  const isMobile = useMediaQuery('(max-width: 600px)')

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchText(event.target.value)
  }

  const handleTextShow = (index: number) => {
    setTextState((pred) => pred.map((elem, i) => (i === index ? !elem : elem)))
  }

  return (
    <Box className={styles.wrapper}>
      <Box className={styles.faqHeader}>
        <Typography className={styles.title}>General FAQs</Typography>
        <Typography className={styles.about}>
          FAQ stands for “Frequently Asked Questions”.
          It refers to a collection of common queries and their corresponding answers.
          FAQs aim to provide you concise and helpful information, by addressing popular concerns asked by most EXGOLanders.
          We hope these FAQs answer all your questions and if not feel free to contact us by clicking “contact us” from the bottom of the page.
        </Typography>
      </Box>
      <Box className={styles.faqFooter}>
        <TextField
          className={styles.search}
          value={searchText}
          onChange={handleInputChange}
          placeholder='Search'
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {isMobile ? (
          <Box className={styles.faqs}>
            {GeneralFaqsItems.filter(({title}) => (title.toLowerCase()).indexOf(searchText.toLowerCase()) !== -1).map(({title, description} : GeneralFaqsItemType, index) => (
              <Accordion key={title} className={styles.accordion}>
                <AccordionSummary
                  onClick={() => {
                    handleTextShow(index)
                  }}
                  className={styles.accordionHeaderBox}
                  expandIcon={
                    <Box className={styles.icon}>
                      <img
                        className={styles.hideShowIcon}
                        src={textState[index] ? hideIcon : showIcon}
                        alt='open or close'
                      />
                    </Box>
                  }
                >
                  <Typography className={styles.accordionHeader}>{title}</Typography>
                </AccordionSummary>
                <Box>
                  <AccordionDetails className={styles.accordionItem}>
                    <Box className={styles.accordionText}>
                      {(
                      <Typography className={styles.text}>
                        {
                          Object.keys(description).map((key: string) => {
                            if (key === 'text') {
                              return description[key]?.map((desc, idx) => {
                                return <Typography key={idx} >{desc}</Typography>
                              })
                            } else if(key === 'linkText') {
                              return <Typography key='linkText'>{description[key]?.text} <Link href={description[key]?.link} target="_blank">{description[key]?.title}</Link></Typography>
                            }
                          })
                        }
                      </Typography>
                      
                    )}
                    </Box>
                  </AccordionDetails>
                </Box>
              </Accordion>
            ))}
          </Box>
        ) : (
          <>
            {GeneralFaqsItems.filter(({title}) => title.toLowerCase().indexOf(searchText.toLowerCase()) !== -1).map(({title, description}: GeneralFaqsItemType, index) => (
              <Box className={styles.desktopFaqs} key={index}>
                <Divider className={styles.divider} />
                <Box className={styles.faqWrapper}>
                  <Typography className={styles.titleBox}>
                    {title}
                    {textState[index] && (
                      <Typography className={styles.text}>
                        {
                          Object.keys(description).map((key: string) => {
                            if (key === 'text') {
                              return description[key]?.map((desc, idx) => {
                                return <Typography key={idx} >{desc}</Typography>
                              })
                            } else if(key === 'linkText') {
                              return <Typography key='linkText'>{description[key]?.text} <Link href={description[key]?.link} target="_blank">{description[key]?.title}</Link></Typography>
                            }
                          })
                        }
                      </Typography>
                      
                    )}
                  </Typography>
                  <Box>
                    <img
                      className={styles.hideShowIcon}
                      src={textState[index] ? hideIcon : showIcon}
                      alt='open or close'
                      onClick={() => {
                        handleTextShow(index)
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            ))}
          </>
        )}
      </Box>
    </Box>
  )
}
export default GeneralFaqs
