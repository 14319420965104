import React, { useState, useEffect } from 'react'
import { Box, Container } from '@mui/material'
import { useWallet } from '@solana/wallet-adapter-react'

import { fetchTokenOwner } from '../../services/metaplex.service'
import Navigation from '../../components/Navigation'
import PopUp from '../../components/Map/PopUp'
import Map from '../../components/Map'
import api from '../../services/axios.service'
import { LandDefaultDescription } from '../../utils/constants/landDiscription'
import { DataTypes, INftData, LandStatus, MintType } from '../../types/nft-map-types'

import styles from './buylandmap.module.scss'

function BuyLandMap() {
  const [landCoordinates, setLandCoordinates] = useState<number[]>([])
  const [nftInfo, setNftInfo] = useState<DataTypes>({
    name: '',
    price: process?.env?.REACT_APP_INITIAL_SALE_USDC_PRICE ? +process.env.REACT_APP_INITIAL_SALE_USDC_PRICE : 0,
    location: '',
    status: '',
    imgUrl: '',
    address: '',
    description: LandDefaultDescription,
    userId: {
      email: '',
      id: 0,
      username: '',
      walletAddress: '',
    },
  })

  const [nftData, setNftData] = useState<INftData[] | undefined>(undefined)
  const [mintType, setMintType] = useState<string>(MintType.NONE)

  const wallet = useWallet()

  useEffect(() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  useEffect(() => {
    const walletAddress = wallet.publicKey?.toString()

    const fetchNftData = async () => {
      try {
        const { data } = await api.get('/nfts/all')
        if (!data) throw new Error('Data not found')
        setNftData(data)
      } catch (error) {
        console.error(error)
      }
    }
    fetchNftData()
  }, [])

  const getLandsStatus = async (landCoordinatesStr: string) => {
    console.log('getLandsStatusInfo')
    if (nftData === undefined) return

    const nft = nftData.find((obj) => obj.coordinates.includes(landCoordinatesStr))
    console.log('nftData', nftData)
    const landLocation = landCoordinates.join(', ')
    nftInfo.userId = nft?.userId

    console.log('nft', nft)
    if (!nft) {
      return setNftInfo({
        ...nftInfo,
        status: LandStatus.NOT_MINTED,
        name: 'Land\'s Name',
        description: LandDefaultDescription,
        location: landLocation,
        imgUrl: '',
        address: '',
      })
    } else {
      const newNftInfo = {
        ...nftInfo,
        status: nft?.isOnSale ? LandStatus.ON_SALE : LandStatus.NOT_ON_SALE,
        name: nft.name,
        description: nft.description,
        location: landLocation,
        address: nft.address,
        imgUrl: nft.imageUrl,
      }

      try {
        const owner = await fetchTokenOwner(wallet, nft.address)
        const walletAddress = nftInfo.userId?.walletAddress
        console.log('owner', owner)
        console.log('walletAddress', nftInfo.userId?.walletAddress)
        let shouldUpdateDb = false
        if (owner && nftInfo.userId && owner !== nftInfo.userId?.walletAddress) {
          newNftInfo.userId = {
            ...nftInfo.userId,
            walletAddress: owner,
          }

          // update nftData
          for (const _nft of nftData) {
            if (nft.address == _nft.address && nft?.userId) {
              nft.userId.walletAddress = owner
            }
          }

          shouldUpdateDb = true
        }
        
        // update the db
        if (shouldUpdateDb) {
          try {
            console.log('calling the updateNftOwner api')
            await api.post('/nfts/updateNftOwner', {
              nftId: nft.id,
              owner: owner,
            })
            console.log('DB udpated too')
          } catch (error) {
            console.log(error)
          }
        }
      } catch (error) {
        console.log(error)
        // setNftInfo(newNftInfo)
      }
      console.log('setState')
      setNftInfo(newNftInfo)
    }
  }

  const setButtonType = async (landCoordinatesStr: string) => {
    if (nftData === undefined) return

    const walletAddress = wallet.publicKey?.toBase58()

    const nft = nftData.find((obj) => obj.coordinates.includes(landCoordinatesStr))
    if (nft && nft.userId) {
      if (nft.userId.walletAddress === walletAddress && !nft.isOnSale) {
        setMintType(MintType.SELL)
      } else if (nft.userId.walletAddress !== walletAddress && nft.isOnSale) {
        setMintType(MintType.BUY)
      } else {
        setMintType(MintType.NONE)
      }
    } else {
      setNftInfo({
        ...nftInfo,
        name: 'Land\'s Name',
      })
      setMintType(MintType.MINT)
    }
  }

  useEffect(() => {
    if (!landCoordinates.length) return
    const landCoordinatesStr = landCoordinates.join(', ')

    setButtonType(landCoordinatesStr)
    getLandsStatus(landCoordinatesStr)
  }, [landCoordinates])

  const handleMapLandSelect = (coordinates: { x: number; y: number }) => {
    // console.log(coordinates)
    setLandCoordinates([coordinates.x, coordinates.y])
  }

  const handleCloseSelectedLand = () => {
    setLandCoordinates([])
  }

  return (
    <Container className={styles.wrapper}>
      <Navigation type={'black'} />
      <Box className={styles.container}>
        <Box className={styles.bottomSection}>
          <Box className={styles.mapWrapper}>
            {landCoordinates.length !== 0 && (
              <Box className={styles.popUpModalContainer}>
                <PopUp
                  nftInfo={nftInfo}
                  nftData={nftData}
                  mintType={mintType}
                  handleClose={handleCloseSelectedLand}
                />
              </Box>
            )}
            <Map onLandSelect={handleMapLandSelect} nftData={nftData} />
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default React.memo(BuyLandMap)
