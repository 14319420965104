import { Typography } from '@mui/material'
import { Link } from 'react-router-dom'

import styles from '../drawer/contentDrawer.module.scss'

export const otherData = [
  ` (“we,” “our,” or “us”) is committed to protecting your privacy. This Privacy Policy
    explains how your personal information is collected, used, and disclosed by
    EXGOLAND. We have a dedicated Global Privacy Program that protects all the Personal
    Information we collect and helps ensure that Personal Information is handled
    properly worldwide.`,
  ` This Privacy Policy applies to our website, and its associated subdomains
    (collectively, our “Service”) alongside our application, EXGOLAND.`,
  `By accessing or using our Service, you signify that you have read, understood, and
   agree to our collection, storage, use, and disclosure of your personal information
   as described in this Privacy Policy and our Terms of Service.`,
]

export const policyData = {
  content: [
    {
      title: (
        <Typography>
          <b>1.</b> Definitions and key terms
        </Typography>
      ),
      firstSentence:
        'To help explain things as clearly as possible in this Privacy Policy, every time any of these terms are referenced, are strictly defined as:',
      body: (
        <Typography>
          <Typography className={styles.content}>
            <b>i.</b> Cookie: small amount of data generated by a website and saved by your web
            browser. It is used to identify your browser, provide analytics, remember information
            about you such as your language preference or login information.
            <br />
            <br />
          </Typography>
          <Typography className={styles.content}>
            <b>ii.</b> Company: when this policy mentions “Company,” “we,” “us,” or “our,” it refers
            to EXGOLAND, that is responsible for your information under this Privacy Policy.
            <br />
            <br />
          </Typography>
          <Typography className={styles.content}>
            <b>iii.</b> Customer: refers to the company, organization or person that signs up to use
            the EXGOLAND Service(s).
            <br />
            <br />
          </Typography>
          <Typography className={styles.content}>
            <b>iv.</b> Device: any internet connected device such as a phone, tablet, computer or
            any other device that can be used to visit EXGOLAND and use the services. <br />
            <br />
          </Typography>
          <Typography className={styles.content}>
            <b>v.</b> IP address: Every device connected to the Internet is assigned a number known
            as an Internet protocol (IP) address. These numbers are usually assigned in geographic
            blocks. An IP address can often be used to identify the location from which a device is
            connecting to the Internet.
            <br />
            <br />
          </Typography>
          <Typography className={styles.content}>
            <b>vi.</b> Personnel: refers to those individuals who are employed by EXGOLAND or are
            under contract to perform a service on behalf of one of the parties. <br />
            <br />
          </Typography>
          <Typography className={styles.content}>
            <b>vii.</b> Personal Data: any information that directly, indirectly, or in connection
            with other information — including a personal identification number — allows for the
            identification or identifiability of a natural person. <br />
            <br />
          </Typography>
          <Typography className={styles.content}>
            <b>viii.</b> Service: refers to the service provided by EXGOLAND as described in the
            Terms of Service and on this platform. <br />
            <br />
          </Typography>
          <Typography className={styles.content}>
            <b>ix.</b> Third-party service: refers to advertisers, contest sponsors, promotional and
            marketing partners, and others who provide our content or whose products or services we
            think may interest you.
            <br />
            <br />
          </Typography>
          <Typography className={styles.content}>
            <strong>x.</strong> Website: EXGOLAND site, which can be accessed via this URL:{' '}
            <a style={{ color: '#0563C1' }} href={'http://exgoland.io'}>
              {'http://exgoland.io'}{' '}
            </a>{' '}
            <br />
            <br />
            <b>xi.</b> You: a person or entity that is registered with EXGOLAND to use the Services.
            <br />
            <br />
            <b>xii.</b>{' '}
            <b>
              <i>Prohibitions</i>:
            </b>
            <br /> You must not misuse our Platform. You shall mot use any automated systems or
            software to extract data from this Platform for any purpose. You shall not commit or
            encourage a criminal offence, transmit or distribute a virus including but not limited
            to Tojan horse, worm, logic bomb or post any other material on the Platform which is
            malicious, technologically harmful, in breach of confidence or in any way offensive or
            obscene; hack into; corrupt data; cause annoyance to other users; infringe upon the
            rights of any other persons proprietary rights; send any unsolicied advertising or
            promotional material; or attempt to affect the performance or functionality of any
            computer facilities of or accessed throughout the Platform. In the event such breach
            occurs, EXGOLAND will report the breach to the relevant law enforcement authorities and
            appropriatelegal action will be taken.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>2.</b> Information collected
        </Typography>
      ),
      firstSentence:
        'We collect information from you when you visit our app, register on our site by creating an account on the application, subscribe to our newsletter etc',
      body: (
        <Typography>
          <Typography className={styles.content}>
            {' '}
            <b>i.</b> Name / Username
          </Typography>
          <Typography className={styles.content}>
            {' '}
            <b>ii.</b> Phone Numbers
          </Typography>
          <Typography className={styles.content}>
            <b>iii.</b> Email Addresses
          </Typography>
          <Typography className={styles.content}>
            {' '}
            <b>iv.</b> Mailing Addresses
          </Typography>
          <Typography className={styles.content}>
            {' '}
            <b>v.</b> Age
          </Typography>
          <Typography className={styles.content}>
            {' '}
            <b>vi.</b> Location
          </Typography>
          <Typography className={styles.content}>
            {' '}
            <b>vii.</b> Crypto wallet address
          </Typography>
          <br />
          <Typography className={styles.content}>
            We also collect information from mobile devices for a better user experience, although
            these features are completely optional.We collect and process information you give us
            when you create an account and upload content to the Platform.This includes technical
            and behavioural information about your use of the Platform.We also collect information
            about you if you download the app and interact with the Platform without creating an
            account.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>3.</b> How is the information used
        </Typography>
      ),
      firstSentence: 'Any of the information we collect from you may be used:',
      body: (
        <Typography>
          <Typography className={styles.content}>
            <b>i.</b> To personalize your experience (your information helps us to better respond to
            your individual needs)
          </Typography>
          <Typography className={styles.content}>
            <b>ii.</b> To improve our app (we continually strive to improve our app offerings based
            on the information and feedback we receive from you)
          </Typography>
          <Typography className={styles.content}>
            <b>iii.</b> To improve customer service (your information helps us to more effectively
            respond to your customer service requests and support needs)
          </Typography>
          <Typography className={styles.content}>
            {' '}
            <b>iv.</b> To process transactions
          </Typography>
          <Typography className={styles.content}>
            <b>v.</b> To administer a contest, promotion, survey or other site feature
          </Typography>
          <Typography className={styles.content}>
            <b>vi.</b> To send periodic emails
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>4. </b> When does EXGOLAND use end user information from third parties?
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            EXGOLAND will collect End User Data necessary to provide the EXGOLAND services to our
            customers. End users may voluntarily provide us with information they have made
            available on social media websites. If you provide us with any such information, we may
            collect publicly available information from the social media websites you have
            indicated. You can control how much of your information social media websites make
            public by visiting these websites and changing your privacy settings.`
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>5. </b> Customer information from third parties
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We receive some information from the third parties when you contact us. For example,
            when you submit your email address to us to show interest in becoming a EXGOLAND
            customer, we receive information from a third party that provides automated fraud
            detection services to EXGOLAND. We also occasionally collect information that is made
            publicly available on social media websites. You can control how much of your
            information social media websites make public by visiting these websites and changing
            your privacy settings.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>6.</b> Using your Email Address
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            By submitting your email address on this app, you agree to receive emails from us. You
            can cancel your participation in any of these email lists at any time by clicking on the
            opt-out link or other unsubscribe option that is included in the respective email. We
            only send emails to people who have authorized us to contact them, either directly, or
            through a third party. We do not send unsolicited commercial emails, because we hate
            spam as much as you do. By submitting your email address, you also agree to allow us to
            use your email address for customer audience targeting on sites like Facebook, where we
            display custom advertising to specific people who have opted-in to receive
            communications from us. Email addresses submitted only through the order processing page
            will be used for the sole purpose of sending you information and updates pertaining to
            your order. If, however, you have provided the same email to us through another method,
            we may use it for any of the purposes stated in this Policy. Note: If at any time you
            would like to unsubscribe from receiving future emails, we include detailed unsubscribe
            instructions at the bottom of each email.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>7. </b> Keep your information
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We keep your information only so long as we need it to provide EXGOLAND to you and
            fulfil the purposes described in this policy. This is also the case for anyone that we
            share your information with and who carries out services on our behalf. When we no
            longer need to use your information and there is no need for us to keep it to comply
            with our legal or regulatory obligations, we’ll either remove it from our systems or
            depersonalize it so that we can&apos;t identify you.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>8.</b> How Do We Protect Your Information?
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We implement a variety of security measures to maintain the safety of your personal
            information when you place an order or enter, submit, or access your personal
            information. We offer the use of a secure server. All supplied sensitive/credit
            information is transmitted via Secure Socket Layer (SSL) technology and then encrypted
            into our Payment gateway providers database only to be accessible by those authorized
            with special access rights to such systems, and are required to keep the information
            confidential. After a transaction, your private information (credit cards, social
            security numbers, financials, etc.) is never kept on file. We cannot, however, ensure or
            warrant the absolute security of any information you transmit to EXGOLAND or guarantee
            that your information on the Service may not be accessed, disclosed, altered, or
            destroyed by a breach of any of our physical, technical, or managerial safeguards.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>9. </b> Could my information be transferred to other countries?
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            EXGOLAND is incorporated in Dubai, UAE. Information collected via our website, through
            direct interactions with you, or from use of our help services may be transferred from
            time to time to our offices or personnel, or to third parties, located throughout the
            world, and may be viewed and hosted anywhere in the world, including countries that may
            not have laws of general applicability regulating the use and transfer of such data. To
            the fullest extent allowed by applicable law, by using any of the above, you voluntarily
            consent to the trans-border transfer and hosting of such information.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>10.</b> Is the information collected through the EXGOLAND Service secure?
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We take precautions to protect the security of your information. We have physical,
            electronic, and managerial procedures to help safeguard, prevent unauthorized access,
            maintain data security, and correctly use your information. However, neither people nor
            security systems are fool proof, including encryption systems. In addition, people can
            commit intentional crimes, make mistakes or fail to follow policies. Therefore, while we
            use reasonable efforts to protect your personal information, we cannot guarantee its
            absolute security. If applicable law imposes any non-disclaimable duty to protect your
            personal information, you agree that intentional misconduct will be the standards used
            to measure our compliance with that duty.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>11. </b> Can I update or correct my information?
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            The rights you have to request updates or corrections to the information EXGOLAND
            collects depend on your relationship with EXGOLAND. Personnel may update or correct
            their information as detailed in our internal company employment policies.
          </Typography>
          <Typography className={styles.content}>
            Customers have the right to request the restriction of certain uses and disclosures of
            personally identifiable information as follows. You can contact us in order to (1)
            update or correct your personally identifiable information, (2) change your preferences
            with respect to communications and other information you receive from us, or (3) delete
            the personally identifiable information maintained about you on our systems (subject to
            the following paragraph), by cancelling your account. Such updates, corrections, changes
            and deletions will have no effect on other information that we maintain, or information
            that we have provided to third parties in accordance with this Privacy Policy prior to
            such update, correction, change or deletion. To protect your privacy and security, we
            may take reasonable steps (such as requesting a unique password) to verify your identity
            before granting you profile access or making corrections. You are responsible for
            maintaining the secrecy of your unique password and account information at all times.
          </Typography>
          <Typography className={styles.content}>
            You should be aware that it is not technologically possible to remove each and every
            record of the information you have provided to us from our system. The need to back up
            our systems to protect information from inadvertent loss means that a copy of your
            information may exist in a non-erasable form that will be difficult or impossible for us
            to locate. Promptly after receiving your request, all personal information stored in
            databases we actively use, and other readily searchable media will be updated,
            corrected, changed or deleted, as appropriate, as soon as and to the extent reasonably
            and technically practicable.
          </Typography>
          <Typography className={styles.content}>
            If you are an end user and wish to update, delete, or receive any information we have
            about you, you may do so by contacting the organization of which you are a customer.
          </Typography>
        </Typography>
      ),
    },

    {
      title: (
        <Typography>
          <b>12. </b> Personnel
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            <b>If you are a EXGOLAND worker or applicant</b>, we collect information you voluntarily
            provide to us. We use the information collected for Human Resources purposes in order to
            administer benefits to workers and screen applicants.
            <br />
            <br /> You may contact us in order to (1) update or correct your information, (2) change
            your preferences with respect to communications and other information you receive from
            us, or (3) receive a record of the information we have relating to you. Such updates,
            corrections, changes and deletions will have no effect on other information that we
            maintain, or information that we have provided to third parties in accordance with this
            Privacy Policy prior to such update, correction, change or deletion.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>13. </b> Sale of Business
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We reserve the right to transfer information to a third party in the event of a sale,
            merger or other transfer of all or substantially all of the assets of EXGOLAND or any of
            its Corporate Affiliates (as defined herein), or that portion of EXGOLAND or any of its
            Corporate Affiliates to which the Service relates, or in the event that we discontinue
            our business or file a petition or have filed against us a petition in bankruptcy,
            reorganization or similar proceeding, provided that the third party agrees to adhere to
            the terms of this Privacy Policy.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>14. </b> Affiliates
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We may disclose information (including personal information) about you to our Corporate
            Affiliates. For purposes of this Privacy Policy, &apos;Corporate Affiliate&apos; means
            any person or entity which directly or indirectly controls, is controlled by or is under
            common control with EXGOLAND, whether by ownership or otherwise. Any information
            relating to you that we provide to our Corporate Affiliates will be treated by those
            Corporate Affiliates in accordance with the terms of this Privacy Policy.
          </Typography>
        </Typography>
      ),
    },

    {
      title: (
        <Typography>
          <b>15. </b> Governing Law
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            This Privacy Policy is governed by the laws of United Arab Emirates without regard to
            its conflict of laws provision. You consent to the exclusive jurisdiction of the courts
            in connection with any action or dispute arising between the parties under or in
            connection with this Privacy Policy except for those individuals who may have rights to
            make claims under Privacy Shield, or the Swiss-US framework.
          </Typography>
          <Typography className={styles.content}>
            The laws of UAE excluding its conflicts of law rules, shall govern this Agreement and
            your use of the app. Your use of the app may also be subject to other local, state,
            national, or international laws.
          </Typography>
          <Typography className={styles.content}>
            By using EXGOLAND or contacting us directly, you signify your acceptance of this Privacy
            Policy. If you do not agree to this Privacy Policy, you should not engage with our
            website, or use our services. Continued use of the website, direct engagement with us,
            or following the posting of changes to this Privacy Policy that do not significantly
            affect the use or disclosure of your personal information will mean that you accept
            those changes.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>16. </b> Your Consent
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We&apos;ve updated our Privacy Policy to provide you with complete transparency into
            what is being set when you visit our site and how it&apos;s being used. By using our
            app, registering an account, or making a purchase, you hereby consent to our Privacy
            Policy and agree to its terms.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>17.</b> Links to Other Websites
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            This Privacy Policy applies only to the Services. The Services may contain links to
            other websites not operated or controlled by EXGOLAND. We are not responsible for the
            content, accuracy or opinions expressed in such websites, and such websites are not
            investigated, monitored or checked for accuracy or completeness by us. Please remember
            that when you use a link to go from the Services to another website, our Privacy Policy
            is no longer in effect. Your browsing and interaction on any other website, including
            those that have a link on our platform, is subject to that website’s own rules and
            policies. Such third parties may use their own cookies or other methods to collect
            information about you.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>18. </b> Advertising
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            This app may contain third party advertisements and links to third party sites. EXGOLAND
            does not make any representation as to the accuracy or suitability of any of the
            information contained in those advertisements or sites and does not accept any
            responsibility or liability for the conduct or content of those advertisements and sites
            and the offerings made by the third parties.
          </Typography>
          <Typography className={styles.content}>
            Advertising keeps EXGOLAND and many of the websites and services you use free of charge.
            We work hard to make sure that ads are safe, unobtrusive, and as relevant as possible.{' '}
          </Typography>
          <Typography className={styles.content}>
            Third party advertisements and links to other sites where goods or services are
            advertised are not endorsements or recommendations by EXGOLAND of the third party sites,
            goods or services. EXGOLAND takes no responsibility for the content of any of the ads,
            promises made, or the quality/reliability of the products or services offered in all
            advertisements.
          </Typography>
        </Typography>
      ),
    },

    {
      title: (
        <Typography>
          <b>19. </b> Cookies for Advertising
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            These cookies collect information over time about your online activity on the app and
            other online services to make online advertisements more relevant and effective to you.
            This is known as interest-based advertising. They also perform functions like preventing
            the same ad from continuously reappearing and ensuring that ads are properly displayed
            for advertisers. Without cookies, it’s really hard for an advertiser to reach its
            audience, or to know how many ads were shown and how many clicks they received.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>20.</b> Cookies
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            EXGOLAND uses &apos;Cookies&apos; to identify the areas of our website that you have
            visited. A Cookie is a small piece of data stored on your computer or mobile device by
            your web browser. We use Cookies to enhance the performance and functionality of our app
            but are non-essential to their use. However, without these cookies, certain
            functionality like videos may become unavailable or you would be required to enter your
            login details every time you visit the app as we would not be able to remember that you
            had logged in previously. Most web browsers can be set to disable the use of Cookies.
            However, if you disable Cookies, you may not be able to access functionality on our
            website correctly or at all. We never place Personally Identifiable Information in
            Cookies.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>21. </b> Blocking and disabling cookies and similar technologies
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            Wherever you&apos;re located you may also set your browser to block cookies and similar
            technologies, but this action may block our essential cookies and prevent our website
            from functioning properly, and you may not be able to fully utilize all of its features
            and services. You should also be aware that you may also lose some saved information
            (e.g. saved login details, site preferences) if you block cookies on your browser.
            Different browsers make different controls available to you. Disabling a cookie or
            category of cookie does not delete the cookie from your browser, you will need to do
            this A yourself from within your browser, you should visit your browser&apos;s help menu
            for more information.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>22. </b> Remarketing Services
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We use remarketing services. What Is Remarketing? In digital marketing, remarketing (or
            retargeting) is the practice of serving ads across the internet to people who have
            already visited your website. It allows your company to seem like they&apos;re
            “following” people around the internet by serving ads on the websites and platforms they
            use most.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>23. </b> Payment Details
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            In respect to any credit card or other payment processing details you have provided us,
            we commit that this confidential information will be stored in the most secure manner
            possible.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>24. </b> Children&apos;s Privacy
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We collect information from kids under the age of 13 just to better our services. If You
            are a parent or guardian and You are aware that Your child has provided Us with Personal
            Data without your permission, please contact Us. If we become aware that we have
            collected Personal Data from anyone under the age of 13 without verification of parental
            consent, we take steps to remove that information from Our servers.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>25. </b> Changes To Our Privacy Policy
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We may change our Service and policies, and we may need to make changes to this Privacy
            Policy so that they accurately reflect our Service and policies. Unless otherwise
            required by law, we will notify you (for example, through our Service) before we make
            changes to this Privacy Policy and give you an opportunity to review them before they go
            into effect. Then, if you continue to use the Service, you will be bound by the updated
            Privacy Policy. If you do not want to agree to this or any updated Privacy Policy, you
            can delete your account.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>26. </b> Third-Party Services
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We may display, include or make available third-party content (including data,
            information, applications and other products services) or provide links to third-party
            websites or services (&apos;Third - Party Services&apos;).
          </Typography>
          <Typography className={styles.content}>
            You acknowledge and agree that EXGOLAND shall not be responsible for any Third-Party
            Services, including their accuracy, completeness, timeliness, validity, copyright
            compliance, legality, decency, quality or any other aspect thereof. EXGOLAND does not
            assume and shall not have any liability or responsibility to you or any other person or
            entity for any Third-Party Services.{' '}
          </Typography>
          <Typography className={styles.content}>
            Third-Party Services and links thereto are provided solely as a convenience to you and
            you access and use them entirely at your own risk and subject to such third
            parties&apos; terms and conditions.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>27. </b> Facebook Pixel
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            Facebook pixel is an analytics tool that allows you to measure the effectiveness of your
            advertising by understanding the actions people take on your website. You can use the
            pixel to: Make sure your ads are shown to the right people. Facebook pixel may collect
            information from your device when you use the service. Facebook pixel collects
            information that is held in accordance with its Privacy Policy
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>28. </b> Tracking Technologies
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            <b>i. Google Maps API</b>
          </Typography>
          <Typography className={styles.content}>
            Google Maps API is a robust tool that can be used to create a custom map, a searchable
            map, check-in functions, display live data synching with location, plan routes, or
            create a mashup just to name a few.
          </Typography>
          <Typography className={styles.content}>
            Google Maps API may collect information from You and from Your Device for security
            purposes.
          </Typography>
          <Typography className={styles.content}>
            Google Maps API collects information that is held in accordance with its Privacy Policy.
          </Typography>
          <Typography className={styles.content}>
            <b>ii. Cookies</b>
          </Typography>
          <Typography className={styles.content}>
            We use Cookies to enhance the performance and functionality of our app but are
            non-essential to their use. However, without these cookies, certain functionality like
            videos may become unavailable or you would be required to enter your login details every
            time you visit the app as we would not be able to remember that you had logged in
            previously.
          </Typography>
          <Typography className={styles.content}>
            <b>iii. Local Storage</b>
          </Typography>
          <Typography className={styles.content}>
            Local Storage sometimes known as DOM storage, provides web apps with methods and
            protocols for storing client-side data. Web storage supports persistent data storage,
            similar to cookies but with a greatly enhanced capacity and no information stored in the
            HTTP request header.{' '}
          </Typography>
          <Typography className={styles.content}>
            <b>iv. Sessions</b>
          </Typography>
          <Typography className={styles.content}>
            EXGOLAND uses &apos;Sessions&apos; to identify the areas of our website that you have
            visited. A Session is a small piece of data stored on your computer or mobile device by
            your web browser.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>29. </b> Information about General Data Protection Regulation (GDPR)
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We may be collecting and using information from you if you are from the European
            Economic Area (EEA), and in this section of our Privacy Policy we are going to explain
            exactly how and why is this data collected, and how we maintain this data under
            protection from being replicated or used in the wrong way.
          </Typography>
          <Typography className={styles.content}>
            <i>What is GDPR?</i>
          </Typography>{' '}
          <Typography className={styles.content}>
            GDPR is an EU-wide privacy and data protection law that regulates how EU residents&apos;
            data is protected by companies and enhances the control the EU residents have, over
            their personal data. The GDPR is relevant to any globally operating company and not just
            the EU-based businesses and EU residents. Our customers’ data is important irrespective
            of where they are located, which is why we have implemented GDPR controls as our
            baseline standard for all our operations worldwide.
          </Typography>
          <Typography className={styles.content}>
            <i>What is personal data?</i>
          </Typography>
          <Typography className={styles.content}>
            Any data that relates to an identifiable or identified individual. GDPR covers a broad
            spectrum of information that could be used on its own, or in combination with other
            pieces of information, to identify a person. Personal data extends beyond a person’s
            name or email address. Some examples include financial information, political opinions,
            genetic data, biometric data, IP addresses, physical address, sexual orientation, and
            ethnicity. The Data Protection Principles include requirements such as:
          </Typography>
          <Typography className={styles.content}>
            The Data Protection Principles include requirements such as:
          </Typography>
          <Typography className={styles.content}>
            <b>i.</b> Personal data collected must be processed in a fair, legal, and transparent
            way and should only be used in a way that a person would reasonably expect. &apos;
          </Typography>
          <Typography className={styles.content}>
            <b>ii.</b> Personal data should only be collected to fulfil a specific purpose and it
            should only be used for that purpose. Organizations must specify why they need the
            personal data when they collect it.
          </Typography>
          <Typography className={styles.content}>
            <b>iii.</b> Personal data should be held no longer than necessary to fulfil its purpose.
            iv. People covered by the GDPR have the right to access their own personal data. They
            can also request a copy of their data, and that their data be updated, deleted,
            restricted, or moved to another organization.
          </Typography>
          <Typography className={styles.content}>
            <b>iv.</b> People covered by the GDPR have the right to access their own personal data.
            They can also request a copy of their data, and that their data be updated, deleted,
            restricted, or moved to another organization.
          </Typography>
          <Typography className={styles.content}>
            <i>Why is GDPR important?</i>{' '}
          </Typography>
          <Typography className={styles.content}>
            GDPR adds some new requirements regarding how companies should protect individuals&apos;
            personal data that they collect and process. It also raises the stakes for compliance by
            increasing enforcement and imposing greater fines for breach. Beyond these facts
            it&apos;s simply the right thing to do. At Help Scout we strongly believe that your data
            privacy is very important and we already have solid security and privacy practices in
            place that go beyond the requirements of this new regulation.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>30. </b> Individual Data Subject&apos;s Rights - Data Access, Portability and Deletion
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            We are committed to helping our customers meet the data subject rights requirements of
            GDPR. EXGOLAND processes or stores all personal data in fully vetted, DPA compliant
            vendors. We do store all conversation and personal data for up to 6 years unless your
            account is deleted. In which case, we dispose of all data in accordance with our Terms
            of Service and Privacy Policy, but we will not hold it longer than 60 days.
          </Typography>
          <Typography className={styles.content}>
            We are aware that if you are working with EU customers, you need to be able to provide
            them with the ability to access, update, retrieve and remove personal data. We got you!
            We&apos;ve been set up as self-service from the start and have always given you access
            to your data and your customers data. Our customer support team is here for you to
            answer any questions you might have about working with the API.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>31. </b> California Residents
        </Typography>
      ),
      body: (
        <Typography>
          <Typography className={styles.content}>
            The California Consumer Privacy Act (CCPA) requires us to disclose categories of
            Personal Information we collect and how we use it, the categories of sources from whom
            we collect Personal Information, and the third parties with whom we share it, which we
            have explained above.
          </Typography>
          <Typography className={styles.content}>
            We are also required to communicate information about rights California residents have
            under California law. You may exercise the following rights:
          </Typography>
          <Typography className={styles.content}>
            <b>i.</b> Right to Know and Access. You may submit a verifiable request for information
            regarding the: (1) categories of Personal Information we collect, use, or share; (2)
            purposes for which categories of Personal Information are collected or used by us; (3)
            categories of sources from which we collect Personal Information; and (4) specific
            pieces of Personal Information we have collected about you.
          </Typography>
          <Typography className={styles.content}>
            <b>ii.</b> Right to Equal Service. We will not discriminate against you if you exercise
            your privacy rights.
          </Typography>
          <Typography className={styles.content}>
            <b>iii.</b> Right to Delete. You may submit a verifiable request to close your account
            and we will delete Personal Information about you that we have collected.
          </Typography>
          <Typography className={styles.content}>
            <b>iv.</b> Request that a business that sells a consumer&apos;s personal data, not sell
            the consumer&apos;s personal data.
          </Typography>
          <Typography className={styles.content}>
            If you make a request, we have one (1) month to respond to you. If you would like to
            exercise any of these rights, please contact us.
          </Typography>
          <Typography className={styles.content}>
            We do not sell the Personal Information of our users.
          </Typography>
          <Typography className={styles.content}>
            For more information about these rights, please contact us at{' '}
            <Link to={'mailto:support@exgoland.io'} target='_blank' style={{ color: '#2B7BCA' }}>
              support@exgoland.io
            </Link>
            .
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>32. </b> California Online Privacy Protection Act (CalOPPA)
        </Typography>
      ),

      body: (
        <Typography>
          <Typography className={styles.content}>
            CalOPPA requires us to disclose categories of Personal Information we collect and how we
            use it, the categories of sources from whom we collect Personal Information, and the
            third parties with whom we share it, which we have explained above.
          </Typography>
          <Typography className={styles.content}>
            CalOPPA users have the following rights:
          </Typography>
          <Typography className={styles.content}>
            <b>i.</b> Right to Know and Access. You may submit a verifiable request for information
            regarding the: (1) categories of Personal Information we collect, use, or share; (2)
            purposes for which categories of Personal Information are collected or used by us; (3)
            categories of sources from which we collect Personal Information; and (4) specific
            pieces of Personal Information we have collected about you.
          </Typography>
          <Typography className={styles.content}>
            <b>ii.</b> Right to Equal Service. We will not discriminate against you if you exercise
            your privacy rights.
          </Typography>
          <Typography className={styles.content}>
            <b>iii.</b> Right to Delete. You may submit a verifiable request to close your account
            and we will delete Personal Information about you that we have collected.
          </Typography>
          <Typography className={styles.content}>
            <b>iv.</b> Right to request that a business that sells a consumer&apos;s personal data,
            not sell the consumer&apos;s personal data.
          </Typography>
          <Typography className={styles.content}>
            Right to request that a business that sells a consumer&apos;s personal data, not sell
            the consumer&apos;s personal data.
          </Typography>
          <Typography className={styles.content}>
            If you make a request, we have one month to respond to you. If you would like to
            exercise any of these rights, please contact us. We do not sell the Personal Information
            of our users.
          </Typography>
          <Typography className={styles.content}>
            For more information about these rights, please contact us.
          </Typography>
        </Typography>
      ),
    },
    {
      title: (
        <Typography>
          <b>33. </b> Contact Us
        </Typography>
      ),
      body: (
        <Typography className={styles.content}>
          In the event that you wish to make a complaint or request concerning your Personal
          Information or our privacy practices, or have questions, concerns or comments about this
          Privacy Policy, please contact us at{' '}
          <Link to={'mailto:support@exgoland.io'} target='_blank' style={{ color: '#2B7BCA' }}>
            support@exgoland.io
          </Link>{' '}
          in the first instance by filling out our contact form and we will endeavour to deal with
          your request as soon as possible.
        </Typography>
      ),
    },
  ],
}
