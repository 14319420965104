import discordIcon from '../../assets/images/discordIcon.svg'
import instagramIcon from '../../assets/images/instagramIcon.svg'
import linkedinIcon from '../../assets/images/linkedinIcon.svg'
import mediumIcon from '../../assets/images/mediumIcon.svg'
import faceBookIcon from '../../assets/images/shareModal/Facebook.svg'
import telegramIcon from '../../assets/images/telegramIcon.svg'
import twitterIcon from '../../assets/images/twitterIcon.svg'
import twitterXIcon from '../../assets/images/twitterXIcon.svg'
import youtubeIcon from '../../assets/images/youtubeIcon.svg'
import tiktokIcon from '../../assets/images/tiktokIcon.svg'
import redditIcon from '../../assets/images/redditIcon.svg'

import mediumSvg from '../../assets/images/socialIcons/medium.svg'
import discordSvg from '../../assets/images/socialIcons/discord.svg'
import facebookSvg from '../../assets/images/socialIcons/facebook.svg'
import instagramSvg from '../../assets/images/socialIcons/instagram.svg'
import linkedinSvg from '../../assets/images/socialIcons/linkedin.svg'
import redditSvg from '../../assets/images/socialIcons/reddit.svg'
import telegramSvg from '../../assets/images/socialIcons/telegram.svg'
import tiktokSvg from '../../assets/images/socialIcons/tiktok.svg'
import twitterSvg from '../../assets/images/socialIcons/twitter.svg'
import youtubeSvg from '../../assets/images/socialIcons/youtube.svg'


export const footerNavItems = [
  {
    name: 'About Us',
    path: '/about',
  },
  {
    name: 'FAQ',
    path: '/faq',
  },
  {
    name: 'Terms of Service',
    path: '/terms-and-conditions',
  },
  {
    name: 'Affiliate Page',
    path: '/affiliate-page',
  },
  {
    name: 'Contact Us',
    path: '/contact',
  },
  {
    name: 'Privacy Policy',
    path: '/policy',
  },
  {
    name: 'Blog/News',
    path: '/blog',
  },
]

export const footerNavItemsMobile = [
  {
    name: 'Terms of Service',
    path: '/terms-and-conditions',
  },
  {
    name: 'FAQ',
    path: '/faq',
  },
  {
    name: 'Privacy Policy',
    path: '/policy',
  },
  {
    name: 'Affiliate Page',
    path: '/affiliate-page',
  },
  {
    name: 'Blog/News',
    path: '/blog',
  },
  {
    name: 'About Us',
    path: '/about',
  },

  {
    name: 'Contact Us',
    path: '/contact',
  },
]

export const footerSocialIcons = [
  {
    name: 'twitter',
    src: twitterSvg,
    url: 'https://twitter.com/exgoland_io',
  },
  {
    name: 'telegram',
    src: telegramSvg,
    url: 'https://t.me/EXGOLAND',
  },
  {
    name: 'instagram',
    src: instagramSvg,
    url: 'https://www.instagram.com/exgoland/',
  },
  {
    name: 'medium',
    src: mediumSvg,
    url: 'https://exgoland.medium.com/',
  },
  {
    name: 'linkedin',
    src: linkedinSvg,
    url: 'https://www.linkedin.com/company/exgoland/?viewAsMember=true',
  },
  {
    name: 'redditIcon',
    src: redditSvg,
    url: 'https://www.reddit.com/u/exgoland/s/KgkuHaNXUf',
  },
  {
    name: 'facebookIcon',
    src: facebookSvg,
    url: 'https://facebook.com/EXGOLAND',
  },
  {
    name: 'tikTok',
    src: tiktokSvg,
    url: 'https://www.tiktok.com/@exgoland?_t=8jUQWSuWwjv&_r=1',
  },
  {
    name: 'youtube',
    src: youtubeSvg,
    url: 'https://www.youtube.com/@exgoland',
  },
  {
    name: 'discord',
    src: discordSvg,
    url: 'https://discord.com/invite/E5gZTh7d8K',
  },
]
