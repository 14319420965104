import { FC, useContext, useState } from 'react'
import { Box, Modal, Typography } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { useWallet } from '@solana/wallet-adapter-react'
import WalletButtons from '../Top/walletButtons'
// import styles from '../Top/top.module.scss'
import { WalletConnectContext } from '../../providers/web3'
import { useUserContext } from '../../context/UserContext'
import { Button, TextField } from '@material-ui/core'

// import styles from '../MarketplaceModal/marketplacemodal.module.scss'
import styles from './magicWalletPopup.module.scss'
import xIcon from '../../assets/images/x-icon.svg'
import { magicInstance } from '../../services/magic.service'

export const MagicWalletPopup: FC = () => {
  const { magicModalOpen, openMagicModal, closeMagicModal } = useContext(WalletConnectContext)
  const { checkLoggedInStatus, isLoggedin, user } = useUserContext()
  // const [modalOpened, setModalOpened] = useState(true)
  const wallet = useWallet()
  // const { isLoggedin } = useUserContext()
  const [email, setEmail] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)

  const loginWallet = async () => {
    setLoading(true)
    try {
      await magicInstance.auth.loginWithEmailOTP({ email });
      closeMagicModal()
      await checkLoggedInStatus()
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Box className={styles.relative}>
      <Modal open={magicModalOpen} onClose={closeMagicModal}>
        <Box className={styles.mintLandContainer} sx={{ width: '400px' }}>
          <Button onClick={closeMagicModal} className={styles.closeButton}>
            <img src={xIcon} alt='' />
          </Button>
          <Typography className={styles.modalTitle} style={{ fontSize: '24px !important', fontWeight: '700 !important' }}>
            Please sign up or login
          </Typography>

          <Box className={styles.inputsContainer}>
            <Box className={styles.landNameInputContainer}>
              <TextField
                className={styles.landNameInput}
                type='email'
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value)
                }}
              />
              {email.length === 0 && (
                <Typography className={styles.placeholderDefault}>
                  Email<Typography className={styles.red}>*</Typography>
                </Typography>
              )}
            </Box>

            {/* <Box className={styles.line} sx={{ margin: '20px' }} /> */}
          </Box>
          
          <Box className={styles.btnsSection}>
            <Button onClick={closeMagicModal}>Cancel</Button>
            <Button
              className={`${!email ? styles.mintDisabledBtn : styles.mintActiveBtn}`}
              onClick={loginWallet}
              disabled={!email || loading}>
              Submit
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
