import { Box, Typography, useMediaQuery } from '@mui/material'
import { Container } from '@mui/system'
import ContentDrawer from '../drawer'
import Navigation from '../Navigation'
import Footer from '../Footer'
import { otherData, policyData } from './types'
import policy from '../../assets/images/policy.svg'
import under from '../../assets/images/under.svg'

import styles from './privacy.module.scss'

export default function Policy() {
  const isMobile = useMediaQuery('(max-width: 700px)')

  return (
    <Container className={styles.wrapper}>
      <Navigation type={'black'} />
      <Box className={styles.container}>
        <Container className={styles.titleWrapper}>
          <Typography variant='h3' className={styles.title}>
            Privacy Policy
          </Typography>
        </Container>
        <Box className={styles.mainContainer}>
          <Container className={styles.start}>
            <Container className={styles.textWrapper}>
              {otherData.map((el, index) => (
                <Typography variant='body2' className={styles.inline} key={index}>
                  {index === 0 && (
                    <span style={{ fontWeight: 700 }}>EXGOLAND TECHNOLOGY L.L.C</span>
                  )}
                  {el}
                </Typography>
              ))}
            </Container>
            <Container className={styles.icon}>
              <img src={policy} alt='policy' className={styles.mainIcon} />
              <img src={under} alt='under' className={styles.underIcon} />
            </Container>
          </Container>
          <Box className={styles.wrapperData}>
            <ContentDrawer key={String(isMobile)} data={policyData} isTerms={false} isMobile={isMobile} />
          </Box>
        </Box>
      </Box>
      <Footer />
    </Container>
  )
}
