import { Box, Typography, useMediaQuery } from '@material-ui/core'
import introBigImg from '../../../../assets/images/introBigImg.svg'
import introMobileBigImg from '../../../../assets/images/introMobileBigImg.svg'
import introductionSmallImg from '../../../../assets/images/cyberman.png'

import styles from './introduction.module.scss'

export default function IntroductionPage() {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <Box className={styles.pageContainer}>
      <Box className={styles.leftSection}>
        <Typography className={styles.pageTitle}>Introduction</Typography>
        <Typography className={styles.pageDescription}>
          <span>EXGOLAND</span> Technology L.L.C is a web 3.0 company that was originally established in Dubai,
           UAE on September 2022. It is the first mover in the MENA region. At{' '}
          <span>EXGOLAND</span> echnology, we are creating the future by
          developing several solutions that assist users worldwide from metaverse to marketplace,
          gaming and digital assets. We introduce to you <span>EXGOLAND</span>: A Distinctive Virtual Reality Experience Powered by
          Artificial Intelligence. <span>EXGOLAND</span> is a unique metaverse powered by AI that has so many unique features that
          solves many problems that users are facing now. Our value proposition is “The true feeling and real living of the metaverse
          with more gaining than paying”.
        </Typography>
        {/* <Typography className={styles.pageTitle}>Outline of the Vision</Typography>
        <Typography className={styles.pageDescription}>
          The vision for <strong>EXGOLAND</strong> is to be one of the leaders in the metaverse industry.
          Our mission is to provide entrepreneurs and users a universe of opportunities to build, own and operate businesses.
        </Typography> */}
        {isMobile ? <img src={introMobileBigImg} /> : <img width={'100%'} src={introductionSmallImg} />}
      </Box>
      <Box className={styles.bottomImg}>
        {isMobile ? <img src={introductionSmallImg} /> : <img src={introBigImg} />}
      </Box>
    </Box>
  )
}
