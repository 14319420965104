export interface INftData {
  address: string
  coordinates: string[]
  id?: number
  isOnSale?: boolean
  metaDataUrl: string
  name: string
  description: string
  imageUrl: string
  isDefaultImg: boolean
  userId?: UserId
  price?: number
}
export interface UserId {
  email: string
  id: number
  username: string
  walletAddress: string
}

export interface ISquareCoord {
  size: number
  coordinates: number[][]
}

export enum MintType {
  BUY = 'Buy',
  SELL = 'Sell',
  MINT = 'Mint',
  NONE = 'None',
}

export interface DataTypes {
  name: string
  price: number
  location: string
  status: string
  imgUrl: string
  address: string
  description: string
  userId?: UserId
  coordinates?: string[]
}

export enum LandStatus {
  NOT_MINTED = 'Not Minted',
  ON_SALE = 'On Sale',
  NOT_ON_SALE = 'Not On Sale',
}
