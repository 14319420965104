import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  IconButton,
  InputBase,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
  useMediaQuery,
  SelectChangeEvent,
  Alert
} from '@mui/material'
import React, { useEffect, useRef, useState, ChangeEvent, useContext } from 'react'
import Highlighter from 'react-highlight-words'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import arrowIcon from '../../assets/images/arrowIcon.svg'
import chart from '../../assets/images/chart.svg'
import mapPin from '../../assets/images/mapPin.svg'
import menuwrapper from '../../assets/images/menuwrapper.svg'
import activeMenuwrapper from '../../assets/images/activeMenuwrapper.svg'
import xicon from '../../assets/images/x-icon.svg'
import Footer from '../../components/Footer'
import MarketplaceModal from '../../components/MarketplaceModal'
import Navigation from '../../components/Navigation'
import NumberInput from '../../components/NumberInput'
import Card from './Card'
import Accordion from '../../components/Accordion'
import SuccessMintModal from './MintSuccessModal'
import { ButtonText, ModalTypes } from './types'
import { useWallet } from '@solana/wallet-adapter-react'
import { filteredNftData, INftsData } from '../../services/nft.service'
import { PagginationOptions } from '../../utils/constants/paginationOptions'
import { ObserverUtil } from '../../utils/observerUtil'
import ChooseMarketplaceModal from '../../components/ChooseMarketplaceModal'
import Loader from '../../components/Loader'
import { Path } from '../../components/Map/types'
import { getMapPaths, getNewNotMintedLands, getRects } from '../../utils/getNotMintedLands'
import api from '../../services/axios.service'
import { WalletConnectContext } from '../../providers/web3'
import { getSolPrice } from '../../services/helper.service'
import { WalletPopup } from '../../components/walletPopup/walletPopup'
import { INftData } from '../../types/nft-map-types'
import styles from './marketplace.module.scss'

export enum LandsEnum {
  AVTOMINT = 'Available to mint',
  AVTOBUY = 'Available to buy',
  ALLLANDS = 'Minted Lands',
  MYLANDS = 'My lands',
}

export enum SortEnum {
  NEWEST = 'Newest',
  OLDEST = 'Oldest',
}

export enum LandsQueryEnum {
  ALLLANDS = '1',
  MYLANDS = '2',
  AVTOBUY = '3',
  AVTOMINT = '4',
}

export interface IQueryParams {
  onSale?: number
  owner?: string
  page: number
  limit: number
  sortBy: string
  priceFrom?: number
  priceTo?: number
  name?: string
}

export type ChangeType = {
  isLastItem: boolean
  isLandsLoading: boolean
  inputValue: string
  filteredData: INftsData[]
  currentSearchResults: INftsData[]
  fillterOption: LandsEnum | SortEnum | string
  currentPage: number
  notMintedLandLastElementIndex: number
  isLastNotMintedElement: boolean
  sortBy: SortEnum | string
  minPrice: string
  maxPrice: string
}

type CollectionListing = {
  tokenAddress: string,
  tokenMint: string,
  price: number,
  pdaAddress: string
}

const priceWarningText = 'The prices shown for the lands that are on sale on Magic Eden is only the Listing Price.\n Make sure to go to Magic Eden to check the final price.'

function Marketplace() {
  const [canvasPaths, setCanvasPaths] = useState<Path[]>([])
  const [nftData, setNftData] = useState<INftData[]>([])
  const [showMore, setShowMore] = useState<boolean>(false)
  const [chooseModal, chooseModalToggle] = useState<boolean>(false)
  const [showFilter, setShowFilter] = useState<boolean>(false)
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false)
  const [filteredBy, setFilteredBy] = useState<string>('')
  const [searchByLocationResult, setSearchByLocationResult] = useState<INftsData[]>([])
  const [searchByNameResult, setSearchByNameResult] = useState<INftsData[]>([])
  const [availableToMint, setAvailableToMint] = useState<INftsData[]>([])
  const [availableToBuy, setAvailableToBuy] = useState<INftsData[]>([])
  const [currency, setCurrency] = React.useState<string>('USD')
  const [isLastElementVisible, setIsLastElementVisible] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [successMint, setSuccessMint] = useState<boolean>(false)
  const [isNewLandsLoading, setIsNewLandsLoading] = useState<boolean>(false)
  const [solPrice, setSOLPrice] = useState(0)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [previousScrollPosition, setPreviousScrollPosition] = useState(0)
  const [totalNftNames, setTotalNftNames] = useState<[]>()

  const [change, setChange] = useState<ChangeType>({
    isLastItem: false,
    isLandsLoading: false,
    inputValue: '',
    filteredData: [],
    currentSearchResults: [],
    fillterOption: LandsEnum.ALLLANDS,
    currentPage: 1,
    notMintedLandLastElementIndex: 0,
    isLastNotMintedElement: false,
    minPrice: '',
    maxPrice: '',
    sortBy: SortEnum.NEWEST,
  })

  const [landInfo, setLandInfo] = useState<ModalTypes>({
    location: '',
    price: '',
  })

  const inputElement = useRef()
  const containerRef = useRef(null)
  const lastElementRef = useRef(null)
  const wallet = useWallet()
  const { openModal } = useContext(WalletConnectContext)

  const isMobile = useMediaQuery('(max-width: 600px)')
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    const fetchNftData = async () => {
      try {
        const { data } = await api.get('/nfts/all')
        if (!data) throw new Error('Data not found')
        if (data && data.length > 0) {
          setTotalNftNames(data.map((datum: { name: string }) => datum.name))
        }
      } catch (error) {
        console.error(error)
      }
    }

    fetchNftData()
  }, [])

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search)

    const queryData = {
      filterOption: queryParams.get('filterOption') || '',
      minPrice: queryParams.get('minPrice') || '',
      maxPrice: queryParams.get('maxPrice') || '',
      sortBy: queryParams.get('sortBy') || '',
      search: queryParams.get('search') || '',
    }

    const updateChange = { ...change }

    switch (queryData.filterOption) {
      case LandsQueryEnum.ALLLANDS:
        updateChange.fillterOption = LandsEnum.ALLLANDS
        break
      case LandsQueryEnum.AVTOBUY:
        updateChange.fillterOption = LandsEnum.AVTOBUY
        break
      case LandsQueryEnum.AVTOMINT:
        updateChange.fillterOption = LandsEnum.AVTOMINT
        break
      case LandsQueryEnum.MYLANDS:
        updateChange.fillterOption = LandsEnum.MYLANDS
    }

    if (queryData.sortBy) {
      updateChange.sortBy = queryData.sortBy
    }

    if (queryData.search !== '') {
      updateChange.inputValue = queryData.search
    }

    if (queryData.minPrice && queryData.maxPrice) {
      updateChange.minPrice = queryData.minPrice
      updateChange.maxPrice = queryData.maxPrice
    }
    setChange(updateChange)
  }, [])

  const fetchNftData = async () => {
    try {
      const { data } = await api.get('/nfts/all')
      if (!data) throw new Error('Data not found')
      setNftData(data)
    } catch (error) {
      console.error(error)
    }
  }

  const getCanvasPaths = async (svgData: string) => {
    const rects = await getRects(svgData)
    const paths = getMapPaths(rects, nftData)
    setCanvasPaths(paths)
  }

  const fetchMapSVG = async () => {
    const response = await fetch('/newest_map.svg')
    const dataText = await response.text()
    if (dataText) {
      getCanvasPaths(dataText)
    }
  }

  useEffect(() => {
    fetchNftData()
    fetchMapSVG()
    getSolPrice().then((price) => {
      setSOLPrice(price)
    })
  }, [])

  useEffect(() => {
    if (change.fillterOption === LandsEnum.AVTOMINT) {
      setAvailableToMint(change.filteredData.filter((nft) => !nft.metaDataUrl))
    } else if(change.fillterOption === LandsEnum.AVTOBUY) {
      const getListings = async () => {
        const { data: listings = []} = await api.get('/nfts/collection/listings')
        if (listings && listings.length > 0) {
          const filteredData = change.filteredData.filter(({ address }) => {
            if (listings.find((list: CollectionListing) => list.tokenMint === address)) {
              return true
            }
            return false
          })
          console.log('filteredData', filteredData)
          setAvailableToBuy(filteredData)
        } else {
          setAvailableToBuy([])
        }
        
        console.log('change.filteredData', change.filteredData)
        console.log('listings', listings)
      };

      getListings()
    } else {

    }

  }, [change.filteredData])

  useEffect(() => {
    getFilteredNftsData(1)
  }, [change.sortBy])

  const handleMinPriceChange = (event: string) => {
    setChange((prev) => ({ ...prev, minPrice: event }))
  }

  const handleMaxPriceChange = (event: string) => {
    setChange((prev) => ({ ...prev, maxPrice: event }))
  }

  const loadNewNotMintedLands = () => {
    if (change.filteredData.length === 0) {
      setChange((prev) => ({ ...prev, isLandsLoading: true }))
    }

    const newLands = getNewNotMintedLands(canvasPaths, change.notMintedLandLastElementIndex)
    if (newLands.lands) {
      setChange((prev) => ({ ...prev, filteredData: [...change.filteredData, ...newLands.lands] }))
      setChange((prev) => ({ ...prev, notMintedLandLastElementIndex: newLands.lastElementIndex }))
      if (newLands.lastElementIndex === true) {
        setChange((prev) => ({ ...prev, isLastNotMintedElement: true }))
      }
    }
    setIsNewLandsLoading(false)
    setChange((prev) => ({ ...prev, isLandsLoading: false }))
  }

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY)
    }

    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    if (
      change.fillterOption === LandsEnum.AVTOMINT &&
      scrollPosition > previousScrollPosition + 600
    ) {
      if (change.notMintedLandLastElementIndex > 0) {
        setIsNewLandsLoading(true)
      }
      loadNewNotMintedLands()
      setPreviousScrollPosition(scrollPosition)
    }  if (
      change.fillterOption !== LandsEnum.AVTOMINT &&
      scrollPosition > previousScrollPosition + 600
    ) {
      
      if (change.notMintedLandLastElementIndex > 0) {
        setIsNewLandsLoading(true)
      }
      getFilteredNftsData()
      setPreviousScrollPosition(scrollPosition)
    }
  }, [scrollPosition])

  const handleCurrencyChange = (event: SelectChangeEvent<typeof currency>) => {
    const {
      target: { value },
    } = event
    setCurrency(value)
  }

  const getFilteredNftsData = async (page: number | undefined = undefined, name: string | undefined = undefined) => {
    if (change.filteredData.length === 0) {
      setChange((prev) => ({ ...prev, isLandsLoading: true }))
    }
    try {
      const params: IQueryParams = {
        page: page ? page : change.currentPage,
        limit: PagginationOptions.ITEMS_PER_PAGE,
        sortBy: change.sortBy,
        priceFrom: Number(getQueryParam('minPrice')) ?? undefined,
        priceTo: Number(getQueryParam('maxPrice')) ?? undefined,
        name: name ?? getQueryParam('search') ?? undefined,
      }

      switch (change.fillterOption) {
        case LandsEnum.MYLANDS:
          params.owner = wallet.publicKey?.toBase58()
          break
        // case LandsEnum.AVTOBUY:
        //   params.onSale = 1
        //   break
      }
      switch (change.sortBy) {
        case SortEnum.NEWEST:
          params.sortBy = SortEnum.NEWEST
          break
        case SortEnum.OLDEST:
          params.sortBy = SortEnum.OLDEST
          break
      }
      const nftData = await filteredNftData(params)
      setChange((prev) => ({ ...prev, isLandsLoading: false }))
      setIsNewLandsLoading(false)
      if (!nftData) throw new Error('Cant get nfts data')

      if (page) {
        setChange((prev) => ({ ...prev, filteredData: [ ...nftData] }))
      }else {
        setChange((prev) => ({ ...prev, filteredData: [...change.filteredData, ...nftData] }))
      }
      setChange((prev) => ({ ...prev, currentPage: change.currentPage + 1 }))

      if (nftData.length) {
        window.scrollTo({
          top: window.scrollY - Math.round(window.scrollY * 0.2),
          behavior: 'smooth',
        })
      }
    } catch (error) {
      setChange((prev) => ({ ...prev, isLandsLoading: false }))
      setIsNewLandsLoading(false)
    }
  }

  useEffect(() => {
    if (change.fillterOption === LandsEnum.AVTOMINT) {
      loadNewNotMintedLands()
    } else {
      getFilteredNftsData()
    }
  }, [change.fillterOption])

  useEffect(() => {
    addQueryParam('filterOption', LandsQueryEnum.ALLLANDS)
    setChange((prev) => ({ ...prev, fillterOption: 'Minted Lands' }))
  }, [])

  const handleChange = (event: SelectChangeEvent) => {
    const filteredValue = event.target.value

    switch (event.target.value) {
      case LandsEnum.ALLLANDS:
        addQueryParam('filterOption', LandsQueryEnum.ALLLANDS)
        break
      case LandsEnum.AVTOBUY:
        addQueryParam('filterOption', LandsQueryEnum.AVTOBUY)
        break
      case LandsEnum.AVTOMINT:
        addQueryParam('filterOption', LandsQueryEnum.AVTOMINT)
        break
      case LandsEnum.MYLANDS:
        addQueryParam('filterOption', LandsQueryEnum.MYLANDS)
        break
    }

    removeQueryParam('search')
    setChange((prev) => ({
      ...prev,
      fillterOption: filteredValue,
      currentPage: 1,
      filteredData: [],
      isLandsLoading: false,
      inputValue: '',
    }))
    setPreviousScrollPosition(0)
  }

  const handleSortBy = (event: SelectChangeEvent) => {
    addQueryParam('sortBy', event.target.value)
    setChange((prev) => ({ ...prev, sortBy: event.target.value, currentPage: 1 }))
    setPreviousScrollPosition(0)
  }

  const addQueryParam = (key: string, value: string) => {
    const queryParams = new URLSearchParams(location.search)

    queryParams.set(key, value)
    navigate(`?${queryParams.toString()}`, { replace: true })
  }

  const removeQueryParam = (key: string) => {
    const queryParams = new URLSearchParams(location.search)

    queryParams.delete(key)
    navigate(`?${queryParams.toString()}`, { replace: true })
  }

  const getQueryParam = (key: string) => {
    const queryParams = new URLSearchParams(location.search)

    return queryParams.get(key)
  }

  const handleSearchFilter = (value: string) => {
    setChange((prev) => ({ ...prev, inputValue: value.trim(), currentPage: 1 }))
    addQueryParam('search', change.inputValue)
    setShowSearchBar(false)
    getFilteredNftsData(1, value)
  }

  useEffect(() => {
    if (change.inputValue.length === 0) {
      setChange((prev) => ({ ...prev, currentSearchResults: [] }))
    }
  }, [change.inputValue])

  const removeSearchValue = (): void => {
    removeQueryParam('search')
    setChange((prev) => ({ ...prev, inputValue: '', currentPage: 1 }))
    getFilteredNftsData(1, '')
  }

  const handleFilterByPrice = () => {
    if (change.minPrice !== '') {
      addQueryParam('minPrice', change.minPrice)
    } else {
      removeQueryParam('minPrice')
    }
    if (change.maxPrice !== '') {
      addQueryParam('maxPrice', change.maxPrice)
    } else {
      removeQueryParam('maxPrice')
    }

    getFilteredNftsData()
  }

  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const searchedValue = event.target.value
    if (searchedValue === '') {
      setSearchByLocationResult([])
      setShowSearchBar(false)
    }
    if (searchedValue.length !== 0 && searchedValue !== ' ') {
      setSearchByLocationResult(
        change.filteredData.filter((item) =>
          item.coordinates.some((coordinate) => coordinate.includes(searchedValue)),
        ),
      )
      setSearchByNameResult(
        change.filteredData.filter(
          (item) => item.name.includes(searchedValue) || item.name === searchedValue,
        ),
      )
      setShowSearchBar(true)
    }
    setChange((prev) => ({ ...prev, inputValue: searchedValue }))
  }

  const handlePressEnter = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSearchFilter(change.inputValue)
    }
  }

  const handleOpen = (buttonType: string, nft_address: string) => {
    if (buttonType === ButtonText.SELL || buttonType === ButtonText.BUY) {
      chooseModalToggle(true)
    } else if (buttonType === ButtonText.DISABLED) {
      openModal(true)
    }
    if (buttonType === ButtonText.MINT) return setOpen(true)
  }

  return (
    <>
      <WalletPopup />
      <Container className={styles.wrapper}>
        <Navigation type={'black'} />
        <ChooseMarketplaceModal open={chooseModal} toggleModal={chooseModalToggle} />
        <MarketplaceModal
          setMintSuccess={setSuccessMint}
          landInfo={landInfo}
          open={open}
          toggleModal={setOpen}
          totalNftNames={totalNftNames}
        />
        <SuccessMintModal isOpen={successMint} onClose={() => setSuccessMint(false)} />
        <Box className={styles.container}>
          <Box className={styles.leftSide}>
            <Typography className={styles.title}>Marketplace</Typography>
            <Box className={styles.searchBar}>
              <Box className={styles.searchWrapper}>
                <Box className={styles.search}>
                  <IconButton className={styles.searchicon}>
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    className={styles.input}
                    placeholder='Search'
                    value={change.inputValue}
                    ref={inputElement}
                    onChange={handleSearch}
                    onKeyDown={handlePressEnter}
                  />
                  {change.inputValue.length > 0 && (
                    <IconButton onClick={removeSearchValue}>
                      <CloseIcon className={styles.closeIcon} />
                    </IconButton>
                  )}
                  <IconButton
                    type='button'
                    className={styles.searchButton}
                    aria-label='search'
                    onClick={() => {
                      handleSearchFilter(change.inputValue)
                    }}>
                    {!isMobile ? <Typography>Search</Typography> : <SearchIcon />}
                  </IconButton>
                </Box>
                {showSearchBar &&
                  (searchByLocationResult.length !== 0 || searchByNameResult.length !== 0) && (
                    <Box className={styles.searchResultbox}>
                      {(searchByLocationResult.length > 4
                        ? searchByLocationResult.slice(0, 4)
                        : searchByLocationResult
                      ).map((item: INftsData, index: number) => (
                        <Box
                          className={styles.searchResultItem}
                          key={index}
                          onClick={() => {
                            handleSearchFilter(item.coordinates[0])
                          }}>
                          <Typography>
                            <img src={mapPin} />

                            <Highlighter
                              highlightClassName={styles.matched}
                              searchWords={[change.inputValue]}
                              autoEscape={true}
                              textToHighlight={item.coordinates[0]}
                            />
                          </Typography>
                        </Box>
                      ))}
                      {(searchByNameResult.length > 4
                        ? searchByNameResult.slice(0, 4)
                        : searchByNameResult
                      ).map((item: INftsData, index: number) => (
                        <Box
                          className={styles.searchResultItem}
                          key={index}
                          onClick={() => {
                            handleSearchFilter(item.name)
                          }}>
                          <Typography>
                            <Highlighter
                              highlightClassName={styles.matched}
                              searchWords={[change.inputValue]}
                              autoEscape={true}
                              textToHighlight={item.name}
                            />
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  )}
              </Box>

              <Box className={styles.selectSection}>
                <Box className={styles.chartImg}>
                  <img src={chart} />
                </Box>
                <Link to={'/buy-land'}>
                  <Box className={styles.mapPinImg}>
                    <img src={mapPin} />
                  </Box>
                </Link>
              </Box>
            </Box>
          </Box>

          <Box className={styles.rightSide}>
            {!isMobile && (
              <Box className={styles.selectorWrapper}>
                <Accordion
                  label='Status'
                  dataToShow={
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      onChange={handleChange}
                      value={change.fillterOption}
                      className={styles.radioGroup}>
                      <Box>
                        <FormControlLabel
                          className={styles.label}
                          value={LandsEnum.ALLLANDS}
                          control={
                            <Radio classes={{ root: styles.radioBtn, checked: styles.checked }} />
                          }
                          label={LandsEnum.ALLLANDS}
                        />
                      </Box>
                      {wallet.connected && (
                        <Box>
                          <FormControlLabel
                            className={styles.label}
                            value={LandsEnum.MYLANDS}
                            control={
                              <Radio classes={{ root: styles.radioBtn, checked: styles.checked }} />
                            }
                            label={LandsEnum.MYLANDS}
                          />
                        </Box>
                      )}
                      <Box>
                        <FormControlLabel
                          className={styles.label}
                          value={LandsEnum.AVTOBUY}
                          control={
                            <Radio classes={{ root: styles.radioBtn, checked: styles.checked }} />
                          }
                          label={LandsEnum.AVTOBUY}
                        />
                      </Box>

                      <Box>
                        <FormControlLabel
                          className={styles.label}
                          value={LandsEnum.AVTOMINT}
                          control={
                            <Radio classes={{ root: styles.radioBtn, checked: styles.checked }} />
                          }
                          label={LandsEnum.AVTOMINT}
                        />
                      </Box>
                    </RadioGroup>
                  }
                />
                {/* <Accordion
                  detailsClassName={styles.priceDetails}
                  className={styles.selector}
                  label='Price'
                  dataToShow={
                    <>
                      <Box className={styles.accardionContent}>
                        <Select
                          displayEmpty
                          value={currency}
                          className={styles.selectCurrency}
                          IconComponent={ExpandMoreIcon}
                          input={<OutlinedInput />}
                          inputProps={{ 'aria-label': 'Without label', color: '#0B3456' }}
                          onChange={handleCurrencyChange}
                          MenuProps={{
                            disableScrollLock: true,
                            sx: {
                              '&& .Mui-selected': {
                                backgroundColor: 'white',
                              },
                            },
                            PaperProps: {
                              sx: {
                                width: '59px',
                                boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
                                borderRadius: '5px 5px 8px 8px',
                                '& .MuiMenuItem-root': {
                                  minHeight: '20px',
                                  fontSize: '11px',
                                  padding: '0',
                                  textAlign: 'start',
                                  fontStyle: 'normal',
                                  color: '#0B3456',
                                  borderRadius: '2px',
                                  width: '80%',
                                  marginLeft: '9px',
                                  fontWeight: '600',
                                  letterSpacing: '0.04em',
                                  fontFamily: 'DM Sans',
                                  lineHeight: '14px',
                                  background: 'none',
                                  justifyContent: 'start',
                                  paddingLeft: '9px',

                                  ':hover': {
                                    background:
                                      'linear-gradient(90.06deg, rgba(29, 103, 170, 0.4) 2.17%, rgba(163, 207, 244, 0.4) 92.93%); !important',
                                  },
                                },
                              },
                            },
                          }}>
                          <MenuItem value='USD'>USD</MenuItem>
                          <MenuItem value='SOL'>SOL</MenuItem>
                        </Select>

                        <Box className={styles.item}>
                          <NumberInput
                            value={change.minPrice}
                            handleChange={handleMinPriceChange}
                            placeholder='Min'
                            className={styles.priceInput}
                          />
                        </Box>
                        <Typography className={styles.item}>to</Typography>
                        <Box className={styles.item}>
                          <NumberInput
                            value={change.maxPrice}
                            handleChange={handleMaxPriceChange}
                            placeholder='Max'
                            className={styles.priceInput}
                          />
                        </Box>
                      </Box>
                      <Button className={styles.applyBtn} onClick={handleFilterByPrice}>
                        Apply
                      </Button>
                    </>
                  }
                /> */}

                <Accordion
                  className={styles.selector}
                  label='Sort by'
                  dataToShow={
                    <RadioGroup
                      aria-labelledby='demo-controlled-radio-buttons-group'
                      name='controlled-radio-buttons-group'
                      onChange={handleSortBy}
                      value={change.sortBy}
                      className={styles.radioGroup}>
                      <Box>
                        <FormControlLabel
                          className={styles.label}
                          value={SortEnum.NEWEST}
                          control={
                            <Radio
                              classes={{ root: styles.radioBtn, checked: styles.checked }}
                              checked={change.sortBy !== SortEnum.OLDEST}
                            />
                          }
                          label={SortEnum.NEWEST}
                        />
                      </Box>
                      <Box>
                        <FormControlLabel
                          className={styles.label}
                          value={SortEnum.OLDEST}
                          control={
                            <Radio classes={{ root: styles.radioBtn, checked: styles.checked }} />
                          }
                          label={SortEnum.OLDEST}
                        />
                      </Box>
                    </RadioGroup>
                  }
                />
              </Box>
            )}
            <Box className={styles.cardWrapper}>
              {
                (change.fillterOption === LandsEnum.AVTOBUY || change.fillterOption === LandsEnum.ALLLANDS) && (
                  <Alert
                    variant="outlined"
                    severity="warning"
                    icon={false}
                    sx={{
                      marginTop: '10px'
                    }}
                  >
                    {priceWarningText}
                  </Alert>
                )
              }

              <Box className={styles.items}>
                {/* <Typography>{priceWarningText}</Typography> */}
                {!change.isLandsLoading && (
                  <Box className={styles.itemsCount}>
                    {change.currentSearchResults.length > 0
                      ? change.currentSearchResults.length
                      : (change.fillterOption === LandsEnum.AVTOBUY) ? availableToBuy.length :  change.filteredData.length}{' '}
                    items
                  </Box>
                )}

                {isMobile && (
                  <>
                    <Box
                      className={styles.filter}
                      onClick={() => {
                        setShowFilter(true)
                      }}>
                      <img src={showFilter ? activeMenuwrapper : menuwrapper} />
                    </Box>
                    {showFilter && (
                      <Box className={styles.selectorWrapper}>
                        <Button
                          className={styles.closebtn}
                          onClick={() => {
                            setShowFilter(!showFilter)
                          }}>
                          <img src={xicon} />
                        </Button>

                        <Accordion
                          className={styles.selector}
                          label='Status'
                          dataToShow={
                            <RadioGroup
                              aria-labelledby='demo-controlled-radio-buttons-group'
                              name='controlled-radio-buttons-group'
                              onChange={handleChange}
                              className={styles.radioGroup}>
                              <Box>
                                <FormControlLabel
                                  className={styles.label}
                                  value={LandsEnum.ALLLANDS}
                                  control={
                                    <Radio
                                      classes={{ root: styles.radioBtn, checked: styles.checked }}
                                    />
                                  }
                                  label={LandsEnum.ALLLANDS}
                                />
                              </Box>

                              {
                                wallet.connected && (
                                  <Box>
                                    <FormControlLabel
                                      className={styles.label}
                                      value={LandsEnum.MYLANDS}
                                      control={
                                        <Radio
                                          classes={{ root: styles.radioBtn, checked: styles.checked }}
                                        />
                                      }
                                      label={LandsEnum.MYLANDS}
                                    />
                                  </Box>
                                )
                              }

                              <Box>
                                <FormControlLabel
                                  className={styles.label}
                                  value={LandsEnum.AVTOBUY}
                                  control={
                                    <Radio
                                      classes={{ root: styles.radioBtn, checked: styles.checked }}
                                    />
                                  }
                                  label={LandsEnum.AVTOBUY}
                                />
                              </Box>

                              <Box>
                                <FormControlLabel
                                  className={styles.label}
                                  value={LandsEnum.AVTOMINT}
                                  control={
                                    <Radio
                                      classes={{ root: styles.radioBtn, checked: styles.checked }}
                                    />
                                  }
                                  label={LandsEnum.AVTOMINT}
                                />
                              </Box>
                            </RadioGroup>
                          }
                        />

                        {/* <Accordion
                          detailsClassName={styles.priceDetails}
                          className={styles.selector}
                          label='Price'
                          dataToShow={
                            <>
                              <Box className={styles.accardionContent}>
                                <Select
                                  displayEmpty
                                  value={currency}
                                  className={styles.selectCurrency}
                                  IconComponent={ExpandMoreIcon}
                                  input={<OutlinedInput />}
                                  inputProps={{ 'aria-label': 'Without label', color: '#0B3456' }}
                                  onChange={handleCurrencyChange}
                                  MenuProps={{
                                    disableScrollLock: true,
                                    sx: {
                                      '&& .Mui-selected': {
                                        backgroundColor: 'white',
                                      },
                                    },
                                    PaperProps: {
                                      sx: {
                                        width: '59px',
                                        boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.05)',
                                        borderRadius: '5px 5px 8px 8px',
                                        '& .MuiMenuItem-root': {
                                          minHeight: '20px',
                                          fontSize: '11px',
                                          padding: '0',
                                          textAlign: 'start',
                                          fontStyle: 'normal',
                                          color: '#0B3456',
                                          borderRadius: '2px',
                                          width: '80%',
                                          marginLeft: '9px',
                                          fontWeight: '600',
                                          letterSpacing: '0.04em',
                                          fontFamily: 'DM Sans',
                                          lineHeight: '14px',
                                          background: 'none',
                                          justifyContent: 'start',
                                          paddingLeft: '9px',

                                          ':hover': {
                                            background:
                                              'linear-gradient(90.06deg, rgba(29, 103, 170, 0.4) 2.17%, rgba(163, 207, 244, 0.4) 92.93%); !important',
                                          },
                                        },
                                      },
                                    },
                                  }}>
                                  <MenuItem value='USD'>USD</MenuItem>
                                  <MenuItem value='SOL'>SOL</MenuItem>
                                </Select>

                                <Box className={styles.item}>
                                  <NumberInput
                                    value={change.minPrice}
                                    handleChange={handleMinPriceChange}
                                    placeholder='Min'
                                    className={styles.priceInput}
                                  />
                                </Box>
                                <Typography className={styles.item}>to</Typography>
                                <Box className={styles.item}>
                                  <NumberInput
                                    value={change.maxPrice}
                                    handleChange={handleMaxPriceChange}
                                    placeholder='Max'
                                    className={styles.priceInput}
                                  />
                                </Box>
                              </Box>
                              <Button className={styles.applyBtn} onClick={handleFilterByPrice}>
                                Apply
                              </Button>
                            </>
                          }
                        /> */}

                        <Accordion
                          className={styles.selector}
                          label='Sort by'
                          dataToShow={
                            <RadioGroup
                              aria-labelledby='demo-controlled-radio-buttons-group'
                              name='controlled-radio-buttons-group'
                              onChange={handleSortBy}
                              className={styles.radioGroup}>
                              <Box>
                                <FormControlLabel
                                  className={styles.label}
                                  value={SortEnum.NEWEST}
                                  control={
                                    <Radio
                                      classes={{ root: styles.radioBtn, checked: styles.checked }}
                                      checked={change.fillterOption !== SortEnum.OLDEST}
                                    />
                                  }
                                  label={SortEnum.NEWEST}
                                />
                              </Box>
                              <Box>
                                <FormControlLabel
                                  className={styles.label}
                                  value={SortEnum.OLDEST}
                                  control={
                                    <Radio
                                      classes={{ root: styles.radioBtn, checked: styles.checked }}
                                    />
                                  }
                                  label={SortEnum.OLDEST}
                                />
                              </Box>
                            </RadioGroup>
                          }
                        />
                      </Box>
                    )}
                  </>
                )}
              </Box>

              <Box>
                {isMobile && filteredBy !== '' && (
                  <Box className={styles.filteredByBox}>
                    <Typography>{filteredBy}</Typography>
                    <img
                      src={xicon}
                      onClick={() => {
                        setFilteredBy('')
                        setChange((prev) => ({ ...prev, filteredData: change.filteredData }))
                      }}
                    />
                  </Box>
                )}
              </Box>
              {change.isLandsLoading ? (
                <Box className={styles.loaderBox}>
                  <Loader
                    isFullScreen={false}
                    background='inheirt'
                    loaderColor='#0a3455'
                    isLoaderSmall={true}
                  />
                </Box>
              ) : (
                <>
                  {/* show nft items */}
                  <Box>
                    {change.fillterOption === LandsEnum.AVTOMINT && (
                      <Box className={styles.results}>
                        {availableToMint.map((item: INftsData, index: number) => (
                          <Card
                            key={index}
                            handleOpen={handleOpen}
                            data={item}
                            solPrice={solPrice}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                  <Box>
                    {change.fillterOption === LandsEnum.AVTOBUY && (
                      <Box className={styles.results}>
                        {availableToBuy.map((item: INftsData, index: number) => (
                          <Card
                            key={index}
                            handleOpen={handleOpen}
                            data={item}
                            solPrice={solPrice}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                  <Box>
                    {(change.fillterOption !== LandsEnum.AVTOMINT && change.fillterOption !== LandsEnum.AVTOBUY) && (
                      <Box className={styles.results}>
                        {change.filteredData.map((item: INftsData, index: number) => (
                          <Card
                            key={index}
                            handleOpen={handleOpen}
                            data={item}
                            solPrice={solPrice}
                          />
                        ))}
                      </Box>
                    )}
                  </Box>
                </>
              )}
              {change.inputValue.length === 0 &&
                !change.isLandsLoading &&
                change.filteredData.length !== 0 && (
                  <Box ref={lastElementRef} className={styles.lastElement} />
                )}
              {isNewLandsLoading && (
                <Loader
                  isFullScreen={false}
                  background='inheirt'
                  loaderColor='#0a3455'
                  isLoaderSmall={true}
                />
              )}
            </Box>

            {isMobile && change.filteredData.length > 0 && (
              <Box
                className={`${styles.showLessIcon} ${showMore ? styles.rotate : ''}`}
                onClick={() => {
                  setShowMore(!showMore)
                }}>
                <img src={arrowIcon} />
              </Box>
            )}
          </Box>
        </Box>
        <Footer />
      </Container>
    </>
  )
}

export default Marketplace
